import {
  GroupType,
  GroupTypeWithCountFragment,
  Maybe,
} from "api/generated/graphql";
import customIcon from "assets/icons/custom.svg";
import adLogo from "assets/logos/active-directory-logo.png";
import awsSsoLogo from "assets/logos/aws-sso-logo.png";
import duoLogo from "assets/logos/duo-logo.svg";
import githubLogo from "assets/logos/github-logo.png";
import gitlabLogo from "assets/logos/gitlab-logo.svg";
import googleGroupLogo from "assets/logos/googlegroups-logo.png";
import ldapLogo from "assets/logos/ldap-logo.png";
import oktaLogo from "assets/logos/okta-logo.png";
import opalLogo from "assets/logos/opal-logo-background.png";
import snowflakeLogo from "assets/logos/snowflake-logo.svg";
import workdayLogo from "assets/logos/workday-logo.png";
import Label from "components/label/Label";
import { IconName } from "components/ui/icon/Icon";
import sprinkles from "css/sprinkles.css";

import roleIcon from "../../assets/icons/role.svg";

export type GroupTypeInfo = {
  name: string;
  icon: string;
  iconName: IconName;
};

export const groupTypeInfoByType: Record<GroupType, GroupTypeInfo> = {
  [GroupType.ActiveDirectoryGroup]: {
    name: "AD Group",
    icon: adLogo,
    iconName: "users",
  },
  [GroupType.AwsSsoGroup]: {
    name: "AWS IAM Group",
    icon: awsSsoLogo,
    iconName: "users",
  },
  [GroupType.DuoGroup]: {
    name: "Duo Group",
    icon: duoLogo,
    iconName: "users",
  },
  [GroupType.GitHubTeam]: {
    name: "GitHub Team",
    icon: githubLogo,
    iconName: "users",
  },
  [GroupType.GitLabGroup]: {
    name: "GitLab Group",
    icon: gitlabLogo,
    iconName: "users",
  },
  [GroupType.GoogleGroupsGroup]: {
    name: "Google Group",
    icon: googleGroupLogo,
    iconName: "users",
  },
  [GroupType.GoogleGroupsGkeGroup]: {
    name: "GKE Google Group",
    icon: googleGroupLogo,
    iconName: "users",
  },
  [GroupType.LdapGroup]: {
    name: "LDAP Group",
    icon: ldapLogo,
    iconName: "users",
  },
  [GroupType.OktaGroup]: {
    name: "Okta Group",
    icon: oktaLogo,
    iconName: "users",
  },
  [GroupType.OpalGroup]: {
    name: "Opal Group",
    icon: opalLogo,
    iconName: "users",
  },
  [GroupType.AzureAdMicrosoft_365Group]: {
    name: "Azure AD Microsoft 365 Group",
    icon: adLogo,
    iconName: "users",
  },
  [GroupType.AzureAdSecurityGroup]: {
    name: "Azure AD Microsoft Entra ID Security Group",
    icon: adLogo,
    iconName: "users",
  },
  [GroupType.ConnectorGroup]: {
    name: "Connector Group",
    icon: customIcon,
    iconName: "users",
  },
  [GroupType.SnowflakeRole]: {
    name: "Snowflake Role",
    icon: snowflakeLogo,
    iconName: "users",
  },
  [GroupType.TailscaleGroup]: {
    name: "Tailscale Group",
    icon: roleIcon,
    iconName: "users",
  },
  [GroupType.WorkdayUserSecurityGroup]: {
    name: "Workday User Security Group",
    icon: workdayLogo,
    iconName: "users",
  },
  [GroupType.WorkdayUserSecurityGroup]: {
    name: "Workday User Security Group",
    icon: roleIcon,
    iconName: "users",
  },
};

export const getGroupTypeInfo = (groupType?: Maybe<GroupType>) => {
  return groupType ? groupTypeInfoByType[groupType] : null;
};

export const getGroupTypeName = (groupType: GroupTypeWithCountFragment) => {
  return (
    groupType.connection?.name ??
    getGroupTypeInfo(groupType.groupType)?.name ??
    groupType.groupType
  );
};

type GroupTypeLabelProps = {
  groupType?: Maybe<GroupType>;
  pointerCursor?: boolean;
  maxChars?: number;
};

export const GroupTypeLabel = (props: GroupTypeLabelProps) => {
  const groupTypeInfo = getGroupTypeInfo(props.groupType);

  return (
    <div className={sprinkles({ display: "flex" })}>
      <Label
        icon={groupTypeInfo?.icon}
        text={groupTypeInfo?.name || "--"}
        pointerCursor={props.pointerCursor}
        maxChars={props.maxChars}
      />
    </div>
  );
};

export default GroupTypeLabel;
