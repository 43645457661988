import { gql, useFragment } from "@apollo/client";
import {
  OpalAppVisibilityCellFragment,
  Visibility,
} from "api/generated/graphql";

export default (props: { appID: string }) => {
  const { complete, data } = useFragment<OpalAppVisibilityCellFragment>({
    fragment: gql`
      fragment OpalAppVisibilityCell on App {
        visibility
      }
    `,
    fragmentName: "OpalAppVisibilityCell",
    from: {
      __typename: "App",
      id: props.appID,
    },
  });

  return (
    <span>
      {complete
        ? data?.visibility === Visibility.Global
          ? "Global"
          : "Limited"
        : "loading"}
    </span>
  );
};
