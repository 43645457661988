import { getModifiedErrorMessage } from "api/ApiContext";
import {
  ConnectionPreviewTinyFragment,
  EntityType,
  GroupGroupFragment,
  GroupPreviewSmallFragment,
  GroupResourceFragment,
  GroupType,
  Maybe,
  PropagationStatusCode,
  RemoveGroupResourceInput,
  ResourceType,
  UpdateGroupGroupInput,
  UpdateGroupResourceInput,
  useRemoveGroupResourcesAndGroupsMutation,
  useUpdateGroupResourcesAndGroupsMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ResourceLabel, TimeLabel } from "components/label/Label";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { BulkUpdateExpirationModal } from "components/modals/BulkUpdateExpirationModal";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import { Checkbox, EntityIcon, Input, Modal } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import { compareAccessPaths } from "components/ui/table/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage, hasBasicPermissions } from "utils/auth/auth";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { PropagationType } from "utils/useRemediations";
import { useTransitionToImportItems } from "views/apps/AppImportItemsView";
import { expirationValueToDurationInMinutes } from "views/requests/utils";
import {
  GroupGroupAccessPathsInfo,
  GroupGroupAccessPointsLabel,
} from "views/resources/GroupGroupAccessPointsLabel";
import {
  getGroupResourceAccessPathsInfo,
  GroupResourceAccessPathsInfo,
  GroupResourceAccessPointsLabel,
} from "views/resources/GroupResourceAccessPointsLabel";
import { dropNothings } from "views/utils";

import { groupTypeInfoByType } from "../../components/label/GroupTypeLabel";
import { useToast } from "../../components/toast/Toast";
import * as styles from "./GroupResourcesTableV3.css";

interface GroupResourceRow {
  id: string;
  resourceId: string;
  data: GroupResourceFragment | GroupGroupFragment;
  name?: string;
  connection?: ConnectionPreviewTinyFragment | null;
  role: string;
  accessPaths?: GroupResourceAccessPathsInfo | GroupGroupAccessPathsInfo;
  expiry?: string | null;
  syncStatus?: PropagationStatusCode | null;
  authorizedActions?: string[] | null;
  isManaged: boolean;
}

type GroupResourcesTableV3Props = {
  group: GroupPreviewSmallFragment & {
    groupResources: (GroupResourceFragment & {
      resource?: {
        id: string;
        authorizedActions?: string[] | null;
      } | null;
    })[];
    containingGroups: (GroupGroupFragment & {
      containingGroup?: {
        id: string;
        authorizedActions?: string[] | null;
      } | null;
    })[];
    authorizedActions?: string[] | null;
  };
  showUnmanagedResources: boolean;
  setShowUnmanagedResources: (checked: boolean) => void;
};

export const GroupResourcesTableV3 = (props: GroupResourcesTableV3Props) => {
  const { authState } = useContext(AuthContext);
  const transitionTo = useTransitionTo();
  const [transitionToImportItems] = useTransitionToImportItems();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [
    updateExpirationErrorMessage,
    setUpdateExpirationErrorMessage,
  ] = useState<string>("");
  const [showUpdateExpirationModal, setShowUpdateExpirationModal] = useState(
    false
  );
  const [
    removeResourcesErrorMessage,
    setRemoveResourcesErrorMessage,
  ] = useState<Maybe<string>>(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const startPushTaskPoll = usePushTaskLoader();
  const logEvent = useLogEvent();
  const { displaySuccessToast } = useToast();

  const [
    updateGroupResourcesAndGroups,
    { loading: updateLoading },
  ] = useUpdateGroupResourcesAndGroupsMutation();
  const [
    removeGroupResourcesAndGroups,
    { loading: removeLoading },
  ] = useRemoveGroupResourcesAndGroupsMutation();

  const canManage = props.group.authorizedActions?.includes(
    AuthorizedActionManage
  );
  const canSeePropagationStatus = () => {
    return !hasBasicPermissions(authState.user) || canManage;
  };

  const RESOURCE_GROUP_COLUMNS: Header<GroupResourceRow>[] = dropNothings([
    {
      id: "name",
      label: "Name",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div
            className={styles.groupNameField}
            onClick={(event) => {
              if (row.isManaged) {
                transitionTo(
                  {
                    pathname: getResourceUrlNew({
                      entityId: row.resourceId,
                      entityType:
                        row.data.__typename === "GroupResource"
                          ? EntityType.Resource
                          : EntityType.Group,
                    }),
                  },
                  event
                );
              } else {
                transitionToImportItems(
                  {
                    connectionId: props.group.connection?.id ?? "",
                    resourceIds:
                      row.data.__typename === "GroupResource"
                        ? [row.resourceId]
                        : undefined,
                    groupIds:
                      row.data.__typename === "GroupGroup"
                        ? [row.data.memberGroupId]
                        : undefined,
                  },
                  event
                );
              }
            }}
          >
            <ResourceLabel
              text={row.name}
              bold={true}
              pointerCursor={true}
              maxChars="auto"
              inactive={!row.isManaged}
              tooltipText={
                !row.isManaged
                  ? "This resource is currently not managed in Opal"
                  : null
              }
            />
          </div>
        );
      },
      width: 130,
    },
    {
      id: "resourceId",
      label: "Type",
      customCellRenderer: (row) => {
        let entityType: ResourceType | GroupType | undefined;
        let label = "";
        if (row.data.__typename === "GroupResource") {
          label =
            resourceTypeInfoByType[row.data.resource?.resourceType!].fullName;
          entityType = row.data.resource?.resourceType;
        } else if (row.data.__typename === "GroupGroup") {
          label =
            groupTypeInfoByType[row.data.containingGroup?.groupType!].name;
          entityType = row.data.containingGroup?.groupType;
        }
        if (!entityType) return <></>;
        if (entityType) {
          return (
            <span
              className={sprinkles({
                display: "flex",
                gap: "sm",
                alignItems: "center",
              })}
            >
              <EntityIcon type={entityType} />
              {label}
            </span>
          );
        }
        return <div>{label}</div>;
      },
    },
    {
      id: "role",
      label: "Role",
    },
    {
      id: "accessPaths",
      label: "Access Paths",
      customCellRenderer: (row) => {
        if (row.data.__typename === "GroupResource") {
          if (!row.accessPaths) {
            return "Direct";
          }
          return (
            <GroupResourceAccessPointsLabel
              accessPathsInfo={row.accessPaths}
              group={props.group}
              resource={row.data.resource}
              role={row.data.accessLevel}
              access={row.data.access}
            />
          );
        } else if (row.data.__typename === "GroupGroup") {
          if (!row.data.quickAccess?.hasDirectAccess) {
            return "Direct";
          }
          return (
            <GroupGroupAccessPointsLabel
              containingGroup={row.data.containingGroup}
              memberGroup={row.data.memberGroup}
              role={row.data.accessLevel}
              groupGroupId={row.id}
              quickAccess={row.data.quickAccess}
            />
          );
        } else {
          return "Direct";
        }
      },
      sortingFn: (rowA, rowB): number => {
        return compareAccessPaths(
          rowA.getValue("accessPaths"),
          rowB.getValue("accessPaths")
        );
      },
      width: 110,
    },
    {
      id: "connection",
      label: "App",
      customCellRenderer: (row) => {
        if (!row.connection) return <></>;
        const connection = row.connection;
        return (
          <div
            className={sprinkles({
              display: "flex",
              gap: "sm",
              alignItems: "center",
            })}
            onClick={(event) => {
              transitionTo(
                {
                  pathname: getResourceUrlNew({
                    entityId: connection.id,
                    entityType: EntityType.Connection,
                  }),
                },
                event
              );
            }}
          >
            <ResourceLabel
              text={connection.name}
              icon={
                <EntityIcon
                  type={connection.connectionType}
                  iconStyle="rounded"
                />
              }
              iconLarge
              bold={true}
              pointerCursor={true}
              maxChars="auto"
            />
          </div>
        );
      },
    },
    {
      id: "expiry",
      label: "Expires",
      customCellRenderer: (row) => {
        let expirationTime: moment.Moment | null = null;
        if (row.data.__typename === "GroupResource") {
          expirationTime = row.data.access?.latestExpiringAccessPoint
            ?.expiration
            ? moment(
                new Date(row.data.access.latestExpiringAccessPoint.expiration)
              )
            : null;
          return (
            <TimeLabel
              time={expirationTime}
              supportTicket={
                row.data.access?.latestExpiringAccessPoint.supportTicket
              }
              useExpiringLabel
              indefiniteLablel="Permanent Access"
            />
          );
        } else if (row.data.__typename === "GroupGroup") {
          expirationTime = row.data.quickAccess?.expiration
            ? moment(new Date(row.data.quickAccess?.expiration))
            : null;
          return (
            <TimeLabel
              time={expirationTime}
              supportTicket={row.data.quickAccess?.supportTicket}
              useExpiringLabel
              indefiniteLablel="Permanent Access"
            />
          );
        } else {
          return (
            <TimeLabel
              time={expirationTime}
              useExpiringLabel
              indefiniteLablel="Permanent Access"
            />
          );
        }
      },
      width: 125,
    },
    canSeePropagationStatus()
      ? {
          id: "syncStatus",
          label: "Status",
          customCellRenderer: (row) => {
            if (row.data.__typename === "GroupResource") {
              return (
                <PropagationStatusLabelWithModal
                  propagationType={PropagationType.GroupResource}
                  propagationStatus={row.data.propagationStatus}
                  isAccessReview={false}
                  entityInfo={{
                    group: props.group,
                    resource: row.data.resource,
                    role: row.data.accessLevel,
                  }}
                />
              );
            } else {
              return (
                <PropagationStatusLabelWithModal
                  propagationType={PropagationType.GroupResource}
                  propagationStatus={undefined}
                  isAccessReview={false}
                  entityInfo={{}}
                />
              );
            }
          },
          width: 50,
        }
      : null,
  ]);

  let groupResources = props.group.groupResources;
  if (!props.showUnmanagedResources) {
    groupResources = groupResources.filter(
      (resource) => resource.resource?.isManaged
    );
  }
  if (searchQuery) {
    groupResources = groupResources.filter((groupResource) =>
      groupResource.resource?.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  }
  const rowsById: Record<string, GroupResourceRow> = {};
  const rows: GroupResourceRow[] = groupResources.map((groupResource) => {
    const row = {
      id: groupResource.resourceId + groupResource.accessLevel.accessLevelName,
      resourceId: groupResource.resourceId,
      name: groupResource.resource?.name,
      data: groupResource,
      connection: groupResource.resource?.connection,
      role: groupResource.accessLevel.accessLevelName || "\u2014",
      accessPaths: getGroupResourceAccessPathsInfo({
        group: props.group,
        resource: groupResource.resource,
        access: groupResource.access,
      }),
      authorizedActions: groupResource.resource?.authorizedActions,
      syncStatus: groupResource.propagationStatus?.statusCode,
      isManaged: groupResource.resource?.isManaged!,
    };
    rowsById[row.id] = row;
    return row;
  });

  for (const groupGroup of props.group.containingGroups) {
    const row = {
      id: groupGroup.id,
      resourceId: groupGroup.containingGroupId,
      name: groupGroup.containingGroup?.name,
      data: groupGroup,
      connection: groupGroup.containingGroup?.connection,
      role: groupGroup.accessLevel.accessLevelName || "\u2014",
      authorizedActions: groupGroup.containingGroup?.authorizedActions,
      // syncStatus: groupGroup.propagationStatus?.statusCode,
      isManaged: groupGroup.containingGroup?.isManaged!,
    };
    rows.push(row);
    rowsById[row.id] = row;
  }

  const bulkRightActions: PropsFor<
    typeof TableHeader
  >["bulkRightActions"] = canManage
    ? [
        {
          label: "Update Expiration",
          type: "mainSecondary",
          onClick: () => setShowUpdateExpirationModal(true),
          iconName: "clock",
        },
        {
          label: "Remove",
          type: "danger",
          onClick: () => setShowRemoveModal(true),
          iconName: "trash",
        },
      ]
    : [];

  const submitRemoval = async (
    groupResourcesToRemove: RemoveGroupResourceInput[],
    groupGroupIdsToRemove: string[]
  ) => {
    const numResourcesRemoved = _.uniqBy(groupResourcesToRemove, "resourceId")
      .length;
    const numGroupsRemoved = groupGroupIdsToRemove.length;
    logEvent({
      name: "apps_remove_resources_from_group",
      properties: {
        numResourcesRemoved: numResourcesRemoved + numGroupsRemoved,
      },
    });

    try {
      const { data } = await removeGroupResourcesAndGroups({
        variables: {
          groupResourcesToRemove: {
            groupResources: groupResourcesToRemove,
          },
          groupGroupIdsToRemove: groupGroupIdsToRemove,
        },
        refetchQueries: ["Group"],
      });
      switch (data?.removeGroupResources.__typename) {
        case "RemoveGroupResourcesResult":
          if (data.removeGroupResources.taskId) {
            startPushTaskPoll(data.removeGroupResources.taskId, {
              refetchOnComplete: { groupId: props.group.id },
            });
          } else {
            displaySuccessToast(
              `Successfully removed ${pluralize(
                "groups",
                groupGroupIdsToRemove.length,
                true
              )}`
            );
          }
          setShowRemoveModal(false);
          setRemoveResourcesErrorMessage(null);
          setSelectedItemIds([]);
          setSearchQuery("");
          break;
        case "UserFacingError":
          setRemoveResourcesErrorMessage(data.removeGroupResources.message);
          break;
        default:
          logError(new Error(`failed to remove resources from group`));
          setRemoveResourcesErrorMessage(
            "Error: failed to remove resources from group"
          );
      }
      switch (data?.removeGroupGroups.__typename) {
        case "RemoveGroupGroupsResult":
          if (data.removeGroupGroups.taskId) {
            startPushTaskPoll(data.removeGroupGroups.taskId, {
              refetchOnComplete: { groupId: props.group.id },
            });
          } else {
            displaySuccessToast(
              `Successfully removed ${pluralize(
                "groups",
                groupGroupIdsToRemove.length,
                true
              )}`
            );
          }
          setShowRemoveModal(false);
          setRemoveResourcesErrorMessage(null);
          setSelectedItemIds([]);
          setSearchQuery("");
          break;
        case "UserFacingError":
          setRemoveResourcesErrorMessage(data.removeGroupGroups.message);
          break;
        default:
          logError(new Error(`failed to remove groups from group`));
          setRemoveResourcesErrorMessage(
            "Error: failed to remove resources from group"
          );
      }
    } catch (error) {
      logError(error, "failed to remove resources from group");
      setRemoveResourcesErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to remove resources from group",
          error
        )
      );
    }
  };

  const submitUpdateExpiration = async (
    groupResourcesToUpdate: UpdateGroupResourceInput[],
    groupGroupsToUpdate: UpdateGroupGroupInput[]
  ) => {
    logEvent({
      name: "apps_update_group_resources",
      properties: {
        numResourcesUpdated: _.uniqBy(groupResourcesToUpdate, "resourceId")
          .length,
        numGroupsUpdated: _.uniqBy(groupGroupsToUpdate, "groupGroupId").length,
      },
    });

    try {
      const { data } = await updateGroupResourcesAndGroups({
        variables: {
          groupGroupsToUpdate: {
            groupGroups: groupGroupsToUpdate,
            // TODO: add uiSource to the mutation
          },
          groupResourcesToUpdate: {
            groupResources: groupResourcesToUpdate,
          },
        },
        refetchQueries: ["GroupDetailView"],
      });
      switch (data?.updateGroupResources.__typename) {
        case "UpdateGroupResourcesResult":
          if (data.updateGroupResources.taskIds.length > 0) {
            for (const taskId of data.updateGroupResources.taskIds) {
              startPushTaskPoll(taskId, {
                refetchOnComplete: { groupId: props.group.id },
              });
            }
          } else if (groupResourcesToUpdate.length > 0) {
            displaySuccessToast(
              `Successfully updated ${pluralize(
                "resources",
                groupResourcesToUpdate.length,
                true
              )}`
            );
          }
          setShowUpdateExpirationModal(false);
          setUpdateExpirationErrorMessage("");
          setSelectedItemIds([]);
          setSearchQuery("");
          break;
        case "UserFacingError":
          setUpdateExpirationErrorMessage(data.updateGroupResources.message);
          break;
        default:
          logError(new Error(`failed to update expiration for resources`));
          setUpdateExpirationErrorMessage(
            "Error: failed to update expiration for resources"
          );
      }
      switch (data?.updateGroupGroups.__typename) {
        case "UpdateGroupGroupsResult":
          if (data.updateGroupGroups.taskIds.length > 0) {
            for (const taskId of data.updateGroupGroups.taskIds) {
              startPushTaskPoll(taskId, {
                refetchOnComplete: { groupId: props.group.id },
              });
            }
          } else if (groupGroupsToUpdate.length > 0) {
            displaySuccessToast(
              `Successfully updated ${pluralize(
                "groups",
                groupGroupsToUpdate.length,
                true
              )}`
            );
          }
          setShowUpdateExpirationModal(false);
          setUpdateExpirationErrorMessage("");
          setSelectedItemIds([]);
          setSearchQuery("");
          break;
        case "UserFacingError":
          setUpdateExpirationErrorMessage(data.updateGroupGroups.message);
          break;
        default:
          logError(new Error(`failed to update expiration for group groups`));
          setUpdateExpirationErrorMessage(
            "Error: failed to update expiration for nested groups"
          );
      }
    } catch (error) {
      logError(error, "failed to update expiration");
      setUpdateExpirationErrorMessage(
        getModifiedErrorMessage("Error: failed to update expiration", error)
      );
    }
  };

  const getCheckboxDisabledReason = (row: GroupResourceRow) => {
    const canManageResource = row.authorizedActions?.includes(
      AuthorizedActionManage
    );
    if (
      row.data.__typename === "GroupResource" &&
      row.data.resource?.resourceType === ResourceType.OktaRole &&
      props.group.groupType === GroupType.OktaGroup
    ) {
      return "Okta Role and Group relationships cannot be managed in Opal. Please make these changes in Okta.";
    } else if (!canManageResource) {
      return "You can only remove resources which you administer. Please ask your Opal admin to remove it.";
    } else if (
      row.data.__typename === "GroupResource" &&
      !row.data.access?.directAccessPoint
    ) {
      return "The resource has no direct access to remove";
    }
    return undefined;
  };

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        height: "100%",
      })}
    >
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          gap: "sm",
          marginBottom: "md",
        })}
      >
        <div className={styles.searchInput}>
          <Input
            leftIconName="search"
            type="search"
            style="search"
            placeholder="Filter by name"
            value={searchQuery}
            onChange={setSearchQuery}
          />
        </div>
        <Checkbox
          size="md"
          label="Show unmanaged resources"
          checked={props.showUnmanagedResources}
          onChange={(value) => {
            props.setShowUnmanagedResources(value);
          }}
        />
      </div>
      <TableHeader
        entityType={EntityType.Resource}
        totalNumRows={new Set(rows.map((row) => row.id)).size}
        selectedNumRows={selectedItemIds.length}
        defaultRightActions={
          canManage
            ? [
                {
                  label: "Add Resources",
                  type: "mainSecondary",
                  onClick: () => {
                    transitionTo({
                      pathname: `/groups/${props.group.id}/add-resources`,
                    });
                  },
                  iconName: "plus",
                },
              ]
            : []
        }
        bulkRightActions={bulkRightActions}
      />
      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        columns={RESOURCE_GROUP_COLUMNS}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedItemIds)}
        onRowClickTransitionTo={(row) =>
          getResourceUrlNew({
            entityId: row.resourceId,
            entityType:
              row.data.__typename === "GroupResource"
                ? EntityType.Resource
                : EntityType.Group,
          })
        }
        onCheckedRowsChange={
          canManage
            ? (checkedRowIds, checked) => {
                if (checked) {
                  setSelectedItemIds((prev) => [...prev, ...checkedRowIds]);
                  return;
                } else {
                  setSelectedItemIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        selectAllChecked={
          selectedItemIds.length > 0 &&
          selectedItemIds.length ===
            rows.filter((row) => !getCheckboxDisabledReason(row)).length
        }
        onSelectAll={(checked) =>
          checked
            ? setSelectedItemIds(
                rows
                  .filter((row) => !getCheckboxDisabledReason(row))
                  .map((row) => row.id)
              )
            : setSelectedItemIds([])
        }
        getCheckboxDisabledReason={getCheckboxDisabledReason}
      />
      {showUpdateExpirationModal && (
        <BulkUpdateExpirationModal
          isOpen={showUpdateExpirationModal}
          onSubmit={(expiration) => {
            const groupResourcesToUpdate: UpdateGroupResourceInput[] = [];
            const groupGroupsToUpdate: UpdateGroupGroupInput[] = [];
            const accessDurationInMinutes =
              expirationValueToDurationInMinutes(expiration)?.asMinutes() ??
              null;
            for (const rowId of selectedItemIds) {
              const row = rowsById[rowId];
              if (row.data.__typename === "GroupGroup") {
                groupGroupsToUpdate.push({
                  groupGroupId: row.id,
                  durationInMinutes: {
                    int: accessDurationInMinutes,
                  },
                });
              } else if (row.data.__typename === "GroupResource") {
                groupResourcesToUpdate.push({
                  resourceId: row.resourceId,
                  groupId: props.group.id,
                  accessLevel: {
                    accessLevelName:
                      row.data.access.accessLevel.accessLevelName,
                    accessLevelRemoteId:
                      row.data.access.accessLevel.accessLevelRemoteId,
                  },
                  durationInMinutes: {
                    int: accessDurationInMinutes,
                  },
                });
              }
            }
            submitUpdateExpiration(groupResourcesToUpdate, groupGroupsToUpdate);
          }}
          selectedItemIds={selectedItemIds}
          entityName={props.group.name}
          errorMessage={updateExpirationErrorMessage}
          onClose={() => setShowUpdateExpirationModal(false)}
          loading={updateLoading}
        />
      )}
      {showRemoveModal && (
        <Modal
          isOpen
          title="Remove Group Resources"
          onClose={() => setShowRemoveModal(false)}
        >
          <Modal.Body>
            {removeResourcesErrorMessage && (
              <ModalErrorMessage errorMessage={removeResourcesErrorMessage} />
            )}
            Are you sure you want to remove{" "}
            {pluralize("direct role assignment", selectedItemIds.length, true)}{" "}
            from this group?
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Remove"
            primaryButtonDisabled={selectedItemIds.length === 0}
            primaryButtonLoading={removeLoading}
            onPrimaryButtonClick={() => {
              const groupResourcesToRemove: RemoveGroupResourceInput[] = [];
              const groupGroupIdsToRemove: string[] = [];

              for (const rowId of selectedItemIds) {
                const row = rowsById[rowId];
                if (row.data.__typename === "GroupResource") {
                  groupResourcesToRemove.push({
                    groupId: props.group.id,
                    resourceId: row.resourceId,
                    accessLevel: {
                      accessLevelName:
                        row.data.access.accessLevel.accessLevelName,
                      accessLevelRemoteId:
                        row.data.access.accessLevel.accessLevelRemoteId,
                    },
                  });
                } else if (row.data.__typename === "GroupGroup") {
                  groupGroupIdsToRemove.push(row.id);
                }
              }

              submitRemoval(groupResourcesToRemove, groupGroupIdsToRemove);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default GroupResourcesTableV3;
