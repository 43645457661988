import NotificationIcon from "components/notifications/notificationIcon";
import { useSpotlightSearch } from "components/spotlight_search/utils";
import { ButtonV3 } from "components/ui";
import Icon, { IconName } from "components/ui/icon/Icon";
import React from "react";
import useLogEvent from "utils/analytics";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import * as styles from "./OpalHeader.css";
type Props = {
  icon: IconName;
  title: string;
  extraActions?: React.ReactNode; // avoid if possible
  leftActions?: React.ReactNode; // avoid if possible
};

export default ({ icon, title, extraActions, leftActions }: Props) => {
  const { setShowSpotlightSearch } = useSpotlightSearch();
  const logEvent = useLogEvent();
  const hasV3Search = useFeatureFlag(FeatureFlag.V3Search);

  let search = null;
  if (!hasV3Search) {
    search = (
      <ButtonV3
        size="lg"
        leftIconName="search"
        onClick={() => {
          logEvent({
            name: "k_bar_search_open",
            properties: {
              entryPoint: "click",
            },
          });
          setShowSpotlightSearch(true);
        }}
      />
    );
  }

  return (
    <header className={styles.container}>
      <Icon name={icon} size="md" />
      <div className={styles.title}>
        {title}
        {leftActions ?? null}
      </div>
      <div className={styles.actionsContainer}>
        {extraActions}
        {search}
        <NotificationIcon />
      </div>
    </header>
  );
};
