import { AxisRight } from "@visx/axis";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { Bar, Circle } from "@visx/shape";
import { MouseEvent, TouchEvent, useCallback, useRef, useState } from "react";
import { RiskScoreRange } from "views/recommendations/RecommendationFilters";
import RiskScoreEntitiesPopover from "views/recommendations/RiskScoreEntitiesPopover";

import {
  GRAPH_BUCKET_SIZE,
  GRAPH_HEIGHT_MULTIPLIER,
  GRAPH_PADDING_MULTIPLIER,
  GRAPH_WIDTH_MULTIPLIER,
  POPOVER_HEIGHT,
  POPOVER_PADDING,
  POPOVER_WIDTH,
  POPOVER_Y_OFFSET,
  RISK_COLOR_CRITICAL,
  RISK_COLOR_HIGH,
  RISK_COLOR_LOW,
  RISK_COLOR_MEDIUM,
  X_AXIS_BAR_HEIGHT_MULTIPLIER,
  X_AXIS_HEIGHT_MULTIPLIER,
} from "./constants";
import * as styles from "./RiskScoreGraph.css";
import { ThreatCenterGraphSize } from "./ThreatCenter";

interface Props {
  // An array of length 101 where the index is the risk score and the value is the number of entities with that risk score
  entitiesPerRiskScore: number[];
  numEntitiesPerDot?: number;
  handleBucketSelect: (scoreRange: RiskScoreRange[]) => void;
  graphSize?: ThreatCenterGraphSize;
}

const MAX_NUM_DOTS = 45; // set equal to granularityMaxColumnSize in risk_scores.go
const DOT_SPACING_MULTIPLIER = 2.5;

export const getRiskScoreColor = (riskScore: number) => {
  if (riskScore <= 25) {
    return RISK_COLOR_LOW;
  } else if (riskScore <= 50) {
    return RISK_COLOR_MEDIUM;
  } else if (riskScore <= 75) {
    return RISK_COLOR_HIGH;
  }
  return RISK_COLOR_CRITICAL;
};

const RiskScoreGraph = ({
  entitiesPerRiskScore,
  numEntitiesPerDot = 20,
  handleBucketSelect,
  graphSize = ThreatCenterGraphSize.Medium,
}: Props) => {
  const [hoveredRiskScore, setHoveredRiskScore] = useState<number | null>(null);
  const [hoveredEntityCount, setHoveredEntityCount] = useState<number>(0);
  const svgRef = useRef<SVGRectElement>(null);

  const points: { x: number; y: number }[] = [];

  const dotRadius = graphSize;
  const dotSpacing = DOT_SPACING_MULTIPLIER * dotRadius;

  // Generate entity counts for 5 score (per GRAPH_BUCKET_SIZE) buckets except for 0-5 since we have 101 scores total.
  let intervalNumEntities = 0;
  entitiesPerRiskScore.forEach((numEntities, riskScore) => {
    intervalNumEntities += numEntities;

    if (riskScore != 0 && riskScore % GRAPH_BUCKET_SIZE == 0) {
      let baseValue =
        riskScore == GRAPH_BUCKET_SIZE ? 0 : riskScore - GRAPH_BUCKET_SIZE + 1;
      for (let x = baseValue; x <= riskScore; x++) {
        let i = 1;
        let increment =
          intervalNumEntities % (GRAPH_BUCKET_SIZE * numEntitiesPerDot) >
          (x - baseValue) * numEntitiesPerDot
            ? 1
            : 0;
        for (
          i = 1;
          i <=
          (Math.floor(
            intervalNumEntities / (GRAPH_BUCKET_SIZE * numEntitiesPerDot)
          ) +
            increment) *
            numEntitiesPerDot;
          i += numEntitiesPerDot
        ) {
          points.push({ x, y: i });
        }
      }
      intervalNumEntities = 0;
    }
  });

  const yScale = scaleLinear<number>({
    domain: [0, numEntitiesPerDot * MAX_NUM_DOTS],
    range: [GRAPH_HEIGHT_MULTIPLIER * dotRadius, 0],
    nice: true,
    zero: false,
  });
  const xScale = scaleLinear<number>({
    domain: [100, 0],
    range: [8, GRAPH_WIDTH_MULTIPLIER * dotRadius],
  });

  const getRiskScoreMinMax = (riskScore: number) => {
    if (riskScore <= 25) {
      return [0, 25];
    } else if (riskScore <= 50) {
      return [26, 50];
    } else if (riskScore <= 75) {
      return [51, 75];
    } else return [76, 100];
  };

  const handleMouseMove = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!svgRef.current) return;

      // Resolve current mouse position to a x-axis value (risk score)
      const point = localPoint(svgRef.current, event);
      if (!point) return;
      const x = Math.round(xScale.invert(point.x));

      if (x >= 0 && x <= 100) {
        let [minX, maxX] = getRiskScoreMinMax(x);
        let entityCount = 0;

        for (let i = minX; i <= maxX; i++) {
          entityCount += entitiesPerRiskScore[i];
        }

        if (entityCount > 0) {
          setHoveredRiskScore(x);
          setHoveredEntityCount(entityCount);
        } else {
          setHoveredRiskScore(null);
          setHoveredEntityCount(0);
        }
      }
    },
    [xScale, entitiesPerRiskScore]
  );

  const handleMouseLeave = useCallback(() => {
    setHoveredRiskScore(null);
  }, []);

  const handleClick = useCallback(() => {
    if (!hoveredRiskScore) {
      return;
    }
    const [minX, maxX] = getRiskScoreMinMax(hoveredRiskScore);
    handleBucketSelect([{ minScore: minX, maxScore: maxX }]);
  }, [hoveredRiskScore, handleBucketSelect]);

  return (
    <div className={styles.container}>
      <svg
        width={
          GRAPH_WIDTH_MULTIPLIER * dotRadius +
          2 * GRAPH_PADDING_MULTIPLIER * dotRadius
        }
        height={
          GRAPH_HEIGHT_MULTIPLIER * dotRadius +
          GRAPH_PADDING_MULTIPLIER * dotRadius
        }
      >
        {graphSize > ThreatCenterGraphSize.XSmall && (
          <Group
            left={(GRAPH_WIDTH_MULTIPLIER + 7) * dotRadius}
            pointerEvents="none"
          >
            <AxisRight
              scale={yScale}
              top={X_AXIS_HEIGHT_MULTIPLIER * dotRadius}
              numTicks={4}
              hideAxisLine
              hideTicks
              tickFormat={(tick) => {
                const label = `${tick}`;
                if (label.length > 3) {
                  return `${label.slice(0, label.length - 3)}k`;
                }
                return label;
              }}
              tickLabelProps={{
                fill: "#737373", // gray700
                fontSize: "14px",
              }}
            />
          </Group>
        )}
        <Group left={0} top={X_AXIS_HEIGHT_MULTIPLIER * dotRadius}>
          <rect
            ref={svgRef}
            width={(GRAPH_WIDTH_MULTIPLIER + 4) * dotRadius}
            height={(GRAPH_HEIGHT_MULTIPLIER + 2) * dotRadius}
            x={-8}
            fill="#FAFAFA" // gray25
            onClick={handleClick}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            onTouchMove={handleMouseMove}
            onTouchEnd={handleMouseLeave}
          />
        </Group>
        <Group top={X_AXIS_HEIGHT_MULTIPLIER * dotRadius} pointerEvents="none">
          {points.map((point, i) => (
            <Circle
              key={`point-${point.x}-${i}`}
              className="dot"
              cx={xScale(point.x)}
              cy={
                // questionable
                (-point.y * dotSpacing) / numEntitiesPerDot +
                yScale(numEntitiesPerDot / dotSpacing)
              }
              r={dotRadius}
              fill={getRiskScoreColor(point.x)}
              opacity={
                hoveredRiskScore === null
                  ? 1
                  : (() => {
                      let [minScore, maxScore] = getRiskScoreMinMax(
                        hoveredRiskScore
                      );
                      return point.x >= minScore && point.x <= maxScore
                        ? 1
                        : 0.3;
                    })()
              }
            />
          ))}
          {hoveredRiskScore !== null &&
            graphSize > ThreatCenterGraphSize.XSmall && (
              <foreignObject
                x={
                  hoveredRiskScore < 50
                    ? xScale(hoveredRiskScore) - POPOVER_WIDTH - POPOVER_PADDING
                    : xScale(hoveredRiskScore) + POPOVER_PADDING / 2
                }
                y={dotRadius > 2 ? POPOVER_Y_OFFSET : 0}
                width={POPOVER_WIDTH + POPOVER_PADDING}
                height={POPOVER_HEIGHT + POPOVER_PADDING}
              >
                <RiskScoreEntitiesPopover
                  riskScore={hoveredRiskScore}
                  numEntities={hoveredEntityCount}
                  rgbColor={getRiskScoreColor(hoveredRiskScore)}
                  small={graphSize < ThreatCenterGraphSize.Large}
                />
              </foreignObject>
            )}
        </Group>
        <Group
          top={(GRAPH_HEIGHT_MULTIPLIER + X_AXIS_HEIGHT_MULTIPLIER) * dotRadius}
        >
          <Bar
            fill={RISK_COLOR_CRITICAL}
            height={X_AXIS_BAR_HEIGHT_MULTIPLIER * dotRadius}
            width={(GRAPH_WIDTH_MULTIPLIER * dotRadius) / 4}
          />
          <Bar
            fill={RISK_COLOR_HIGH}
            height={X_AXIS_BAR_HEIGHT_MULTIPLIER * dotRadius}
            x={(GRAPH_WIDTH_MULTIPLIER * dotRadius) / 4}
            width={(GRAPH_WIDTH_MULTIPLIER * dotRadius) / 4}
          />
          <Bar
            fill={RISK_COLOR_MEDIUM}
            height={X_AXIS_BAR_HEIGHT_MULTIPLIER * dotRadius}
            x={(GRAPH_WIDTH_MULTIPLIER * dotRadius) / 2}
            width={(GRAPH_WIDTH_MULTIPLIER * dotRadius) / 4}
          />
          <Bar
            fill={RISK_COLOR_LOW}
            height={X_AXIS_BAR_HEIGHT_MULTIPLIER * dotRadius}
            x={(3 * GRAPH_WIDTH_MULTIPLIER * dotRadius) / 4}
            width={((GRAPH_WIDTH_MULTIPLIER + 10) * dotRadius) / 4}
          />
        </Group>
      </svg>
      <div className={styles.xLabelContainer}>
        <div className={styles.xSublabel}>Critical Risk</div>
        <div className={styles.xSublabel}>Low Risk</div>
      </div>
    </div>
  );
};

export default RiskScoreGraph;
