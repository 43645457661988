import { gql, useFragment } from "@apollo/client";
import { OpalAppGroupCountCellFragment } from "api/generated/graphql";
import { Skeleton } from "components/ui";
import pluralize from "pluralize";

export default (props: { appID: string }) => {
  const { complete, data } = useFragment<OpalAppGroupCountCellFragment>({
    fragment: gql`
      fragment OpalAppGroupCountCell on App {
        groupAccessCount
      }
    `,
    fragmentName: "OpalAppGroupCountCell",
    from: {
      __typename: "App",
      id: props.appID,
    },
  });

  return (
    <span>
      {complete ? (
        `${pluralize("Group", data.groupAccessCount, true)}`
      ) : (
        <Skeleton variant="text" width="50px" />
      )}
    </span>
  );
};
