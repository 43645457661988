import OpalPage from "components/layout/OpalPage";
import { useHistory } from "react-router-dom";

import InventoryAppsTable from "./InventoryAppsTable";

// key:suffix
const TABS = {
  INVENTORY: "/inventory",
  BUNDLES: "/inventory/bundles",
};

export default () => {
  const history = useHistory();

  // replace with nested routes when we get to react-router v6
  const currentTab = history.location.pathname.endsWith(TABS.BUNDLES)
    ? TABS.BUNDLES
    : TABS.INVENTORY;

  return (
    <OpalPage
      title="Inventory"
      icon="inventory"
      tabs={[
        {
          title: "Apps",
          isSelected: currentTab === TABS.INVENTORY,
          onClick: () => history.push(TABS.INVENTORY),
        },
        {
          title: "Bundles",
          isSelected: currentTab === TABS.BUNDLES,
          onClick: () => history.push(TABS.BUNDLES),
        },
      ]}
    >
      {currentTab === TABS.INVENTORY ? <InventoryAppsTable /> : "BUNDLES"}
    </OpalPage>
  );
};
