import { gql, useFragment } from "@apollo/client";
import { OpalAppResourceCountCellFragment } from "api/generated/graphql";
import pluralize from "pluralize";

type Props = { appID: string };

export const OpalAppResourceCountCell_Fragment = gql`
  fragment OpalAppResourceCountCell on App {
    resourceCount
  }
`;

export default (props: Props) => {
  const { complete, data } = useFragment<OpalAppResourceCountCellFragment>({
    fragment: OpalAppResourceCountCell_Fragment,
    fragmentName: "OpalAppResourceCountCell",
    from: {
      __typename: "App",
      id: props.appID,
    },
  });

  return (
    <span>
      {complete
        ? `${pluralize("Resource", data?.resourceCount, true)}`
        : "loading"}
    </span>
  );
};
