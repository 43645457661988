import { gql, useFragment } from "@apollo/client";
import { OpalAppSourceCellFragment } from "api/generated/graphql";

export default (props: { appID: string }) => {
  // connectId and resourceId are used for cacheID resolution so we need to fetch them
  const { complete, data } = useFragment<OpalAppSourceCellFragment>({
    fragment: gql`
      fragment OpalAppSourceCell on App {
        app {
          __typename
          ... on ConnectionApp {
            connectionId
          }
          ... on OktaResourceApp {
            resourceId
          }
        }
      }
    `,
    fragmentName: "OpalAppSourceCell",
    from: {
      __typename: "App",
      id: props.appID,
    },
  });

  return (
    <span>
      {complete
        ? data?.app.__typename === "OktaResourceApp"
          ? "Okta"
          : "Native"
        : "loading"}
    </span>
  );
};
