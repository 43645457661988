import OpalPage from "components/layout/OpalPage";
import React from "react";

import SettingsColumn from "./SettingsColumn";
import SettingsContent from "./SettingsContent";
import * as styles from "./SettingsV3.css";

const SettingsV3 = () => {
  return (
    <OpalPage icon="settings" title="Settings" customBody>
      <div className={styles.container}>
        <SettingsColumn />
        <SettingsContent />
      </div>
    </OpalPage>
  );
};

export default SettingsV3;
