import { getModifiedErrorMessage } from "api/ApiContext";
import {
  ConnectionType,
  ConnectResourceSessionFragment,
  EntityType,
  GeneralSettingType,
  OidcProviderType,
  ResourceAccessLevel,
  ResourceType,
  useAssumeImpersonationMutation,
  useCreateSessionMutation,
  useInitOidcAuthFlowMutation,
  useSessionsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import ConnectRoleDropdown from "components/connect_sessions/ConnectRoleDropdown";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { IdpMfaModal } from "components/modals/IdpMfaModal";
import { renderModalContent } from "components/modals/SessionDetailsModal";
import { PillV3 } from "components/pills/PillsV3";
import { PendingRequestDisplay } from "components/requests/PendingRequestDisplay";
import { useToast } from "components/toast/Toast";
import { Banner, ButtonV3, EntityIcon, Icon, Skeleton } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { ReactNode, useContext, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import useLogEvent from "utils/analytics";
import { generateState } from "utils/auth/auth";
import {
  DEFAULT_SESSION_LENGTH_MINUTES,
  IMPERSONATION_SESSION_LENGTH_MINUTES,
  OPAL_GLOBAL_IMPERSONATION_REMOTE_ID,
  OPAL_IMPERSONATION_REMOTE_ID,
  OPAL_PROD_GLOBAL_IMPERSONATION_REMOTE_ID,
} from "utils/constants";
import { isSessionableType } from "utils/directory/connections";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { useMountEffect, usePageTitle } from "utils/hooks";
import { logError } from "utils/logging";
import { clearMfaData, getMfaParams, MfaCustomParams } from "utils/mfa/mfa";
import {
  clearOidcData,
  getOidcData,
  OidcCustomParams,
  OidcPostAuthAction,
  setOidcData,
} from "utils/oidc/oidc";
import { useTransitionTo } from "utils/router/hooks";
import { useAccessRequestTransition } from "views/access_request/AccessRequestContext";
import {
  CurrentUserResourceAccessStatus,
  currentUserResourceAccessStatusFromResource,
  getSessionWithExpiration,
} from "views/connect_sessions/utils";
import OrgContext from "views/settings/OrgContext";

import * as styles from "./ConnectSessionView.css";

type Props = {
  resource: ConnectResourceSessionFragment;
  roles: ResourceAccessLevel[];
  refetchResource: () => void;
};

const ConnectResourceSession = (props: Props) => {
  const { resource, roles, refetchResource } = props;

  const history = useHistory();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState<string>();
  const {
    displaySuccessToast,
    displayErrorToast,
    displayCustomToast,
  } = useToast();
  const logEvent = useLogEvent();
  const [showSessionDetails, setShowSessionDetails] = useState(false);
  const { orgState } = useContext(OrgContext);
  const { authState } = useContext(AuthContext);
  const [isIdpMfaModalOpen, setIsIdpMfaModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<ResourceAccessLevel>({
    accessLevelName: "",
    accessLevelRemoteId: "",
  });
  const [assumeImpersonation] = useAssumeImpersonationMutation();
  const [
    createSessionMutation,
    { loading: createSessionLoading },
  ] = useCreateSessionMutation();
  const [
    initOidcAuthFlow,
    { loading: initOidcAuthLoading },
  ] = useInitOidcAuthFlowMutation();
  const toAccessRequest = useAccessRequestTransition();
  const transitionTo = useTransitionTo();

  // Set of ids of the sessions that have expired and we have already sent out a toast for
  const notifiedExpiredSessions = useRef(new Set());

  const connection = resource?.connection;
  const queryParams = new URLSearchParams(location.search);
  // Our CLI sets the 'refresh=true' query param when it wants to force-create a new session.
  const pageForceNewSession = queryParams.get("refresh") === "true";

  const isImpersonationResource =
    resource.remoteId === OPAL_IMPERSONATION_REMOTE_ID;
  const isGlobalImpersonationResource =
    resource.remoteId === OPAL_GLOBAL_IMPERSONATION_REMOTE_ID;
  const isProdGlobalImpersonationResource =
    resource.remoteId === OPAL_PROD_GLOBAL_IMPERSONATION_REMOTE_ID;

  const allSessions =
    resource?.currentUserAccess.activeSessions.map(getSessionWithExpiration) ??
    [];

  usePageTitle(`Connecting to ${resource.name}`);
  // Remove notified ids of any sessions that we no longer have information on
  for (const notifiedSessId of Array.from(notifiedExpiredSessions.current)) {
    if (!allSessions.some((sess) => sess.session.id === notifiedSessId))
      notifiedExpiredSessions.current.delete(notifiedSessId);
  }

  // Send notification for expired sessions
  for (const session of allSessions) {
    if (
      session.isExpired &&
      !notifiedExpiredSessions.current.has(session.session.id)
    ) {
      let message;
      const role = session.session.accessLevel;
      if (role?.trim()) {
        message = `Your session for "${role}" access has expired.`;
      } else {
        message = "Your session has expired.";
      }
      displayCustomToast(message, "alert-triangle", false, true);
      notifiedExpiredSessions.current.add(session.session.id);
    }
  }

  // Hide expired sessions
  const currentSessions = allSessions.filter((s) => !s.isExpired);
  const getCurrentSession = (remoteId: string) => {
    for (const session of currentSessions) {
      // The current session must not have expired
      if (
        session.session.accessLevelRemoteId === remoteId &&
        !session.isExpired
      ) {
        return session;
      }
    }
    return null;
  };

  const currentSession = getCurrentSession(selectedRole.accessLevelRemoteId);
  const connectSessionAndDisplayDetails = async (
    role: ResourceAccessLevel,
    forceNewSession?: boolean
  ) => {
    logEvent({
      name: "apps_initiate_session_click",
      properties: { itemType: resource.resourceType },
    });

    if (
      resource.resourceType === ResourceType.AwsSsoPermissionSet &&
      connection?.metadata?.__typename === "AWSSSOConnectionMetadata"
    ) {
      // AWS SSO resources don't use sessions (yet, perhaps it's possible).
      // Redirect them to the AWS Portal which can grant console or CLI access.
      window.open(connection.metadata.accessPortalUrl);
      return false;
    }

    const session = getCurrentSession(role.accessLevelRemoteId);
    setSelectedRole(role);

    // If an unexpired session already exists, no need to try to create a new one
    if (!forceNewSession && session) {
      if (isImpersonationResource || isProdGlobalImpersonationResource) {
        if (session.session.accessLevelRemoteId) {
          try {
            await assumeImpersonation({
              variables: {
                input: {
                  userId: session.session.accessLevelRemoteId,
                },
              },
            });
          } catch (e) {
            logError(e, "could not assume impersonation");
          }
        } else {
          logError("no role remote id found for impersonation session");
        }
        window.location.href = "/";
      } else {
        setShowSessionDetails(true);
      }
      return false;
    }

    try {
      const { data } = await createSessionMutation({
        variables: {
          input: {
            resourceId: resource.id,
            durationInMinutes:
              isImpersonationResource ||
              isGlobalImpersonationResource ||
              isProdGlobalImpersonationResource
                ? IMPERSONATION_SESSION_LENGTH_MINUTES
                : DEFAULT_SESSION_LENGTH_MINUTES,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
          },
        },
      });
      switch (data?.createSession.__typename) {
        case "CreateSessionResult":
          if (isImpersonationResource || isProdGlobalImpersonationResource) {
            if (data.createSession.session.accessLevelRemoteId) {
              try {
                await assumeImpersonation({
                  variables: {
                    input: {
                      userId: data.createSession.session.accessLevelRemoteId,
                    },
                  },
                });
              } catch (e) {
                logError(e, "could not assume impersonation");
              }
            } else {
              logError("no role remote id found for impersonation session");
            }
            window.location.href = "/";
          } else {
            refetchResource();
            setShowSessionDetails(true);
          }
          return true;
        case "MfaInvalidError": {
          const useOktaMfa = orgState.orgSettings?.generalSettings.some(
            (setting) =>
              setting === GeneralSettingType.UseOktaMfaForGatingOpalActions
          );
          const useOidcMfa = orgState.orgSettings?.generalSettings.some(
            (setting) =>
              setting === GeneralSettingType.UseOidcMfaForGatingOpalActions
          );
          if (useOidcMfa) {
            try {
              const state = generateState();
              const { data } = await initOidcAuthFlow({
                variables: {
                  input: {
                    state: state,
                    oidcProviderType: OidcProviderType.Mfa,
                  },
                },
              });
              switch (data?.initOidcAuthFlow.__typename) {
                case "InitOidcAuthFlowResult":
                  setOidcData({
                    state: state,
                    oidcProviderType: OidcProviderType.Mfa,
                    postAuthPath: window.location.pathname,
                    postAuthHash: window.location.hash,
                    mfaParams: {
                      resourceId: resource.id,
                      accessLevel: role,
                      forceNewSession: forceNewSession,
                    },
                  });
                  window.location.replace(data?.initOidcAuthFlow.authorizeUrl);
                  break;
                case "OidcProviderNotFoundError":
                  displayErrorToast(
                    "Error: Failed to trigger MFA. Please contact your admin."
                  );
                  break;
                default:
                  displayErrorToast(
                    "Error: Failed to trigger MFA. Please try again or contact support if the issue persists."
                  );
              }
            } catch (e) {
              logError(e, "Error: could not complete MFA");
            }
          } else if (useOktaMfa) {
            setIsIdpMfaModalOpen(true);
          } else {
            authState.authClient?.mfaFlow(history.location.pathname, {
              resourceId: resource.id,
              accessLevel: role,
              forceNewSession: forceNewSession,
            });
          }
          break;
        }
        case "OidcIDTokenNotFoundError": {
          try {
            const state = generateState();
            const { data } = await initOidcAuthFlow({
              variables: {
                input: {
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                },
              },
            });
            switch (data?.initOidcAuthFlow.__typename) {
              case "InitOidcAuthFlowResult":
                setOidcData({
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                  postAuthPath: window.location.pathname,
                  postAuthHash: window.location.hash,
                  params: {
                    action: OidcPostAuthAction.StartSession,
                    resourceId: resource.id,
                    accessLevel: role,
                    forceNewSession: forceNewSession,
                  },
                });
                window.location.replace(data?.initOidcAuthFlow.authorizeUrl);
                break;
              case "OidcProviderNotFoundError":
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please contact your admin."
                );
                break;
              default:
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please try again or contact support if the issue persists."
                );
            }
          } catch (e) {
            logError(e, "session creation failed");
            setErrorMessage(
              getModifiedErrorMessage("Error: session creation failed", e)
            );
          }
          break;
        }
        case "ResourceNotFoundError":
        case "UserImpersonationDisabledError":
        case "ImpersonationSessionLengthError":
        case "EndSystemAuthorizationError":
        case "UserFacingError":
        case "VaultClientNotFoundError":
        case "VaultSessionError":
          setErrorMessage(data.createSession.message);
          break;
        default:
          logError(new Error(`session creation failed`));
          setErrorMessage("Error: session creation failed");
      }
    } catch (e) {
      logError(e, "session creation failed");
      setErrorMessage(
        getModifiedErrorMessage("Error: session creation failed", e)
      );
    }
    return false;
  };

  useMountEffect(() => {
    const activeSessions = resource.currentUserAccess.activeSessions;
    if (
      roles.length === 1 &&
      activeSessions.length === 1 &&
      roles[0].accessLevelRemoteId === activeSessions[0].accessLevelRemoteId &&
      !isImpersonationResource &&
      !isGlobalImpersonationResource &&
      !isProdGlobalImpersonationResource &&
      connection &&
      isSessionableType(
        connection?.connectionType,
        resource.remoteId,
        resource.resourceType
      )
    ) {
      const onlyRole = resource.currentUserAccess.resourceUsers[0].accessLevel;
      connectSessionAndDisplayDetails(onlyRole, pageForceNewSession);
    }

    const performMfaActions = (
      mfaParams: MfaCustomParams | OidcCustomParams,
      cleanup: () => void
    ) => {
      if (
        mfaParams &&
        mfaParams.resourceId === resource.id &&
        mfaParams.accessLevel
      ) {
        connectSessionAndDisplayDetails(
          mfaParams.accessLevel,
          mfaParams.forceNewSession
        );
        cleanup();
      }
    };

    if (queryParams.has("mfa")) {
      const mfaStatus = queryParams.get("mfa");
      if (mfaStatus === "success") {
        const mfaParams = getMfaParams();
        if (mfaParams) {
          displaySuccessToast("MFA challenge completed.");
          performMfaActions(mfaParams, () => {
            clearMfaData();
          });
        }
      } else if (mfaStatus === "access_denied") {
        displayErrorToast(
          "Error: Please contact your Opal Admin to reset your MFA."
        );
      } else {
        displayErrorToast(
          "MFA challenge failed. Please try again or contact support if the issue remains."
        );
      }

      queryParams.delete("mfa");
      history.replace({
        search: queryParams.toString(),
      });
    } else if (queryParams.has("oidc_auth")) {
      const oidcAuthStatus = queryParams.get("oidc_auth");
      if (oidcAuthStatus === "success") {
        const oidcData = getOidcData();
        if (
          oidcData &&
          oidcData.params?.action === OidcPostAuthAction.StartSession
        ) {
          performMfaActions(oidcData.params, () => {
            clearOidcData();
          });
        } else if (oidcData?.mfaParams) {
          performMfaActions(oidcData.mfaParams, () => {
            clearOidcData();
          });
        }

        queryParams.delete("oidc_auth");
        history.replace({
          search: queryParams.toString(),
        });
      } else {
        displayErrorToast(
          "Authentication failed. Please try again or contact support if the issue remains."
        );
      }
    }
  });

  const mostRecentUserAccessStatus = currentUserResourceAccessStatusFromResource(
    props.resource
  );

  let content: ReactNode = null;
  switch (mostRecentUserAccessStatus) {
    // We just want to use mostRecentUserAccessStatus to determine if the user
    // is authorized to start a session, if not either they don't have access
    // or the resource can't be sessioned, in these two cases we show an error
    case CurrentUserResourceAccessStatus.AuthorizedSessionStarted:
    case CurrentUserResourceAccessStatus.AuthorizedSessionNotStarted:
      content = (
        <>
          <div className={styles.headerContainer}>
            <EntityIcon
              type={connection?.connectionType ?? ConnectionType.Custom}
              size="xxl"
              iconStyle="rounded"
            />
            <h2 className={styles.header}>
              Connecting to <b>{resource.name}</b>
            </h2>
            <PillV3
              pillColor="Teal"
              icon={{ type: "entity", entityType: resource.resourceType }}
              keyText={getResourceTypeInfo(resource.resourceType)?.fullName}
            />
          </div>

          <ConnectRoleDropdown
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            roles={roles}
            isImpersonationResource={
              isImpersonationResource || isProdGlobalImpersonationResource
            }
            onSubmit={(role) => {
              connectSessionAndDisplayDetails(role, pageForceNewSession);
            }}
            getCurrentSession={getCurrentSession}
            loading={createSessionLoading || initOidcAuthLoading}
          />
        </>
      );
      break;

    case CurrentUserResourceAccessStatus.Requested:
      content = (
        <>
          <div className={styles.headerContainer}>
            <EntityIcon
              type={connection?.connectionType ?? ConnectionType.Custom}
              size="xxl"
              iconStyle="rounded"
            />
            <h2 className={styles.header}>
              Connecting to <b>{resource.name}</b>
            </h2>
            <PillV3
              pillColor="Teal"
              icon={{ type: "entity", entityType: resource.resourceType }}
              keyText={getResourceTypeInfo(resource.resourceType)?.fullName}
            />
          </div>

          <div
            className={sprinkles({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "md",
              gap: "lg",
            })}
          >
            <Banner
              type="info"
              message={"Access to this item is pending"}
              width="md"
            />
            <PendingRequestDisplay
              resourceId={props.resource.id}
              pendingRequests={props.resource.currentUserAccess.pendingRequests}
              renderButton={(onClick) => (
                <ButtonV3
                  label="View pending request"
                  type="mainBorderless"
                  size="lg"
                  onClick={onClick}
                />
              )}
            />
          </div>
        </>
      );
      break;

    case CurrentUserResourceAccessStatus.Unauthorized:
      content = (
        <>
          <div className={styles.headerContainer}>
            <EntityIcon
              type={connection?.connectionType ?? ConnectionType.Custom}
              size="xxl"
              iconStyle="rounded"
            />
            <h2 className={styles.header}>
              Connecting to <b>{resource.name}</b>
            </h2>
            <PillV3
              pillColor="Teal"
              icon={{ type: "entity", entityType: resource.resourceType }}
              keyText={getResourceTypeInfo(resource.resourceType)?.fullName}
            />
          </div>

          <div
            className={sprinkles({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "lg",
              marginTop: "md",
            })}
          >
            <Banner
              type="info"
              message={
                <>
                  <b>You do not have access to this item</b>
                  <br />
                  In order to connect to this item you must first have access.
                </>
              }
            />

            <ButtonV3
              type="main"
              label="Request Access"
              size="lg"
              onClick={() =>
                toAccessRequest({
                  selectedEntities: [
                    { id: resource.id, type: EntityType.Resource },
                  ],
                })
              }
            />
          </div>
        </>
      );
      break;

    default:
      content = (
        <>
          <div className={styles.headerContainer}>
            <Icon name="alert-triangle" color="red600V3" size="xxl" />
            <h2 className={styles.header}>
              This item does not support connected sessions.
            </h2>
          </div>

          <div
            className={sprinkles({
              display: "flex",
              justifyContent: "center",
              marginTop: "md",
            })}
          >
            <ButtonV3
              type="main"
              label="Return to catalog home"
              size="lg"
              onClick={(event) =>
                transitionTo(
                  {
                    pathname: "/apps",
                  },
                  event
                )
              }
            />
          </div>
        </>
      );
  }

  return (
    <>
      {content}

      {errorMessage && <Banner type="error" message={errorMessage} />}

      {showSessionDetails && currentSession && (
        <ConnectResourceSessionDetails
          resource={resource}
          sessionId={currentSession.session.id}
          selectedRole={selectedRole}
        />
      )}

      {isIdpMfaModalOpen && (
        <IdpMfaModal
          onClose={() => {
            setIsIdpMfaModalOpen(false);
          }}
          onMfaSuccess={() => {
            setIsIdpMfaModalOpen(false);
            connectSessionAndDisplayDetails(selectedRole, pageForceNewSession);
          }}
        />
      )}
    </>
  );
};

const ConnectResourceSessionDetails = (props: {
  resource: ConnectResourceSessionFragment;
  sessionId: string;
  selectedRole: ResourceAccessLevel;
}) => {
  // We need to use the session query because some resources, eg. AwsFedRole,
  // AwsFedSsm need to reset some expiration state
  const { data, error, loading } = useSessionsQuery({
    variables: {
      input: {
        resourceId: props.resource.id,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const session = data?.sessions.sessions.find(
    (session) => session.id === props.sessionId
  );

  if (error) {
    return <Banner type="error" message={error.message} />;
  }

  if (loading || !session) {
    return <Skeleton height="100px" width="100%" />;
  }

  return renderModalContent(
    EntityTypeDeprecated.Resource,
    session,
    props.resource,
    props.selectedRole
  ).content;
};

export default ConnectResourceSession;
