import { openInNewTab } from "api/common/common";
import {
  CurrentUserGroupAccessFragment,
  EntityType,
  GroupType,
} from "api/generated/graphql";
import axios from "axios";
import AuthContext from "components/auth/AuthContext";
import { GroupExpirationLabel } from "components/label/GroupExpirationLabel";
import {
  CurrentUserGroupAccessStatus,
  currentUserGroupAccessStatusFromGroup,
} from "components/label/Label";
import { useToast } from "components/toast/Toast";
import { ButtonV3, ContextMenu } from "components/ui";
import { makeURLForEntityViz } from "components/viz/contexts/FilterContext";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import {
  AuthorizedActionExport,
  AuthorizedActionManage,
} from "utils/auth/auth";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { ForfeitGroupButton } from "views/groups/ForfeitGroupButton";
import { ForfeitGroupMenuOption } from "views/groups/ForfeitGroupMenuOption";
import {
  GroupBreakGlassButton,
  GroupBreakGlassModal,
} from "views/groups/GroupBreakGlassModal";
import OrgContext from "views/settings/OrgContext";

import { GroupViewKey } from "./utils";

type MenuOptions = PropsFor<typeof ContextMenu>["options"];

type GroupActionButtonsV3Props = {
  group: {
    id: string;
    name: string;
    currentUserAccess: CurrentUserGroupAccessFragment;
    isRequestable: boolean;
    groupType: GroupType;
    authorizedActions?: string[] | null;
    groupBinding?: { id: string } | null;
  };
  selectedGroupViewKey?: string;
  onNavigate: (groupViewKey: GroupViewKey) => void;
  editButtons?: React.ReactNode;
  setShowDeleteGroupModal: (show: boolean) => void;
  setShowLinkGroupModal: (show: boolean) => void;
  setShowUnlinkGroupModal: (show: boolean) => void;
  setShowEditGroupBindingModal: (show: boolean) => void;
};

export const GroupActionButtonsV3 = (props: GroupActionButtonsV3Props) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const hasEUX = useFeatureFlag(FeatureFlag.EndUserExperience);
  const hasGroupBindings = useFeatureFlag(FeatureFlag.GroupBindings);
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();

  const history = useHistory();
  const logEvent = useLogEvent();

  const { authState } = useContext(AuthContext);
  const { orgState } = useContext(OrgContext);

  const [isBreakGlassModalOpen, setIsBreakGlassModalOpen] = useState(false);

  const groupAccess = props.group.currentUserAccess.groupUser?.access;
  const mostRecentUserAccessStatus = currentUserGroupAccessStatusFromGroup(
    props.group
  );
  const isAdmin = authState.user?.isAdmin || authState.user?.isReadOnlyAdmin;
  const canManage = props.group.authorizedActions?.includes(
    AuthorizedActionManage
  );

  // Export Handlers
  const handleRequestExport = () => {
    if (!props.group) return;
    logEvent({
      name: "apps_export_users",
      properties: {
        exportType: "group",
      },
    });
    displayLoadingToast("Generating export...");
    axios({
      url: "/export/groups/users?groupID=" + props.group.id,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        if (!props.group) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_" + props.group.name.replace(" ", "_") + "_Users.csv"
        );
        link.click();
        displaySuccessToast(`Success: downloaded users in group`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  const handleRequestExportDebugInfo = () => {
    if (!props.group) return;
    displayLoadingToast("Generating export...");
    axios({
      url: `/export/groups/${props.group.id}/debug`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        if (!props.group) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_" + props.group.name.replace(" ", "_") + "_Debug.json"
        );
        link.click();
        displaySuccessToast(`Success: downloaded group debug info`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  // Populate Button Array and Menu
  const menuOptions: MenuOptions = [];

  // Add Export options
  if (props.group.authorizedActions?.includes(AuthorizedActionExport)) {
    menuOptions.push({
      label: "Export resource users",
      icon: { type: "name", icon: "users-right" },
      onClick: handleRequestExport,
    });
    menuOptions.push({
      label: "Export debug info",
      icon: { type: "name", icon: "tool" },
      onClick: handleRequestExportDebugInfo,
    });
  }
  // Add Link Group and Remove from Opal
  if (props.group.authorizedActions?.includes(AuthorizedActionManage)) {
    if (hasGroupBindings) {
      if (props.group.groupBinding) {
        menuOptions.push(
          {
            label: "Edit group link",
            onClick: () => props.setShowEditGroupBindingModal(true),
            icon: { type: "name", icon: "edit-3" },
          },
          {
            label: "Unlink",
            onClick: () => {
              props.setShowUnlinkGroupModal(true);
            },
            icon: { type: "name", icon: "link-broken" },
            type: "danger",
          }
        );
      } else {
        menuOptions.push({
          label: "Link to group",
          onClick: () => props.setShowLinkGroupModal(true),
          icon: { type: "name", icon: "link" },
        });
      }
    }
    menuOptions.push({
      label: "Remove from Opal",
      onClick: () => {
        props.setShowDeleteGroupModal(true);
      },
      icon: { type: "name", icon: "trash" },
      type: "danger",
    });
  }

  // Button Definitions
  const buttonSize = "sm";
  const buttons = [];

  let expirationLabel = null;
  let hasAccess = false;
  let forfeitButton = null;
  let hasForfeitMenuOption = false;

  const contextMenu = (
    <ContextMenu
      key="menu"
      rightAligned
      options={menuOptions}
      renderButton={(onClick) => (
        <ButtonV3
          onClick={onClick}
          leftIconName="dots-horizontal"
          size={buttonSize}
        />
      )}
    />
  );
  const breakGlassModal = (
    <GroupBreakGlassModal
      groupId={props.group.id}
      onClose={() => setIsBreakGlassModalOpen(false)}
      isOpen={isBreakGlassModalOpen}
    />
  );
  let breakGlassButton = props.group.currentUserAccess.hasBreakGlass ? (
    <GroupBreakGlassButton
      key="break-glass"
      onClick={() => {
        setIsBreakGlassModalOpen(true);
        logEvent({ name: "apps_breakglass_click" });
      }}
      hasV3={hasV3}
      buttonSize={buttonSize}
    />
  ) : undefined;

  const expirationTime = groupAccess?.latestExpiringAccessPoint?.expiration
    ? moment(new Date(groupAccess?.latestExpiringAccessPoint?.expiration))
    : null;

  const hasForfeitableAccess =
    groupAccess?.directAccessPoint && groupAccess?.groupId === props.group.id;
  let hasPendingButton = false;
  switch (mostRecentUserAccessStatus) {
    case CurrentUserGroupAccessStatus.Authorized:
      breakGlassButton = undefined; // Do not allow break-glass if the user already has access
      if (hasForfeitableAccess) {
        forfeitButton = (
          <ForfeitGroupButton
            group={props.group}
            key="forfeit-button-group"
            hasV3={hasV3}
            buttonSize={buttonSize}
          />
        );
        if (!hasForfeitMenuOption) {
          hasForfeitMenuOption = true;
        }
      }

      expirationLabel = (
        <GroupExpirationLabel
          key="expiration-label"
          user={authState.user?.user}
          group={props.group}
          entityType={EntityType.Group}
          expiration={expirationTime}
          iconOnly={true}
          hasV3={hasV3}
          buttonSize={buttonSize}
        />
      );
      hasAccess = true;

      break;
    case CurrentUserGroupAccessStatus.Requested:
      hasPendingButton = true;
      break;
  }
  // Populate buttons
  if (isAdmin) {
    buttons.push(
      <ButtonV3
        type="mainSecondary"
        label="Explore"
        leftIconName="department"
        onClick={() => {
          const hash = makeURLForEntityViz(props.group.id, EntityType.Group);
          openInNewTab("/insights" + hash);
        }}
        size={buttonSize}
      />
    );
  }
  if (canManage) {
    buttons.push(
      <ButtonV3
        type="mainSecondary"
        label="Edit"
        leftIconName="edit"
        onClick={() => history.push(`/groups/${props.group.id}/edit`)}
        size={buttonSize}
      />
    );
  }
  if (props.group.isRequestable || !hasForfeitableAccess) {
    // Show a disabled request button if the user has no access to the group and is not allowed to request it.
    const notRequestableMessage =
      "This group cannot be requested. Please see the group admin.";
    const hasOtherPrimaryButton = hasEUX
      ? false
      : Boolean(hasPendingButton || breakGlassButton || forfeitButton);
    if (hasAccess) {
      // if has access, add request to menu
      menuOptions.unshift({
        label: "Request",
        icon: { type: "name", icon: "raised-hand" },
        onClick: () => {
          props.onNavigate("request");
          logEvent({
            name: "apps_item_request_click",
            properties: {
              itemType: props.group.groupType,
            },
          });
        },
      });
    } else {
      // if no access, add request to buttons
      buttons.push(
        <ButtonV3
          key="request"
          label="Request"
          leftIconName="raised-hand"
          type={hasOtherPrimaryButton ? "mainSecondary" : "main"}
          onClick={() => {
            props.onNavigate("request");
            logEvent({
              name: "apps_item_request_click",
              properties: {
                itemType: props.group.groupType,
              },
            });
          }}
          disabled={!props.group.isRequestable}
          disabledTooltip={notRequestableMessage}
          size={buttonSize}
        />
      );
    }
  }
  if (hasPendingButton) {
    if (hasEUX) {
      menuOptions.unshift({
        label: "View Request",
        icon: { type: "name", icon: "send" },
        onClick: () => {
          logEvent({ name: "apps_pending_click" });
          history.push(
            `${getResourceUrl(
              EntityTypeDeprecated.Request,
              props.group.currentUserAccess.pendingRequest?.id
            )}?o=1`
          );
        },
      });
    } else {
      buttons.push(
        <ButtonV3
          label="View Request"
          key="view-request"
          leftIconName="send"
          type="defaultSecondary"
          onClick={() => {
            logEvent({ name: "apps_pending_click" });
            history.push(
              `${getResourceUrl(
                EntityTypeDeprecated.Request,
                props.group.currentUserAccess.pendingRequest?.id
              )}?o=1`
            );
          }}
          size={buttonSize}
        />
      );
    }
  }
  if (breakGlassButton) {
    buttons.push(breakGlassButton);
  }
  if (expirationLabel) {
    buttons.push(expirationLabel);
  }
  if (!hasEUX && forfeitButton) {
    buttons.push(forfeitButton);
  }
  if (menuOptions.length) {
    buttons.push(contextMenu);
  }

  return (
    <>
      {hasEUX && hasForfeitMenuOption && (
        <ForfeitGroupMenuOption
          group={props.group}
          hasV3={hasV3}
          showMenuOption={(onClick) => {
            const option = menuOptions.find(
              (elem) => "label" in elem && elem.label === "Forfeit"
            );
            if (!option) {
              menuOptions.unshift({
                label: "Forfeit",
                icon: { type: "name", icon: "zap-off" },
                onClick: onClick,
                type: "danger",
                disabled: !!orgState.orgSettings?.isRemoteReadOnly,
              });
            }
          }}
        />
      )}
      {breakGlassModal}
      <div
        className={sprinkles({
          display: "flex",
          flexDirection: "row",
          gap: "sm",
        })}
      >
        {buttons}
      </div>
    </>
  );
};
