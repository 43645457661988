import * as Sentry from "@sentry/react";
import AuthCodeCallbackUnauthed from "components/auth/AuthCodeCallbackUnauthed";
import AuthContext from "components/auth/AuthContext";
import OidcCallback from "components/auth/OidcCallback";
import { SignInForm } from "components/auth/SignIn";
import SignOut from "components/auth/SignOut";
import SlackMfaCallback from "components/auth/SlackMfaCallback";
import SlackMfaOidcPresigned from "components/auth/SlackMfaOidcPresigned";
import Unauthenticated from "components/auth/Unauthenticated";
import LogRocket from "logrocket";
import React, { useContext, useEffect } from "react";
import { Switch } from "react-router";
import { Route, useHistory } from "react-router-dom";
import AppContext from "views/app/AppContext";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { Onboarding } from "views/onboarding/Onboarding";

const UnauthenticatedViews = () => {
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const { appState } = useContext(AppContext);

  if (appState.isRemoteLoggingEnabled) {
    Sentry.init({
      dsn:
        "https://6e6b86c3c574446ba8a7a446186b4532@o581619.ingest.sentry.io/5736543",
      integrations: [Sentry.browserTracingIntegration()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,
      release: appState.version,
      environment: appState.environment,
      attachStacktrace: true,
    });

    if (!appState.isOnPrem || window.location.hostname === "us.opal.dev") {
      LogRocket.init("owtokk/opal", {
        release: `v${appState.version}`,
        network: {
          isEnabled: true,
          requestSanitizer: (request) => {
            request.body = undefined;
            if (
              "authorization" in request.headers &&
              request.headers["authorization"]
            ) {
              request.headers["authorization"] = "";
            }
            return request;
          },
          responseSanitizer: (response) => {
            response.body = undefined;
            return response;
          },
        },
      });
    }
  }

  useEffect(() => {
    if (appState.isOnPrem && !authState.authClient) {
      // there's no auth client right now, force the user to onboard
      history.replace("/onboarding");
    }
  }, [appState.isOnPrem, authState.authClient, history]);

  if (!appState.isOnPrem && !authState.authClient) {
    // Error out here because we should always have an auth client for non on-prem
    return <UnexpectedErrorPage />;
  }

  return (
    <Switch>
      <Route exact path="/sign-in" component={SignInForm} />
      <Route exact path="/callback" component={AuthCodeCallbackUnauthed} />
      <Route exact path="/sign-out" component={SignOut} />
      <Route exact path="/onboarding" component={Onboarding} />
      <Route exact path="/callback/slackmfa" component={SlackMfaCallback} />
      <Route exact path="/slackmfa/oidc" component={SlackMfaOidcPresigned} />
      <Route exact path="/callback/oidc" component={OidcCallback} />
      <Route path="/" component={Unauthenticated} />
    </Switch>
  );
};

export default UnauthenticatedViews;
