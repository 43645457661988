export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AccessLevelMetadata: ["ImpersonationAccessLevelMetadata"],
    AccessReviewConnectionOutput: [
      "AccessReviewConnectionNotFoundError",
      "AccessReviewConnectionResult",
    ],
    AccessReviewConnectionsOutput: [
      "AccessReviewConnectionsResult",
      "AccessReviewNotFoundError",
    ],
    AccessReviewConnectionUsersOutput: [
      "AccessReviewConnectionUsersResult",
      "AccessReviewNotFoundError",
    ],
    AccessReviewGroupOutput: [
      "AccessReviewGroupNotFoundError",
      "AccessReviewGroupResult",
    ],
    AccessReviewGroupsOutput: [
      "AccessReviewGroupsResult",
      "AccessReviewNotFoundError",
    ],
    AccessReviewGroupUsersOutput: [
      "AccessReviewGroupUsersResult",
      "AccessReviewNotFoundError",
    ],
    AccessReviewOutput: ["AccessReviewNotFoundError", "AccessReviewResult"],
    AccessReviewProposedChange: [
      "AccessReviewGroupProposedChange",
      "AccessReviewResourceProposedChange",
    ],
    AccessReviewProposedChangesOutput: [
      "AccessReviewProposedChangesResult",
      "NoProposedChangesError",
    ],
    AccessReviewResourceOutput: [
      "AccessReviewResourceNotFoundError",
      "AccessReviewResourceResult",
    ],
    AccessReviewResourcesOutput: [
      "AccessReviewNotFoundError",
      "AccessReviewResourcesResult",
    ],
    AccessReviewResourceUsersOutput: [
      "AccessReviewNotFoundError",
      "AccessReviewResourceUsersResult",
    ],
    AccessReviewsOutput: ["AccessReviewsResult"],
    AccessReviewStatsOutput: [
      "AccessReviewNotFoundError",
      "AccessReviewStatsResult",
    ],
    AccessReviewTemplateOutput: [
      "AccessReviewTemplateNotFound",
      "AccessReviewTemplateResult",
    ],
    AccessReviewTemplatesOutput: ["AccessReviewTemplatesResult"],
    AccessReviewUserOutput: [
      "AccessReviewUserNotFoundError",
      "AccessReviewUserResult",
    ],
    AccessReviewUsersOutput: [
      "AccessReviewNotFoundError",
      "AccessReviewUsersResult",
    ],
    AddBigDataOutput: ["AddBigDataResult"],
    AddConnectionUsersOutput: [
      "AddConnectionUsersResult",
      "ConnectionNotFoundError",
      "ConnectionUserAlreadyExists",
    ],
    AddGroupGroupsEntryOutput: ["AddGroupGroupsEntryResult"],
    AddGroupGroupsOutput: [
      "AddGroupGroupsResult",
      "CallToWebhookFailedError",
      "GroupGroupAlreadyExists",
      "GroupNestingNotAllowedError",
      "GroupNotFoundError",
      "UserFacingError",
    ],
    AddGroupResourcesEntryOutput: ["AddGroupResourcesEntryResult"],
    AddGroupResourcesOutput: [
      "AddGroupResourcesResult",
      "CallToWebhookFailedError",
      "GroupNotFoundError",
      "GroupResourceAlreadyExists",
      "UserFacingError",
    ],
    AddGroupTagsEntryOutput: ["AddGroupTagsEntryResult"],
    AddGroupTagsOutput: [
      "AddGroupTagsResult",
      "GroupNotFoundError",
      "TagNotFoundError",
    ],
    AddGroupUsersEntryOutput: ["AddGroupUsersEntryResult"],
    AddGroupUsersOutput: [
      "AddGroupUsersResult",
      "CallToWebhookFailedError",
      "CannotAddSystemUserToGroupError",
      "GroupNotFoundError",
      "GroupUserAlreadyExists",
    ],
    AddResourceTagsEntryOutput: ["AddResourceTagsEntryResult"],
    AddResourceTagsOutput: [
      "AddResourceTagsResult",
      "ResourceNotFoundError",
      "TagNotFoundError",
    ],
    AddResourceUsersEntryOutput: ["AddResourceUsersEntryResult"],
    AddResourceUsersOutput: [
      "AddResourceUsersResult",
      "CallToWebhookFailedError",
      "CannotAddSystemUserToResourceError",
      "OpalGlobalImpersonationResourceDirectAddNotAllowedError",
      "ResourceNotFoundError",
      "ResourceUserAlreadyExists",
    ],
    AddRoleAssignmentsEntryOutput: ["AddRoleAssignmentsEntryResult"],
    AddRoleAssignmentsOutput: ["AddRoleAssignmentsResult", "UserFacingError"],
    AddUserTagsEntryOutput: ["AddUserTagsEntryResult"],
    AddUserTagsOutput: [
      "AddUserTagsResult",
      "TagNotFoundError",
      "UserNotFoundError",
    ],
    AllowedToCreateOrganizationsOutput: ["AllowedToCreateOrganizationsResult"],
    AllowedToUseAldwinOutput: ["AllowedToUseAldwinResult"],
    AppData: ["ConnectionApp", "OktaResourceApp"],
    AppInfoOutput: ["AppInfoResult"],
    AppOutput: ["App", "AppNotFoundError"],
    ApproveRequestOutput: [
      "AdminApproveRequiresReasonError",
      "ApproveRequestResult",
      "AWSRoleCreationError",
      "AWSRolePolicyInvalidError",
      "GroupNestingNotAllowedError",
      "MfaInvalidError",
      "OrganizationInReadOnlyModeError",
      "RequestAlreadyActionedError",
      "RequestNotFoundError",
    ],
    AssumeImpersonationOutput: ["AssumeImpersonationResult"],
    AuthCodeCallbackOutput: [
      "AuthCodeCallbackResult",
      "SamlLoginRequiredError",
      "UserAlreadyExistsInAnotherOrg",
      "UserNotFoundError",
    ],
    AuthProviderInfoOutput: [
      "AuthProviderInfoResult",
      "ThirdPartyClientNotFoundError",
    ],
    AuthSessionStatusOutput: ["AuthSessionStatusResult"],
    AwsIamFederatedSession: [
      "AwsIamFederatedEksSession",
      "AwsIamFederatedRdsSession",
      "AwsIamFederatedRoleSession",
      "AwsIamFederatedSSMSession",
    ],
    AzureSession: ["AzureSqlSession"],
    BulkImportRemoteItemsOutput: [
      "BulkImportRemoteItemsResult",
      "GroupMaxDurationTooLargeError",
      "GroupNotFoundError",
      "InvalidReviewerSettingsError",
      "InvalidUpdateGroupVisibilityGroupError",
      "InvalidUpdateResourceVisibilityGroupError",
      "ResourceMaxDurationTooLargeError",
      "TagNotFoundError",
    ],
    BulkUpdateItemsOutput: [
      "BulkUpdateItemsResult",
      "CannotUpdateConfigurationTemplateError",
      "GroupMaxDurationTooLargeError",
      "GroupNotFoundError",
      "InvalidReviewerSettingsError",
      "InvalidUpdateGroupVisibilityGroupError",
      "InvalidUpdateResourceVisibilityGroupError",
      "ResourceMaxDurationTooLargeError",
      "ResourceNotFoundError",
      "TagNotFoundError",
      "TooManyGroupLeadersError",
    ],
    BundleOutput: ["BundleNotFoundError", "BundleResult"],
    BundleVisibilityGroupOutput: [
      "BundleVisibilityGroupNotFoundError",
      "BundleVisibilityGroupResult",
    ],
    CancelRequestOutput: [
      "CancelRequestResult",
      "RequestAlreadyActionedError",
      "RequestNotFoundError",
    ],
    ClearResourceUserPropagationStatusOutput: [
      "ClearResourceUserPropagationStatusResult",
    ],
    ConfigTemplateTagsOutput: ["ConfigTemplateTagsResult"],
    ConfigurationTemplateOutput: [
      "ConfigurationTemplateNotFoundError",
      "ConfigurationTemplateResult",
    ],
    ConfigurationTemplatesOutput: ["ConfigurationTemplatesResult"],
    ConfirmEndSystemRevocationOutput: [
      "AccessReviewAlreadyStoppedError",
      "AccessReviewUserSupportTicketAlreadyLinkedError",
      "ConfirmEndSystemRevocationResult",
      "RemoteUserNotFoundError",
    ],
    ConnectionMetadataOutput: [
      "ADConnectionMetadata",
      "AwsConnectionMetadata",
      "AWSSSOConnectionMetadata",
      "AzureADConnectionMetadata",
      "CustomConnectorConnectionMetadata",
      "DuoConnectionMetadata",
      "GCPConnectionMetadata",
      "GitHubConnectionMetadata",
      "GitLabConnectionMetadata",
      "GoogleGroupsConnectionMetadata",
      "GoogleWorkspaceConnectionMetadata",
      "LDAPConnectionMetadata",
      "MongoAtlasConnectionMetadata",
      "MongoConnectionMetadata",
      "MySQLMariaDBConnectionMetadata",
      "OktaDirectoryConnectionMetadata",
      "PagerDutyConnectionMetadata",
      "PostgresConnectionMetadata",
      "PropagationTicketConnectionMetadata",
      "SalesforceConnectionMetadata",
      "SnowflakeConnectionMetadata",
      "TailscaleConnectionMetadata",
      "TeleportConnectionMetadata",
      "WorkdayConnectionMetadata",
    ],
    ConnectionOutput: ["ConnectionNotFoundError", "ConnectionResult"],
    ConnectionsOutput: ["ConnectionsResult"],
    CountFilteredGroupsOutput: ["CountFilteredGroupsResult"],
    CountFilteredResourcesOutput: ["CountFilteredResourcesResult"],
    CreateAccessReviewTemplateOutput: ["CreateAccessReviewTemplateResult"],
    CreateAuthProviderApplicationOutput: [
      "CreateAuthProviderApplicationResult",
      "HttpsHostnameRequiredError",
    ],
    CreateBundleOutput: ["BundleNameExistsError", "CreateBundleResult"],
    CreateConfigurationTemplateOutput: [
      "ConfigurationTemplateNameExistsError",
      "CreateConfigurationTemplateResult",
    ],
    CreateConnectionOutput: [
      "ConnectionBadMetadataError",
      "ConnectionExistsError",
      "CreateConnectionResult",
      "UserFacingError",
    ],
    CreateEventFilterOutput: [
      "CreateEventFilterResult",
      "EventFilterNotFoundError",
      "InvalidEventFilterNameError",
    ],
    CreateEventStreamOutput: [
      "CreateEventStreamError",
      "CreateEventStreamResult",
    ],
    CreateFirstPartyTokenOutput: ["CreateFirstPartyTokenResult"],
    CreateGroupBindingsOutput: [
      "CreateGroupBindingsResult",
      "GroupAlreadyBelongsToBindingError",
      "GroupBindingHasNoGroupsError",
    ],
    CreateGroupOutput: [
      "CreateGroupResult",
      "GoogleGroupError",
      "OktaGroupError",
    ],
    CreateIdpConnectionOutput: [
      "ConnectionBadMetadataError",
      "CreateIdpConnectionResult",
      "IdpConnectionExistsError",
      "UserFacingError",
    ],
    CreateIdpConnectionUserAttributeImportMappingOutput: [
      "CreateIdpConnectionUserAttributeImportMappingResult",
      "IdpConnectionNotFoundError",
    ],
    CreateOrganizationOutput: [
      "CreateOrganizationResult",
      "OrganizationExistsError",
    ],
    CreateOrUpdateOidcProviderOutput: [
      "CreateOrUpdateOidcProviderResult",
      "OidcIssuerNotValidError",
    ],
    CreateOwnerOutput: [
      "CannotAddUserToSyncedOwnerError",
      "CreateOwnerResult",
      "GroupNotFoundError",
      "MessageChannelNotFoundError",
      "OwnerNameAlreadyExists",
    ],
    CreateOwnersOutput: [
      "CannotAddUserToSyncedOwnerError",
      "CreateOwnersResult",
      "GroupNotFoundError",
      "MessageChannelNotFoundError",
      "OwnerNameAlreadyExists",
    ],
    CreateRemoteMessageChannelOutput: [
      "CreateRemoteMessageChannelResult",
      "MessageChannelInvalidNameError",
      "MessageChannelMissingOAuthScopeError",
      "MessageChannelNameTakenError",
      "MessageChannelUserNotInWorkspaceError",
    ],
    CreateRequestCommentOutput: ["CreateRequestCommentResult"],
    CreateRequestOutput: [
      "BulkRequestTooLargeError",
      "CreateRequestResult",
      "GroupNestingNotAllowedError",
      "ItemCannotBeRequestedError",
      "LinkedGroupNotRequestableError",
      "MfaInvalidError",
      "NoManagerSetForRequestingUserError",
      "NoReviewersSetForGroupError",
      "NoReviewersSetForOwnerError",
      "NoReviewersSetForResourceError",
      "RequestDurationTooLargeError",
      "RequestFieldValueMissingError",
      "RequestReasonMissingError",
      "RequestRequiresUserAuthTokenForConnectionError",
      "TargetUserHasNestedAccessError",
      "UserCannotRequestAccessForTargetGroupError",
    ],
    CreateRequestProposalOutput: [
      "AWSRoleCreationError",
      "AWSRoleCreationReviewerNotSetError",
      "AWSRolePolicyInvalidError",
      "CreateRequestProposalResult",
      "NoManagerSetForRequestingUserError",
      "NoReviewersSetForGroupError",
      "NoReviewersSetForOwnerError",
      "NoReviewersSetForResourceError",
    ],
    CreateRequestTemplateOutput: [
      "CreateRequestTemplateResult",
      "RequestTemplateNameExistsError",
    ],
    CreateResourceCustomAccessLevelEntryOutput: [
      "CreateResourceCustomAccessLevelEntryResult",
    ],
    CreateResourceCustomAccessLevelOutput: [
      "CreateResourceCustomAccessLevelResult",
      "ResourceCustomAccessLevelAlreadyExistsError",
      "ResourceCustomAccessLevelPriorityError",
    ],
    CreateResourceCustomAccessLevelsOutput: [
      "CreateResourceCustomAccessLevelsResult",
    ],
    CreateResourceEntryOutput: ["CreateResourceEntryResult"],
    CreateResourceOutput: [
      "AWSRoleCreationError",
      "AWSRolePolicyInvalidError",
      "CreateResourceResult",
    ],
    CreateResourcesOutput: ["CreateResourcesResult"],
    CreateSamlConnectionOutput: [
      "CertIsTooLargeError",
      "CreateSamlConnectionResult",
      "InvalidEmailError",
    ],
    CreateSamlConnectionProxyOutput: ["CreateSamlConnectionProxyResult"],
    CreateSessionOutput: [
      "CreateSessionResult",
      "EndSystemAuthorizationError",
      "GroupNotFoundError",
      "ImpersonationSessionLengthError",
      "MfaInvalidError",
      "NotSessionableError",
      "OidcIDTokenNotFoundError",
      "ResourceNotFoundError",
      "SessionNotFoundError",
      "UserFacingError",
      "UserImpersonationDisabledError",
      "VaultClientNotFoundError",
      "VaultSessionError",
    ],
    CreateTagOutput: ["CreateTagResult", "TagExistsError"],
    CreateThirdPartyIntegrationOutput: [
      "CreateThirdPartyIntegrationResult",
      "SlackOrganizationAlreadyExistsError",
      "ThirdPartyConnectionNotMatchedError",
      "ThirdPartyUserNotMatchedError",
      "UserFacingError",
    ],
    CreateWebhookInfoOutput: ["CreateWebhookInfoResult"],
    CurrentUserStatsOutput: ["CurrentUserStatsResult"],
    DeleteAccessReviewTemplatesOutput: [
      "AccessReviewTemplateNotFound",
      "DeleteAccessReviewTemplatesResult",
    ],
    DeleteConfigurationTemplateOutput: [
      "ConfigurationInUseError",
      "DeleteConfigurationTemplateResult",
    ],
    DeleteConnectionOutput: [
      "ConnectionNotFoundError",
      "DeleteConnectionResult",
    ],
    DeleteEventFilterOutput: [
      "DeleteEventFilterResult",
      "EventFilterInUseError",
      "EventFilterNotFoundError",
    ],
    DeleteEventStreamOutput: ["DeleteEventStreamResult"],
    DeleteFirstPartyTokenEntryOutput: ["DeleteFirstPartyTokenEntryResult"],
    DeleteFirstPartyTokensOutput: ["DeleteFirstPartyTokensResult"],
    DeleteGroupBindingsOutput: [
      "DeleteGroupBindingsResult",
      "GroupBindingNotFoundError",
    ],
    DeleteGroupOutput: [
      "DeleteGroupResult",
      "GroupHasChildrenError",
      "GroupNotFoundError",
    ],
    DeleteGroupsOutput: [
      "DeleteGroupsResult",
      "GroupHasChildrenError",
      "GroupNotFoundError",
    ],
    DeleteIdpConnectionOutput: [
      "DeleteIdpConnectionResult",
      "IdpConnectionNotFoundError",
    ],
    DeleteIdpConnectionUserAttributeImportMappingOutput: [
      "DeleteIdpConnectionUserAttributeImportMappingResult",
      "IdpConnectionUserAttributeImportMappingNotFoundError",
    ],
    DeleteOrganizationOutput: ["DeleteOrganizationResult"],
    DeleteOwnerOutput: [
      "DeleteOwnerResult",
      "OwnerNotFoundError",
      "OwnerOwnsItemsError",
      "OwnerReviewsItemsError",
    ],
    DeleteOwnersOutput: [
      "DeleteOwnersResult",
      "OwnerNotFoundError",
      "OwnerOwnsItemsError",
      "OwnerReviewsItemsError",
    ],
    DeleteRequestTemplateOutput: ["DeleteRequestTemplateResult"],
    DeleteResourceCustomAccessLevelsOutput: [
      "DeleteResourceCustomAccessLevelsResult",
      "ResourceCustomAccessLevelNotFoundError",
    ],
    DeleteResourcesOutput: [
      "DeleteResourcesResult",
      "OpalResourceImmutableError",
      "ResourceNotFoundError",
    ],
    DeleteSamlConnectionOutput: ["DeleteSamlConnectionResult"],
    DeleteSamlConnectionProxyOutput: ["DeleteSamlConnectionProxyResult"],
    DeleteTagsOutput: ["DeleteTagsResult", "TagNotFoundError"],
    DeleteThirdPartyIntegrationOutput: ["DeleteThirdPartyIntegrationResult"],
    DeleteUserOutput: [
      "DeleteUserResult",
      "OpalAdminRoleMustHaveAtLeastOneDirectUser",
      "SystemUserIsImmutableError",
      "UserNotFoundError",
    ],
    DeleteWebhookInfoOutput: ["DeleteWebhookInfoResult"],
    DenyRequestOutput: [
      "DenyRequestResult",
      "RequestAlreadyActionedError",
      "RequestNotFoundError",
    ],
    DismissGroupBindingSuggestionsOutput: [
      "DismissGroupBindingSuggestionsResult",
      "GroupBindingSuggestionNotFoundError",
    ],
    DismissSyncErrorsOutput: [
      "DismissSyncErrorsResult",
      "SyncErrorNotFoundError",
    ],
    Entity: ["Group", "Resource"],
    Error: [
      "AccessExpiringNotificationsInvalidError",
      "AccessRequestEscalationPeriodInvalidError",
      "AccessReviewAlreadyStoppedError",
      "AccessReviewConnectionNotFoundError",
      "AccessReviewGroupNotFoundError",
      "AccessReviewNotFoundError",
      "AccessReviewNotStoppedError",
      "AccessReviewResourceNotFoundError",
      "AccessReviewTemplateNotFound",
      "AccessReviewUserNotFoundError",
      "AccessReviewUserSupportTicketAlreadyLinkedError",
      "AdminApproveRequiresReasonError",
      "AppNotFoundError",
      "AuthSessionExpirationInvalidError",
      "AWSRoleCreationError",
      "AWSRoleCreationReviewerNotSetError",
      "AWSRolePolicyInvalidError",
      "BulkRequestTooLargeError",
      "BundleNameExistsError",
      "BundleNotAuthorizedError",
      "BundleNotFoundError",
      "BundleVisibilityGroupNotFoundError",
      "CallToWebhookFailedError",
      "CannotAddSystemUserToGroupError",
      "CannotAddSystemUserToOwnerError",
      "CannotAddSystemUserToResourceError",
      "CannotAddUserToSyncedOwnerError",
      "CannotUpdateConfigurationTemplateError",
      "CertIsTooLargeError",
      "ConfigurationInUseError",
      "ConfigurationTemplateNameExistsError",
      "ConfigurationTemplateNotFoundError",
      "ConnectionAccessAlreadyReviewedError",
      "ConnectionBadMetadataError",
      "ConnectionExistsError",
      "ConnectionNotFoundError",
      "ConnectionUserAlreadyExists",
      "ConnectionUserNotFound",
      "ConnectionVisibilityGroupNotFoundError",
      "CreateEventStreamError",
      "CustomerSupportSettingsInvalid",
      "CustomFieldExistsError",
      "EndSystemAuthorizationError",
      "EventFilterInUseError",
      "EventFilterNotFoundError",
      "EventNotFoundError",
      "FactorNotFoundError",
      "GoogleGroupError",
      "GroupAccessAlreadyReviewedError",
      "GroupAccessRequestFrequencyReminderOutOfBoundsError",
      "GroupBindingSuggestionNotFoundError",
      "GroupGroupAlreadyExists",
      "GroupGroupNotFound",
      "GroupHasChildrenError",
      "GroupMaxDurationTooLargeError",
      "GroupMustForfeitOwnershipError",
      "GroupNestingNotAllowedError",
      "GroupNotFoundError",
      "GroupOnCallSchedulePriorityError",
      "GroupResourceAlreadyExists",
      "GroupTagKeyExistsError",
      "GroupUserAlreadyExists",
      "HttpsHostnameRequiredError",
      "IdpConnectionExistsError",
      "IdpConnectionNotFoundError",
      "IdpConnectionUserAttributeImportMappingNotFoundError",
      "ImpersonationSessionLengthError",
      "ImportCustomResourcesInputValidationError",
      "InvalidEmailError",
      "InvalidEventFilterNameError",
      "InvalidObjectIDFilterError",
      "InvalidReviewerSettingsError",
      "InvalidSyncTypeError",
      "InvalidUpdateGroupVisibilityGroupError",
      "InvalidUpdateResourceVisibilityGroupError",
      "ItemAccessAlreadyReviewedError",
      "ItemCannotBeRequestedError",
      "LinkedGroupNotRequestableError",
      "ManagedHRDataIsImmutableError",
      "ManagerHasAlreadyApprovedError",
      "MessageChannelInvalidNameError",
      "MessageChannelMissingOAuthScopeError",
      "MessageChannelNameTakenError",
      "MessageChannelNotFoundError",
      "MessageChannelRateLimitedError",
      "MessageChannelUserNotInWorkspaceError",
      "MfaInvalidError",
      "MissingIAMPermissionError",
      "MissingTokenPermissionError",
      "NoAccessReviewInProgressError",
      "NoBreakGlassAccessError",
      "NoIDPConfiguredError",
      "NoManagerSetForRequestingUserError",
      "NoProposedChangesError",
      "NoReviewersSetForGroupError",
      "NoReviewersSetForOwnerError",
      "NoReviewersSetForResourceError",
      "NoSkipManagerSetForTargetUserError",
      "NotSessionableError",
      "OidcIDTokenNotFoundError",
      "OktaGroupError",
      "OpalAdminRoleMustHaveAtLeastOneDirectUser",
      "OpalGlobalImpersonationResourceDirectAddNotAllowedError",
      "OpalResourceImmutableError",
      "OrganizationExistsError",
      "OrganizationInReadOnlyModeError",
      "OrganizationSettingsNotFoundError",
      "OrgMaxGroupDurationInvalidError",
      "OrgMaxResourceDurationInvalidError",
      "OwnerAccessRequestFrequencyReminderOutOfBoundsError",
      "OwnerNameAlreadyExists",
      "OwnerNotFoundError",
      "OwnerOwnsItemsError",
      "OwnerReviewsItemsError",
      "OwnerUserAlreadyExists",
      "PushTaskNotFoundError",
      "RemoteMessageChannelAlreadyLinked",
      "RemoteMessageChannelNotFound",
      "RemoteUserNotFound",
      "RemoteUserNotFoundError",
      "RequestAlreadyActionedError",
      "RequestAlreadyEscalatedToSkipManagerError",
      "RequestDurationTooLargeError",
      "RequestFieldValueMissingError",
      "RequestNotFoundError",
      "RequestReasonMissingError",
      "RequestRequiresUserAuthTokenForConnectionError",
      "RequestTemplateNameExistsError",
      "RequeueEventStreamMessagesError",
      "ResetAuth0MFAError",
      "ResourceAccessAlreadyReviewedError",
      "ResourceCustomAccessLevelAlreadyExistsError",
      "ResourceCustomAccessLevelNotFoundError",
      "ResourceCustomAccessLevelPriorityError",
      "ResourceMaxDurationTooLargeError",
      "ResourceNotFoundError",
      "ResourceTagKeyExistsError",
      "ResourceTagNotFoundError",
      "ResourceUserAlreadyExists",
      "ReviewersAlreadyRemindedError",
      "RoleAssignmentAlreadyExists",
      "RoleAssignmentNotFoundError",
      "SamlLoginRequiredError",
      "SamlNotConfiguredForOrgError",
      "SessionNotFoundError",
      "SlackOrganizationAlreadyExistsError",
      "SupportTicketAPIKeyNotFoundError",
      "SupportTicketNotFoundError",
      "SyncErrorNotFoundError",
      "SyncTaskNotFoundError",
      "SystemUserIsImmutableError",
      "TagExistsError",
      "TagNotFoundError",
      "TargetUserHasNestedAccessError",
      "ThirdPartyClientNotFoundError",
      "ThirdPartyConnectionNotFoundError",
      "ThirdPartyConnectionNotMatchedError",
      "ThirdPartyIntegrationNotFoundError",
      "ThirdPartyUserNotMatchedError",
      "TooManyGroupLeadersError",
      "UpdateEventStreamError",
      "UserAlreadyExistsInAnotherOrg",
      "UserCannotRequestAccessForTargetGroupError",
      "UserFacingError",
      "UserImpersonationDisabledError",
      "UserNotFoundError",
      "UserSettingsInvalidError",
      "UserSettingsNotFoundError",
      "UserTagKeyExistsError",
      "UserTagNotFoundError",
      "VaultClientNotFoundError",
      "VaultSessionError",
      "VisualizationTimeoutError",
    ],
    EscalateRequestToSkipManagerOutput: [
      "EscalateRequestToSkipManagerResult",
      "ManagerHasAlreadyApprovedError",
      "NoManagerSetForRequestingUserError",
      "NoSkipManagerSetForTargetUserError",
      "RequestAlreadyEscalatedToSkipManagerError",
      "RequestNotFoundError",
    ],
    EventFilterOutput: ["EventFilterNotFoundError", "EventFilterResult"],
    EventFiltersOutput: ["EventFiltersResult"],
    EventOutput: ["EventNotFoundError", "EventResult"],
    EventsOutput: ["EventsResult", "InvalidObjectIDFilterError"],
    FilteredGroupsOutput: ["FilteredGroupsResult"],
    FilteredResourcesOutput: ["FilteredResourcesResult"],
    FirstPartyTokensOutput: ["FirstPartyTokensResult"],
    ForfeitGroupOutput: ["ForfeitGroupResult", "GroupNotFoundError"],
    ForfeitResourceOutput: [
      "ForfeitResourceResult",
      "OidcIDTokenNotFoundError",
      "OpalAdminRoleMustHaveAtLeastOneDirectUser",
      "ResourceNotFoundError",
    ],
    GroupAccessLevelsOutput: ["GroupAccessLevelsResult", "GroupNotFoundError"],
    GroupBindingOutput: ["GroupBinding", "GroupBindingNotFoundError"],
    GroupBindingSuggestionOutput: [
      "GroupBindingSuggestion",
      "GroupBindingSuggestionNotFoundError",
    ],
    GroupGroupAccessOutput: ["GroupGroupAccessResult", "GroupGroupNotFound"],
    GroupOnCallSchedulesOutput: ["GroupOnCallSchedulesResult"],
    GroupOutput: ["GroupNotFoundError", "GroupResult"],
    GroupRequestStatusCountsOutput: [
      "GroupNotFoundError",
      "GroupRequestStatusCountsResult",
    ],
    GroupsOutput: ["GroupsResult"],
    GroupTypesWithCountsOutput: ["GroupTypesWithCountsResult"],
    IdpConnectionOutput: ["IdpConnectionNotFoundError", "IdpConnectionResult"],
    ImportAllOutput: [
      "BulkUpdateItemsResult",
      "GroupNotFoundError",
      "ResourceNotFoundError",
    ],
    ImportCustomResourcesDryRunOutput: [
      "ImportCustomResourcesDryRunResult",
      "ImportCustomResourcesInputValidationError",
    ],
    ImportCustomResourcesOutput: [
      "ImportCustomResourcesInputValidationError",
      "ImportCustomResourcesResult",
    ],
    InitOidcAuthFlowOutput: [
      "InitOidcAuthFlowResult",
      "OidcProviderNotFoundError",
    ],
    InitThirdPartyIntegrationAuthFlowOutput: [
      "ConnectionNotFoundError",
      "InitThirdPartyIntegrationAuthFlowResult",
      "ThirdPartyIntegrationNotFoundError",
    ],
    InitThirdPartyIntegrationLinkAuthFlowOutput: [
      "InitThirdPartyIntegrationLinkAuthFlowResult",
    ],
    InviteUsersOutput: ["InviteUsersResult", "UserAlreadyExistsInAnotherOrg"],
    LinkExistingRemoteMessageChannelOutput: [
      "LinkExistingRemoteMessageChannelResult",
      "MessageChannelMissingOAuthScopeError",
      "RemoteMessageChannelAlreadyLinked",
      "RemoteMessageChannelNotFound",
    ],
    ListEventStreamMessagesOutput: ["ListEventStreamMessagesResult"],
    ListEventStreamsOutput: ["ListEventStreamsResult"],
    ListFactorsOutput: [
      "ConnectionUserNotFound",
      "ListFactorsResult",
      "MissingTokenPermissionError",
      "NoIDPConfiguredError",
    ],
    ListRoleAssignmentsOutput: ["ListRoleAssignmentsResult"],
    MessageChannelsOutput: [
      "MessageChannelMissingOAuthScopeError",
      "MessageChannelRateLimitedError",
      "MessageChannelsResult",
    ],
    MfaCallbackOutput: ["MfaCallbackResult"],
    MultipleGroupAccessLevelsOutput: [
      "GroupNotFoundError",
      "MultipleGroupAccessLevelsResult",
    ],
    MultipleResourceAccessLevelsOutput: [
      "MultipleResourceAccessLevelsResult",
      "ResourceNotFoundError",
    ],
    MyAccessReviewItemsOutput: [
      "AccessReviewNotFoundError",
      "MyAccessReviewItemsResult",
    ],
    OidcProviderOutput: ["OidcProviderNotFoundError", "OidcProviderResult"],
    OnCallSchedulesOutput: ["OnCallSchedulesResult", "UserFacingError"],
    OngoingAccessReviewSubtabStatsOutput: [
      "AccessReviewAlreadyStoppedError",
      "AccessReviewNotFoundError",
      "OngoingAccessReviewSubtabStatsResult",
    ],
    OngoingAccessReviewTabStatsOutput: [
      "AccessReviewAlreadyStoppedError",
      "AccessReviewNotFoundError",
      "OngoingAccessReviewTabStatsResult",
    ],
    OrganizationSettingsOutput: [
      "OrganizationSettingsNotFoundError",
      "OrganizationSettingsResult",
    ],
    OwnerOutput: ["OwnerNotFoundError", "OwnerResult"],
    OwnersOutput: ["OwnersResult"],
    PerformReviewOutput: [
      "AccessReviewAlreadyStoppedError",
      "AccessReviewNotFoundError",
      "ItemCannotBeRequestedError",
      "OidcIDTokenNotFoundError",
      "PerformReviewResult",
    ],
    Principal: ["Group", "Resource", "User"],
    PrincipalsOutput: ["PrincipalsResult"],
    PubsubPublishConnectionCredentialOutput: [
      "WebhookPubsubPublishConnectionCredentialsOutput",
    ],
    PubsubPublishConnectionMetadataOutput: [
      "WebhookPubsubPublishConnectionMetadata",
    ],
    PushTask: ["PushTaskComplete", "PushTaskPending"],
    PushTaskCompleteResult: [
      "ConnectionUserPushTaskResult",
      "GroupGroupPushTaskResult",
      "GroupResourcePushTaskResult",
      "GroupUserPushTaskResult",
      "ResourceUserPushTaskResult",
      "RoleAssignmentPushTaskResult",
    ],
    PushTaskOutput: ["PushTaskNotFoundError", "PushTaskResult"],
    ReadUINotificationOutput: ["ReadUINotificationResult"],
    ReadUINotificationsOutput: ["ReadUINotificationsResult"],
    RecommendationsEntity: ["Group", "Resource"],
    RecordGroupViewOutput: ["RecordGroupViewResult"],
    RecordResourceViewOutput: ["RecordResourceViewResult"],
    RegenerateAccessReviewReportOutput: [
      "AccessReviewNotFoundError",
      "AccessReviewNotStoppedError",
      "RegenerateAccessReviewReportResult",
    ],
    RegenerateSigningSecretOutput: ["RegenerateSigningSecretResult"],
    RemediateAddGroupResourcesOutput: [
      "AddGroupResourcesResult",
      "CallToWebhookFailedError",
      "GroupNotFoundError",
      "GroupResourceAlreadyExists",
      "UserFacingError",
    ],
    RemediateAddGroupUsersOutput: [
      "AddGroupUsersResult",
      "CallToWebhookFailedError",
      "CannotAddSystemUserToGroupError",
      "GroupNotFoundError",
      "GroupUserAlreadyExists",
    ],
    RemediateAddResourceUsersOutput: [
      "AddResourceUsersResult",
      "CallToWebhookFailedError",
      "CannotAddSystemUserToResourceError",
      "OpalAdminRoleMustHaveAtLeastOneDirectUser",
      "OpalGlobalImpersonationResourceDirectAddNotAllowedError",
      "ResourceNotFoundError",
      "ResourceUserAlreadyExists",
    ],
    RemediateAddRoleAssignmentsOutput: [
      "AddRoleAssignmentsResult",
      "RoleAssignmentNotFoundError",
    ],
    RemoteAppItemsOutput: ["RemoteAppItemsResult", "UserFacingError"],
    RemoteResourcesOutput: [
      "ConnectionNotFoundError",
      "MissingIAMPermissionError",
      "RemoteResourcesResult",
      "UserFacingError",
    ],
    RemoveConnectionUsersOutput: [
      "ConnectionNotFoundError",
      "ConnectionUserNotFound",
      "RemoveConnectionUsersResult",
    ],
    RemoveGroupGroupsEntryOutput: ["RemoveGroupGroupsEntryResult"],
    RemoveGroupGroupsOutput: [
      "CallToWebhookFailedError",
      "GroupNotFoundError",
      "RemoveGroupGroupsResult",
      "UserFacingError",
    ],
    RemoveGroupResourcesEntryOutput: ["RemoveGroupResourcesEntryResult"],
    RemoveGroupResourcesOutput: [
      "CallToWebhookFailedError",
      "GroupNotFoundError",
      "RemoveGroupResourcesResult",
      "UserFacingError",
    ],
    RemoveGroupTagsOutput: ["RemoveGroupTagsResult"],
    RemoveGroupUsersEntryOutput: ["RemoveGroupUsersEntryResult"],
    RemoveGroupUsersOutput: [
      "CallToWebhookFailedError",
      "GroupNotFoundError",
      "RemoveGroupUsersResult",
    ],
    RemoveResourceTagsEntryOutput: ["RemoveResourceTagsEntryResult"],
    RemoveResourceTagsOutput: [
      "RemoveResourceTagsResult",
      "ResourceTagNotFoundError",
    ],
    RemoveResourceUsersEntryOutput: ["RemoveResourceUsersEntryResult"],
    RemoveResourceUsersOutput: [
      "CallToWebhookFailedError",
      "OidcIDTokenNotFoundError",
      "OpalAdminRoleMustHaveAtLeastOneDirectUser",
      "RemoveResourceUsersResult",
      "ResourceNotFoundError",
    ],
    RemoveRoleAssignmentsEntryOutput: ["RemoveRoleAssignmentsEntryResult"],
    RemoveRoleAssignmentsOutput: ["RemoveRoleAssignmentsResult"],
    RemoveUserTagsEntryOutput: ["RemoveUserTagsEntryResult"],
    RemoveUserTagsOutput: ["RemoveUserTagsResult", "UserTagNotFoundError"],
    RequestOutput: ["RequestNotFoundError", "RequestResult"],
    RequestsOutput: ["RequestsResult"],
    RequestTemplateOutput: ["RequestTemplateResult"],
    RequestTemplatesOutput: ["RequestTemplatesResult"],
    RequestTemplatesWithFieldsOutput: ["RequestTemplatesWithFieldsResult"],
    RequeueEventStreamMessagesOutput: [
      "RequeueEventStreamMessagesError",
      "RequeueEventStreamMessagesResult",
    ],
    RescoreRecommendationsOutput: ["RescoreRecommendationsResult"],
    ResetDataseedOutput: ["ResetDataseedResult"],
    ResetRemediationsOutput: ["ResetRemediationsResult"],
    ResetUserMFAOutput: [
      "ResetAuth0MFAError",
      "ResetUserMFAResult",
      "SystemUserIsImmutableError",
      "UserNotFoundError",
    ],
    ResourceAccessLevelsOutput: [
      "ResourceAccessLevelsResult",
      "ResourceNotFoundError",
    ],
    ResourceCustomAccessLevelOutput: [
      "ResourceCustomAccessLevelNotFoundError",
      "ResourceCustomAccessLevelResult",
    ],
    ResourceCustomAccessLevelsOutput: ["ResourceCustomAccessLevelsResult"],
    ResourceMetadata: [
      "AwsRoleMetadata",
      "OktaDirectoryAppMetadata",
      "OktaRoleMetadata",
      "PropagationTicketOwnerMetadata",
    ],
    ResourceOutput: ["ResourceNotFoundError", "ResourceResult"],
    ResourceProposalMetadata: ["AWSRoleProposalMetadata"],
    ResourceRequestStatusCountsOutput: [
      "ResourceNotFoundError",
      "ResourceRequestStatusCountsResult",
    ],
    ResourcesOutput: ["ResourcesResult"],
    ResourceTagsOutput: ["ResourceTagsResult"],
    ResourceTypesWithCountsOutput: ["ResourceTypesWithCountsResult"],
    ResourceUsageOutput: [
      "ResourceNotFoundError",
      "ResourceUsageResult",
      "UserFacingError",
    ],
    ResourceUserPropagationMetadata: ["PropagationTicketMetadata"],
    SamlConnectionOutput: ["SamlConnectionResult"],
    SearchOutput: ["SearchResult"],
    SendAccessReviewReminderOutput: [
      "AccessReviewAlreadyStoppedError",
      "AccessReviewNotFoundError",
      "SendAccessReviewReminderResult",
    ],
    SendManualRequestReminderOutput: [
      "RequestNotFoundError",
      "ReviewersAlreadyRemindedError",
      "SendManualRequestReminderResult",
    ],
    SessionMetadata: [
      "AwsIamFederatedEksSession",
      "AwsIamFederatedRdsSession",
      "AwsIamFederatedRoleSession",
      "AwsIamFederatedSSMSession",
      "AzureSqlSession",
      "OidcSession",
      "OpalImpersonationSession",
      "VaultMongoAtlasSession",
      "VaultMongoSession",
      "VaultMySQLMariaDBSession",
      "VaultPostgresSession",
    ],
    SessionsOutput: ["SessionsResult"],
    SetNoteOutput: [
      "AccessReviewAlreadyStoppedError",
      "AccessReviewNotFoundError",
      "SetNoteResult",
    ],
    SetOwnerUsersOutput: [
      "CannotAddSystemUserToOwnerError",
      "CannotAddUserToSyncedOwnerError",
      "OwnerNotFoundError",
      "UpdateOwnerUsersForOwnerResult",
      "UserNotFoundError",
    ],
    SetRolesOutput: ["SetRolesResult"],
    SignInMethodOutput: ["InvalidEmailError", "SignInMethodResult"],
    SignInOutput: ["SignInResult"],
    SignOutOutput: ["SignOutResult"],
    StartAccessReviewOutput: ["StartAccessReviewResult"],
    StartAccessReviewStatsOutput: ["StartAccessReviewStatsResult"],
    StartSyncOutput: [
      "AccessReviewNotFoundError",
      "ConnectionNotFoundError",
      "GroupNotFoundError",
      "InvalidSyncTypeError",
      "ResourceNotFoundError",
      "StartSyncResult",
    ],
    StopAccessReviewOutput: [
      "AccessReviewAlreadyStoppedError",
      "AccessReviewNotFoundError",
      "StopAccessReviewResult",
    ],
    StopImpersonationOutput: ["StopImpersonationResult"],
    Suggestion: ["GroupSuggestion", "ResourceSuggestion"],
    SuggestionsOutput: ["SuggestionsResult"],
    SupportTicketOutput: ["SupportTicketNotFoundError", "SupportTicketResult"],
    SupportTicketsOutput: [
      "SupportTicketAPIKeyNotFoundError",
      "SupportTicketsResult",
      "UserFacingError",
    ],
    SyncStatusOutput: [
      "AccessReviewNotFoundError",
      "ConnectionNotFoundError",
      "GroupNotFoundError",
      "InvalidSyncTypeError",
      "ResourceNotFoundError",
      "SyncStatusResult",
    ],
    SyncTaskOutput: ["SyncTaskNotFoundError", "SyncTaskResult"],
    TagOutput: ["TagNotFoundError", "TagResult"],
    TagsOutput: ["TagsResult"],
    TeamsOutput: ["TeamsResult"],
    ThirdPartyIntegrationOutput: [
      "ConnectionNotFoundError",
      "ThirdPartyIntegrationNotFoundError",
      "ThirdPartyIntegrationResult",
    ],
    ThirdPartyIntegrationsOutput: ["ThirdPartyIntegrationsResult"],
    TicketProjectsOutput: [
      "SupportTicketAPIKeyNotFoundError",
      "TicketProjectsResult",
    ],
    TitlesOutput: ["TitlesResult"],
    UpdateAccessReviewGroupReviewersOutput: [
      "AccessReviewAlreadyStoppedError",
      "UpdateAccessReviewGroupReviewersResult",
    ],
    UpdateAccessReviewOutput: [
      "AccessReviewAlreadyStoppedError",
      "AccessReviewNotFoundError",
      "UpdateAccessReviewResult",
    ],
    UpdateAccessReviewResourceReviewersOutput: [
      "AccessReviewAlreadyStoppedError",
      "UpdateAccessReviewResourceReviewersResult",
    ],
    UpdateAccessReviewReviewersOutput: [
      "UpdateAccessReviewReviewersResult",
      "UpdateAccessReviewReviewersValidationError",
    ],
    UpdateAccessReviewTemplateOutput: [
      "AccessReviewTemplateNotFound",
      "UpdateAccessReviewTemplateResult",
    ],
    UpdateBundleOutput: [
      "BundleNameExistsError",
      "BundleNotAuthorizedError",
      "UpdateBundleResult",
    ],
    UpdateConfigTemplateTagsOutput: ["UpdateConfigTemplateTagsResult"],
    UpdateConfigurationTemplateOutput: [
      "ConfigurationTemplateNameExistsError",
      "UpdateConfigurationTemplateResult",
    ],
    UpdateConnectionOutput: [
      "ConnectionBadMetadataError",
      "ConnectionNotFoundError",
      "ConnectionVisibilityGroupNotFoundError",
      "UpdateConnectionResult",
      "UserFacingError",
    ],
    UpdateConnectionReviewersOutput: [
      "AccessReviewAlreadyStoppedError",
      "UpdateConnectionReviewersResult",
    ],
    UpdateConnectionUserReviewersOutput: [
      "AccessReviewAlreadyStoppedError",
      "UpdateAccessReviewReviewersValidationError",
      "UpdateConnectionUserReviewersResult",
    ],
    UpdateEventStreamOutput: [
      "UpdateEventStreamError",
      "UpdateEventStreamResult",
    ],
    UpdateGroupBindingsOutput: [
      "GroupAlreadyBelongsToBindingError",
      "GroupBindingHasNoGroupsError",
      "GroupBindingNotFoundError",
      "UpdateGroupBindingsResult",
    ],
    UpdateGroupGroupsOutput: [
      "GroupNestingNotAllowedError",
      "GroupNotFoundError",
      "UpdateGroupGroupsResult",
      "UserFacingError",
    ],
    UpdateGroupOutput: ["GroupNotFoundError", "UpdateGroupResult"],
    UpdateGroupResourceReviewersOutput: [
      "AccessReviewAlreadyStoppedError",
      "UpdateGroupResourceReviewersResult",
    ],
    UpdateGroupResourcesOutput: [
      "CallToWebhookFailedError",
      "GroupNotFoundError",
      "ResourceNotFoundError",
      "UpdateGroupResourcesResult",
      "UserFacingError",
    ],
    UpdateGroupUserReviewersOutput: [
      "AccessReviewAlreadyStoppedError",
      "UpdateAccessReviewReviewersValidationError",
      "UpdateGroupUserReviewersResult",
    ],
    UpdateGroupUsersEntryOutput: ["UpdateGroupUsersEntryResult"],
    UpdateGroupUsersOutput: ["GroupNotFoundError", "UpdateGroupUsersResult"],
    UpdateIdpConnectionOutput: [
      "IdpConnectionNotFoundError",
      "UpdateIdpConnectionResult",
    ],
    UpdateOrganizationSettingsOutput: [
      "AccessExpiringNotificationsInvalidError",
      "AccessRequestEscalationPeriodInvalidError",
      "AuthSessionExpirationInvalidError",
      "CustomerSupportSettingsInvalid",
      "OidcIssuerNotValidError",
      "OrganizationSettingsNotFoundError",
      "OrgMaxGroupDurationInvalidError",
      "OrgMaxResourceDurationInvalidError",
      "UpdateOrganizationSettingsResult",
    ],
    UpdateOwnerOutput: [
      "CannotAddUserToSyncedOwnerError",
      "GroupNotFoundError",
      "MessageChannelNotFoundError",
      "OwnerAccessRequestFrequencyReminderOutOfBoundsError",
      "OwnerNotFoundError",
      "UpdateOwnerResult",
    ],
    UpdateRequestTemplateOutput: [
      "CustomFieldExistsError",
      "RequestTemplateNameExistsError",
      "UpdateRequestTemplateResult",
    ],
    UpdateResourceCustomAccessLevelOutput: [
      "ResourceCustomAccessLevelNotFoundError",
      "ResourceCustomAccessLevelPriorityError",
      "UpdateResourceCustomAccessLevelResult",
    ],
    UpdateResourcesOutput: [
      "ConfigurationVisibilityGroupNotFoundError",
      "InvalidUpdateResourceVisibilityGroupError",
      "OpalResourceImmutableError",
      "ResourceNotFoundError",
      "UpdateResourcesResult",
    ],
    UpdateResourceUserReviewersOutput: [
      "AccessReviewAlreadyStoppedError",
      "UpdateAccessReviewReviewersValidationError",
      "UpdateResourceUserReviewersResult",
    ],
    UpdateResourceUsersOutput: [
      "ResourceNotFoundError",
      "UpdateResourceUsersResult",
    ],
    UpdateResourceVisibilityGroupsOutput: [
      "ConfigurationVisibilityGroupNotFoundError",
      "GroupNotFoundError",
      "InvalidUpdateResourceVisibilityGroupError",
      "ResourceNotFoundError",
      "UpdateResourceVisibilityGroupsResult",
    ],
    UpdateSamlConnectionOutput: ["UpdateSamlConnectionResult"],
    UpdateUserManagerOutput: [
      "ManagedHRDataIsImmutableError",
      "SystemUserIsImmutableError",
      "UpdateUserManagerResult",
      "UserNotFoundError",
    ],
    UpdateUserOutput: [
      "ManagedHRDataIsImmutableError",
      "SystemUserIsImmutableError",
      "UpdateUserResult",
      "UserNotFoundError",
    ],
    UpdateUserSettingsOutput: [
      "UpdateUserSettingsResult",
      "UserSettingsInvalidError",
      "UserSettingsNotFoundError",
    ],
    UpdateWebhookInfoOutput: ["UpdateWebhookInfoResult"],
    UseGroupBreakGlassOutput: [
      "GroupNotFoundError",
      "GroupUserAlreadyExists",
      "NoBreakGlassAccessError",
      "UseGroupBreakGlassResult",
    ],
    UserOutput: ["UserNotFoundError", "UserResult"],
    UserSettingsOutput: [
      "UserSettingsInvalidError",
      "UserSettingsNotFoundError",
      "UserSettingsResult",
    ],
    UsersOutput: ["UsersResult"],
    UserTagsOutput: ["UserTagsResult"],
    VaultSessionsOutput: ["VaultSessionsResult"],
    VerifyFactorAsyncOutput: [
      "ConnectionUserNotFound",
      "FactorNotFoundError",
      "MissingTokenPermissionError",
      "VerifyFactorAsyncResult",
    ],
    VerifyFactorSyncOutput: [
      "ConnectionUserNotFound",
      "FactorNotFoundError",
      "MissingTokenPermissionError",
      "VerifyFactorSyncResult",
    ],
    VisualizationOutput: [
      "UserFacingError",
      "VisualizationResult",
      "VisualizationTimeoutError",
    ],
    WebhookInfoOutput: ["WebhookInfoResult"],
  },
};
export default result;
