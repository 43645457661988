import { gql, useQuery } from "@apollo/client";
import {
  AppCategory,
  InventoryPageQuery,
  OpalAppGroupCountCellFragmentDoc,
  OpalAppNameCellFragmentDoc,
  OpalAppResourceCountCellFragmentDoc,
  OpalAppSourceCellFragmentDoc,
  OpalAppUserCountCellFragmentDoc,
  OpalAppVisibilityCellFragmentDoc,
} from "api/generated/graphql";
import OpalAppGroupCountCell from "components/opal/table/cells/app/OpalAppGroupCountCell";
import OpalAppNameCell from "components/opal/table/cells/app/OpalAppNameCell";
import OpalAppResourceCountCell from "components/opal/table/cells/app/OpalAppResourceCountCell";
import OpalAppSourceCell from "components/opal/table/cells/app/OpalAppSourceCell";
import OpalAppUserCountCell from "components/opal/table/cells/app/OpalAppUserCountCell";
import OpalAppVisibilityCell from "components/opal/table/cells/app/OpalAppVisibilityCell";
import OpalTable, { Column } from "components/opal/table/OpalTable";
import { useAccessOptionKey } from "views/apps/utils";

const QUERY = gql`
  query InventoryPage($access: AccessOption!, $appCategory: AppCategory) {
    apps(access: $access, appCategory: $appCategory) {
      apps {
        id
        ...OpalAppNameCell
        ...OpalAppVisibilityCell
        ...OpalAppResourceCountCell
        ...OpalAppGroupCountCell
        ...OpalAppUserCountCell
        ...OpalAppSourceCell
      }
      totalNumApps
    }
  }
  ${OpalAppNameCellFragmentDoc}
  ${OpalAppVisibilityCellFragmentDoc}
  ${OpalAppResourceCountCellFragmentDoc}
  ${OpalAppGroupCountCellFragmentDoc}
  ${OpalAppUserCountCellFragmentDoc}
  ${OpalAppSourceCellFragmentDoc}
`;

const COLUMN_IDS = {
  NAME: "name",
  VISIBILITY: "visibility",
  RESOURCE_COUNT: "resource_count",
  GROUP_COUNT: "group_count",
  USER_COUNT: "user_count",
  SOURCE: "source",
};

type RowData = InventoryPageQuery["apps"]["apps"][0];

const COLUMNS: Column<RowData>[] = [
  {
    id: COLUMN_IDS.NAME,
    label: "Name",
    width: 400,
    sortable: true,
    customCellRenderer: (row: RowData) => {
      return <OpalAppNameCell appID={row.id} />;
    },
  },
  {
    id: COLUMN_IDS.VISIBILITY,
    label: "Visibility",
    sortable: true,
    customCellRenderer: (row: RowData) => {
      return <OpalAppVisibilityCell appID={row.id} />;
    },
  },
  {
    id: COLUMN_IDS.RESOURCE_COUNT,
    label: "Resources",
    sortable: true,
    customCellRenderer: (row) => {
      return <OpalAppResourceCountCell appID={row.id} />;
    },
  },
  {
    id: COLUMN_IDS.GROUP_COUNT,
    label: "Group Access",
    sortable: false, // groupCount has its own resolver and so can't be sorted server-side
    customCellRenderer: (row) => {
      return <OpalAppGroupCountCell appID={row.id} />;
    },
  },
  {
    id: COLUMN_IDS.USER_COUNT,
    label: "User Access",
    sortable: false, // userCount has its own resolver and so can't be sorted server-side
    customCellRenderer: (row) => {
      return <OpalAppUserCountCell appID={row.id} />;
    },
  },
  {
    id: COLUMN_IDS.SOURCE,
    label: "Source",
    sortable: true,
    customCellRenderer: (row) => {
      return <OpalAppSourceCell appID={row.id} />;
    },
  },
];

export default () => {
  const [accessOptionKey] = useAccessOptionKey();

  const { data } = useQuery<InventoryPageQuery>(QUERY, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      access: accessOptionKey,
      appCategory: AppCategory.All,
    },
  });

  return (
    <OpalTable
      autoHeight
      rows={data?.apps.apps || []}
      columns={COLUMNS}
      totalNumRows={data?.apps.totalNumApps || 0}
      getRowId={(row) => row.id}
    ></OpalTable>
  );
};
