import { getModifiedErrorMessage } from "api/ApiContext";
import {
  GeneralSettingType,
  IdpConnectionType,
  OidcProviderType,
  UpdateOrganizationSettingsInput,
  useOidcProviderQuery,
  useUpdateOrganizationSettingsMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { MoreInfo } from "components/more_info/MoreInfo";
import { useToast } from "components/toast/Toast";
import { ButtonV3, FormGroup, Input, Modal, RadioGroup } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useContext, useEffect, useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logWarning } from "utils/logging";
import { isValidHttpsUrl } from "utils/url";
import OrgContext, { OrgContextActionType } from "views/settings/OrgContext";
import orgSettingsStyles from "views/settings/OrgSettings.module.scss";
import * as styles from "views/settings/OrgSettingsV3.css";

export type MFASettingsProps = {
  currentSettings: GeneralSettingType[];
  initialState: GeneralSettingType | null;
};

export const MFASettings = (props: MFASettingsProps) => {
  const { orgState, orgDispatch } = useContext(OrgContext);

  const hasOkta = orgState.orgIdpConnections.some(
    (connection) => connection.idpConnectionType === IdpConnectionType.Okta
  );
  const options = [
    {
      value: 0,
      label: "Opal MFA",
      description: "Opal manages MFA.",
      providerType: null,
      generalSettingType: null,
    },
    {
      value: 1,
      label: "Okta MFA",
      description:
        (hasOkta ? "Requires selecting Okta as your Identity Provider. " : "") +
        "If enabled, Opal validates users via their Okta MFA (Okta Verify and TOTP only). This lets you consolidate MFA providers and manage MFA resets in Okta.",
      generalSettingType: GeneralSettingType.UseOktaMfaForGatingOpalActions,
      disabled: !hasOkta,
    },
    {
      value: 2,
      label: "OIDC MFA",
      description:
        "If enabled, Opal validates users via an OIDC application. You can modify MFA settings through your OIDC provider.",
      providerType: OidcProviderType.Mfa,
      generalSettingType: GeneralSettingType.UseOidcMfaForGatingOpalActions,
    },
  ];
  const [selectedMfaValue, setSelectedMfaValue] = useState(props.initialState);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (props.initialState) {
      setSelectedMfaValue(props.initialState);
    }
  }, [props.initialState]);

  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [issuerUrl, setIssuerUrl] = useState("https://");
  const invalidOidcFields =
    clientId === "" || clientSecret === "" || !isValidHttpsUrl(issuerUrl);

  const { authState } = useContext(AuthContext);
  const { displaySuccessToast, displayErrorToast } = useToast();

  const [
    updateOrgSettings,
    { loading: updateOrgLoading },
  ] = useUpdateOrganizationSettingsMutation();

  useOidcProviderQuery({
    variables: {
      input: {
        oidcProviderType: OidcProviderType.Mfa,
      },
    },
    onCompleted: (data) => {
      switch (data?.oidcProvider.__typename) {
        case "OidcProviderResult": {
          setClientId(data.oidcProvider.oidcProvider.clientId);
          setIssuerUrl(data.oidcProvider.oidcProvider.issuerUrl);
          break;
        }
        case "OidcProviderNotFoundError":
          // Do nothing, keep defaults.
          break;
      }
    },
    onError: (error) => {
      logWarning(error, "failed to fetch OIDC provider");
    },
  });

  const onSubmit = async () => {
    const generalSetting = selectedMfaValue;
    let newSettings = props.currentSettings.filter(
      (setting) =>
        setting !== GeneralSettingType.UseOktaMfaForGatingOpalActions &&
        setting !== GeneralSettingType.UseOidcMfaForGatingOpalActions
    );
    if (generalSetting) {
      newSettings = [...newSettings, generalSetting];
    }
    let input: UpdateOrganizationSettingsInput = {
      settings: newSettings,
    };
    if (
      selectedMfaValue === GeneralSettingType.UseOidcMfaForGatingOpalActions
    ) {
      input.createOrUpdateOidcProviderInput = {
        clientId: clientId,
        clientSecret: clientSecret,
        issuerUrl: issuerUrl,
        oidcProviderType: OidcProviderType.Mfa,
      };
    }
    try {
      const { data } = await updateOrgSettings({
        variables: {
          input: input,
        },
      });
      switch (data?.updateOrganizationSettings.__typename) {
        case "UpdateOrganizationSettingsResult":
          orgDispatch({
            type: OrgContextActionType.OrgSettings,
            payload: {
              orgSettings: data.updateOrganizationSettings.settings,
            },
          });
          setShowModal(false);
          displaySuccessToast("Success: settings updated");
          break;
        case "OidcIssuerNotValidError":
          displayErrorToast(data.updateOrganizationSettings.message);
          break;

        default:
          displayErrorToast("Error: failed to update org settings");
      }
    } catch (error) {
      displayErrorToast(
        getModifiedErrorMessage("Error: failed to update org settings", error)
      );
    }
  };

  const oidcInput = (
    <div
      className={sprinkles({
        paddingTop: "lg",
        paddingLeft: "xs",
        marginLeft: "lg",
        marginRight: "lg",
      })}
    >
      <FormGroup label="Client ID:">
        <Input
          value={clientId}
          onChange={(value) => setClientId(value)}
          type="text"
        />
      </FormGroup>
      <FormGroup label="Client Secret:">
        <Input
          value={clientSecret}
          onChange={(value) => setClientSecret(value)}
          type="text"
        />
      </FormGroup>
      <FormGroup label="Issuer URL:">
        <Input
          value={issuerUrl}
          onChange={(value) => {
            setIssuerUrl(value);
          }}
          type="text"
        />
      </FormGroup>
    </div>
  );
  const mfaModal = (
    <Modal
      isOpen={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      title={"MFA Settings"}
    >
      <Modal.Body>
        <RadioGroup
          value={options.find((o) => o.generalSettingType == selectedMfaValue)}
          options={options}
          getOptionKey={(option) => option.value}
          getOptionLabel={(option) => option.label}
          getOptionDescription={(option) => option.description}
          isOptionDisabled={(option) => option.disabled}
          onSelectValue={(mfaVal) => {
            setSelectedMfaValue(mfaVal.generalSettingType);
          }}
        />
        {selectedMfaValue ===
          GeneralSettingType.UseOidcMfaForGatingOpalActions && oidcInput}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        onPrimaryButtonClick={() => {
          onSubmit();
        }}
        primaryButtonDisabled={
          selectedMfaValue ===
            GeneralSettingType.UseOidcMfaForGatingOpalActions &&
          invalidOidcFields
        }
        primaryButtonLoading={updateOrgLoading}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={() => {
          setShowModal(false);
        }}
      />
    </Modal>
  );

  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const settingsPanel = hasV3 ? (
    <div className={styles.switchesHeader}>
      <div className={sprinkles({ display: "flex" })}>
        <div className={styles.label}>MFA settings for gated Opal Actions</div>
        <MoreInfo
          tooltipText={
            "Configure your MFA provider for actions configured to require MFA (e.g. connecting to a resource)."
          }
        />
      </div>
      <div className={styles.switches}>
        <ButtonV3
          disabled={!authState.user?.isAdmin}
          onClick={() => {
            setShowModal(true);
          }}
          label={"Configure"}
          outline={true}
        />
      </div>
    </div>
  ) : (
    <div className={orgSettingsStyles.switchesHeader}>
      <div className={orgSettingsStyles.switches}>
        <button
          disabled={!authState.user?.isAdmin}
          onClick={() => {
            setShowModal(true);
          }}
          className={orgSettingsStyles.orgSettingOpenModalButton}
        >
          {"Configure"}
        </button>
      </div>
      <div className={orgSettingsStyles.label}>
        MFA settings for gated Opal Actions
      </div>
      <MoreInfo
        tooltipText={
          "Configure your MFA provider for actions configured to require MFA (e.g. connecting to a resource)."
        }
      />
    </div>
  );
  return (
    <>
      {settingsPanel}
      {showModal && mfaModal}
    </>
  );
};
