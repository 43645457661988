import {
  useLeftSidebarRoutesQuery,
  useNumRequestsToReviewQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Icon } from "components/ui";
import { useContext } from "react";
import { hasBasicPermissions } from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import AccessReviews from "views/access_reviews/AccessReviews";
import Items from "views/apps/ItemsPage";
import Events from "views/events/Events";
import GroupBindings from "views/group_bindings/GroupBindings";
import Inventory from "views/inventory/Inventory";
import MetricsDashboardView from "views/metrics_dashboard/MetricsDashboardView";
import NonHumanIdentities from "views/non-human-identities/NonHumanIdentities";
import Owners from "views/owners/Owners";
import RecommendationsView from "views/recommendations/RecommendationsView";
import Requests from "views/requests/Requests";
import SearchView from "views/search/SearchV3";
import Settings from "views/settings/Settings";
import Tags from "views/tags/Tags";
import Templates from "views/templates/Templates";
import Users from "views/users/Users";
import InsightsRouteWrapper from "views/viz/Insights";

export type LeftSidebarSectionInfo = {
  title: string;
  options: LeftSidebarOptionInfo[];
  iconName: PropsFor<typeof Icon>["name"];
  canAccess: boolean;
};

export type LeftSidebarOptionInfo = {
  iconName?: PropsFor<typeof Icon>["name"];
  route: string;
  extraRoutes?: string[];
  title: string;
  // Component to redirect the main page to when this sidebar element is clicked
  component: React.ComponentType;
  // This allows the route to exist without it needing to appear in the sidebar
  hidden?: boolean;
  canAccess: boolean;
  badgeCount?: number;
};

// Each nav sidebar item is either an exandable section or a link to a page
export type LeftSidebarItemInfo =
  | LeftSidebarOptionInfo
  | LeftSidebarSectionInfo;

const useLeftSidebarSectionInfos = (): LeftSidebarItemInfo[] => {
  const hasGroupBindings = useFeatureFlag(FeatureFlag.GroupBindings);
  const hasHomepage = useFeatureFlag(FeatureFlag.LPPMShowHomepage);
  const hasNonHumanIdentities = useFeatureFlag(FeatureFlag.NonHumanIdentities);
  const hasV3Search = useFeatureFlag(FeatureFlag.V3Search);
  const hasInventory = useFeatureFlag(FeatureFlag.AdminCatalog);
  const { authState } = useContext(AuthContext);

  const isAdmin = !!authState.user?.isAdmin;
  const isReadOnlyAdmin = !!authState.user?.isReadOnlyAdmin;
  const isAuditor = !!authState.user?.isAuditor;
  const isMember = hasBasicPermissions(authState.user);

  const { data } = useLeftSidebarRoutesQuery();
  const { data: numRequestsToReviewData } = useNumRequestsToReviewQuery();
  return [
    {
      iconName: "search",
      route: "/search",
      title: "Search",
      component: SearchView,
      canAccess: hasV3Search,
    },
    {
      iconName: "home-2",
      route: "/home",
      title: "Home",
      component: RecommendationsView,
      canAccess: (isAdmin || isReadOnlyAdmin) && hasHomepage,
    },
    {
      iconName: "apps",
      route: "/apps",
      extraRoutes: ["/resources", "/groups", "/bundles"],
      title: "Catalog",
      component: Items,
      canAccess: true,
    },
    {
      iconName: "inventory",
      route: "/inventory",
      title: "Inventory",
      component: Inventory,
      canAccess: hasInventory,
    },
    {
      iconName: "check-circle",
      route: "/requests",
      title: "Requests",
      component: Requests,
      badgeCount: numRequestsToReviewData?.numRequestsToReview,
      canAccess: true,
    },
    {
      iconName: "edit",
      route: "/access-reviews",
      title: "Access Reviews",
      component: AccessReviews,
      canAccess: true,
    },
    {
      iconName: "events",
      route: "/events",
      title: "Events",
      component: Events,
      hidden: isMember,
      canAccess: !isMember,
    },
    {
      iconName: "line-chart-up",
      route: "/dashboard",
      title: "Dashboard",
      component: MetricsDashboardView,
      canAccess: isAdmin || isReadOnlyAdmin,
    },
    {
      iconName: "department",
      route: "/insights",
      title: "Explore (beta)",
      component: InsightsRouteWrapper,
      canAccess: !isMember,
    },
    {
      iconName: "settings",
      title: "Configuration",
      canAccess: true,
      options: [
        {
          iconName: "user",
          route: "/users",
          title: "Users",
          component: Users,
          canAccess:
            isAdmin ||
            isReadOnlyAdmin ||
            isAuditor ||
            !!data?.leftSidebarRoutes.hasOwners,
        },
        {
          iconName: "key",
          route: "/non-human-identities",
          title: "Non-human Identities",
          component: NonHumanIdentities,
          canAccess:
            (isAdmin || isReadOnlyAdmin || isAuditor) && hasNonHumanIdentities,
        },
        {
          iconName: "user-square",
          route: "/owners",
          title: "Owners",
          component: Owners,
          canAccess: !!data?.leftSidebarRoutes.hasOwners,
        },
        {
          iconName: "tag",
          route: "/tags",
          title: "Tags",
          component: Tags,
          canAccess: isAdmin || isReadOnlyAdmin || isAuditor,
        },
        {
          iconName: "template",
          route: "/templates",
          title: "Templates",
          component: Templates,
          canAccess: isAdmin || isReadOnlyAdmin,
        },
        {
          iconName: "link",
          route: "/linked-groups",
          title: "Linked Groups",
          component: GroupBindings,
          canAccess: (isAdmin || isReadOnlyAdmin) && hasGroupBindings,
        },
        {
          iconName: "settings",
          route: "/settings",
          title: "Settings",
          component: Settings,
          canAccess: isAdmin || isReadOnlyAdmin,
        },
      ],
    },
  ];
};

export default useLeftSidebarSectionInfos;
