import { IconName } from "components/ui/icon/Icon";
import TabsV3, { TabInfo } from "components/ui/tabs/TabsV3";
import React from "react";
import { usePageTitle } from "utils/hooks";

import OpalHeader from "./OpalHeader";
import * as styles from "./OpalPage.css";

type Props = {
  icon: IconName;
  title: string;
  children: React.ReactNode;
  tabs?: TabInfo[];
  extraActions?: React.ReactNode; // kind of an anti-pattern so avoid if possible
  leftActions?: React.ReactNode; // kind of an anti-pattern so avoid if possible
  customBody?: boolean; // for pages that want to do their own thing (e.g. insights)
  pageTitleOverride?: string; // defaults to the same as title
};

export default ({
  icon,
  title,
  children,
  tabs,
  extraActions,
  customBody = false,
  pageTitleOverride,
  leftActions,
}: Props) => {
  usePageTitle(pageTitleOverride ?? title);
  return (
    <section className={styles.container}>
      <OpalHeader
        icon={icon}
        title={title}
        extraActions={extraActions}
        leftActions={leftActions}
      />
      <div
        className={styles.body({
          hasTabs: tabs != null,
          customBody: customBody,
        })}
      >
        {tabs != null && <TabsV3 tabInfos={tabs} />}
        {children}
      </div>
    </section>
  );
};
