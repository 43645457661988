import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AddGroupGroupInput,
  ConnectionType,
  GroupAccessLevel,
  GroupDropdownPreviewFragment,
  GroupPreviewWithGroupsFragment,
  GroupType,
  useAddGroupGroupsMutation,
  useGroupAddGroupsQuery,
  useGroupQuery,
  usePaginatedGroupDropdownLazyQuery,
  useSearchGroupsQuery,
} from "api/generated/graphql";
import FullscreenViewTitle from "components/fullscreen_modals/FullscreenViewTitle";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import {
  Banner,
  Divider,
  EntityIcon,
  Icon,
  Input,
  Label,
  Loader,
  Select,
} from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";

import { useToast } from "../../components/toast/Toast";
import { groupTypeHasRoles } from "../../utils/directory/groups";
import * as styles from "./GroupAddMemberGroups.css";

const PAGE_SIZE = 100;

interface GroupGroupRow {
  id: string;
  icon?: IconData;
  name: string;
  connectionType?: ConnectionType;
  connectionId?: string;
  groupType?: GroupType;
  isEmpty?: boolean;
}

const GroupAddMemberGroupsView = () => {
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { groupId } = useParams<{ groupId: string }>();
  const startPushTaskPoll = usePushTaskLoader();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [groupById, setGroupById] = useState<{
    [groupId: string]: GroupDropdownPreviewFragment;
  }>({});
  const [groupsByConnectionId, setGroupsByConnectionId] = useState<{
    [connectionId: string]: GroupDropdownPreviewFragment[];
  }>({});
  const [roleByGroupIdToAdd, setRoleByGroupIdToAdd] = useState<
    Record<string, GroupAccessLevel[]>
  >({});
  const [
    accessDurationByGroupIdToAdd,
    setAccessDurationByGroupIdToAdd,
  ] = useState<Record<string, ExpirationValue>>({});
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [addError, setAddError] = useState("");
  const rowsById: Record<string, GroupGroupRow> = {};
  const [itemsLoadingSubRows, setItemsLoadingSubRows] = useState<string[]>([]);

  const { displaySuccessToast } = useToast();

  const [getGroups] = usePaginatedGroupDropdownLazyQuery();
  const { data, loading, error } = useGroupAddGroupsQuery({
    variables: {
      groupId,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: searchGroupsData,
    loading: searchGroupsLoading,
    error: searchGroupsError,
  } = useSearchGroupsQuery({
    variables: {
      query: debouncedSearchQuery,
      maxNumEntries: PAGE_SIZE,
    },

    skip: debouncedSearchQuery === "",
  });

  useEffect(() => {
    setGroupById((groupById) => {
      return {
        ...groupById,
        ...searchGroupsData?.groups.groups.reduce((acc, group) => {
          acc[group.id] = group;
          return acc;
        }, {} as typeof groupById),
      };
    });
  }, [searchGroupsData]);

  const [addGroupGroups, { loading: addLoading }] = useAddGroupGroupsMutation();
  const { refetch: refetchGroup } = useGroupQuery({ skip: true });

  let group: GroupPreviewWithGroupsFragment | undefined;
  if (data?.group.__typename === "GroupResult") {
    group = data.group.group;
  }
  const allConnections = data?.connections.connections ?? [];
  const connections = allConnections.filter((connection) =>
    Boolean(connection.numGroups)
  );
  const allRoles = group?.accessLevels ?? [];

  if (loading) {
    return <FullscreenSkeleton />;
  }
  if (!group?.authorizedActions?.includes(AuthorizedActionManage)) {
    return <ForbiddenPage />;
  }
  if (!group || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const groupHasRoles = groupTypeHasRoles(group.groupType);
  const rolesByGroupId: Record<string, GroupAccessLevel[]> = {};

  group.memberGroups.forEach((groupGroup) => {
    if (!rolesByGroupId[groupGroup.memberGroupId]) {
      rolesByGroupId[groupGroup.memberGroupId] = [];
    }
    rolesByGroupId[groupGroup.memberGroupId] = _.uniqBy(
      [...rolesByGroupId[groupGroup.memberGroupId], groupGroup.accessLevel],
      "accessLevelRemoteId"
    );
  });

  const handleClose = () => {
    transitionBack(`/groups/${groupId}#groups`);
  };

  const handleFetchGroups = async (connectionId: string) => {
    try {
      setItemsLoadingSubRows((prev) => [...prev, connectionId]);
      const { data } = await getGroups({
        variables: {
          input: {
            connectionIds: [connectionId],
            maxNumEntries: PAGE_SIZE,
          },
        },
      });

      setGroupsByConnectionId((resourcesByConnectionId) => {
        return {
          ...resourcesByConnectionId,
          [connectionId]:
            data?.groups.groups.filter((g) => g.id !== group?.id) ?? [],
        };
      });

      setGroupById((groupById) => {
        return {
          ...groupById,
          ...data?.groups.groups.reduce((acc, group) => {
            acc[group.id] = group;
            return acc;
          }, {} as typeof groupById),
        };
      });
      setItemsLoadingSubRows((prev) =>
        prev.filter((id) => id !== connectionId)
      );
      return data?.groups.groups ?? [];
    } catch (err) {
      logError(err, "Failed to fetch groups for connection " + connectionId);
    }
  };

  const numGroupsToAdd = Object.keys(roleByGroupIdToAdd).length;

  const disabledGroupIds = new Set();
  group.memberGroups.forEach((groupGroup) => {
    if (groupGroup.depth == 0) {
      disabledGroupIds.add(groupGroup.memberGroupId);
    }
  });

  const hasNestedRows = (row: GroupGroupRow) => {
    return Boolean(row.connectionType);
  };

  const getNestedRows = (row: GroupGroupRow) => {
    const groups = groupsByConnectionId[row.id]?.filter(
      (group) => !disabledGroupIds.has(group.id)
    );
    if (groups && groups.length === 0) {
      return [
        {
          id: `${row.id}-empty`,
          name: "No groups",
          isEmpty: true,
        },
      ];
    }
    return groups?.map((group) => {
      const iconData: IconData = {
        type: "entity",
        entityType: group.groupType,
      };
      const row: GroupGroupRow = {
        id: group.id,
        icon: iconData,
        name: group.name,
        groupType: group.groupType,
        connectionId: group?.connection?.id,
      };
      rowsById[row.id] = row;
      return row;
    });
  };

  const handleSubmit = async () => {
    logEvent({
      name: "apps_add_resource_to_groups",
      properties: {
        numGroupsAddedTo: Object.entries(roleByGroupIdToAdd).length,
      },
    });
    try {
      const groupGroupsToAdd: AddGroupGroupInput[] = [];
      for (const [groupId, roles] of Object.entries(roleByGroupIdToAdd)) {
        // Convert expiration value to duration in minutes, default to undefined.
        const expirationVal =
          accessDurationByGroupIdToAdd[groupId] || ExpirationValue.Indefinite;
        const accessDurationInMinutes = expirationValueToDurationInMinutes(
          expirationVal
        )?.asMinutes();
        if (roles.length === 0) {
          // If role requires a role, but none are selected,
          // show an error.
          if (groupHasRoles) {
            setAddError("Please select at least one role for each group.");
            return;
          } else {
            // If group does not require roles,
            // add an empty role to add the group directly.
            groupGroupsToAdd.push({
              memberGroupId: groupId,
              containingGroupId: group?.id!,
              accessLevel: {
                accessLevelName: "",
                accessLevelRemoteId: "",
              },
              durationInMinutes: accessDurationInMinutes,
            });
          }
        }

        roles.forEach((role) => {
          groupGroupsToAdd.push({
            memberGroupId: groupId,
            containingGroupId: group?.id!,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
            durationInMinutes: accessDurationInMinutes,
          });
        });
      }

      const { data } = await addGroupGroups({
        variables: {
          input: {
            groupGroups: groupGroupsToAdd,
          },
        },
        // Note that this might not work because there's a slight delay due to async tasks
        refetchQueries: ["Group", "AppsListColumn"],
      });
      switch (data?.addGroupGroups.__typename) {
        case "AddGroupGroupsResult":
          if (data.addGroupGroups.taskId) {
            startPushTaskPoll(data.addGroupGroups.taskId, {
              refetchOnComplete: { groupId: groupId },
              onComplete() {
                // Refetch group to update group user propagations, this is
                // sort of a magic number (2s), we should keep track of all the
                // generated pushTaskIDs from the subtasks that are spawned
                // when propagating nested group users
                setTimeout(() => {
                  refetchGroup({
                    input: { id: groupId },
                  });
                }, 2000);
              },
            });
          } else {
            displaySuccessToast(
              `Successfully added ${pluralize(
                "group",
                groupGroupsToAdd.length,
                true
              )}`
            );
          }
          handleClose();
          break;
        case "GroupNotFoundError":
        case "GroupGroupAlreadyExists":
          setAddError(data.addGroupGroups.message);
          break;
        case "GroupNestingNotAllowedError": {
          const fromGroupName =
            data.addGroupGroups.fromGroup?.name ?? "Unknown group";
          const toGroupName =
            data.addGroupGroups.toGroup?.name ?? "Unknown group";
          setAddError(
            `Error: Group nesting not allowed. Cannot add ${fromGroupName} group to ${toGroupName} group,` +
              ` as it would create a circular dependency. Contact your admin for assistance.`
          );
          break;
        }
        default:
          logError(new Error(`failed to add groups to group`));
          setAddError("Error: failed to add groups to group");
      }
    } catch (error) {
      logError(error, "failed to add groups to group");
      setAddError(
        getModifiedErrorMessage("Error: failed to add groups to group", error)
      );
    }
  };

  const COLUMNS: Header<GroupGroupRow>[] = [
    {
      id: "name",
      label: "Name",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              gap: "md",
            })}
          >
            <Label label={row.name} icon={row.icon} />
            {itemsLoadingSubRows.includes(row.id) && <Loader size="xs" />}
          </div>
        );
      },
      width: 500,
    },
  ];

  const getCheckboxDisabledReason = (row: GroupGroupRow) => {
    if (row?.isEmpty) {
      return "No groups";
    }
    if (disabledGroupIds.has(row.id)) {
      return "Already in group";
    }
  };

  const onCheckedRowsChange = async (
    checkedRowIds: string[],
    checked: boolean
  ) => {
    if (checked) {
      setSelectedRowIds((prev) => [...prev, ...checkedRowIds]);
    } else {
      setSelectedRowIds((prev) =>
        prev.filter((id) => !checkedRowIds.includes(id))
      );
    }
    checkedRowIds.forEach((id) => {
      const row = rowsById[id];
      if (hasNestedRows(row)) {
        onCheckConnection(row, checked);
      } else {
        onCheckGroup(row);
      }
    });
  };

  const onCheckGroup = async (row: GroupGroupRow) => {
    if (row.id in roleByGroupIdToAdd) {
      setSelectedRowIds((prev) =>
        prev.filter((id) => id !== row.id && id !== row.connectionId)
      );
      setRoleByGroupIdToAdd((prev) => {
        const newRoles = { ...prev };
        delete newRoles[row.id];
        return newRoles;
      });
      setAccessDurationByGroupIdToAdd((prev) => {
        const newDurations = { ...prev };
        delete newDurations[row.id];
        return newDurations;
      });
    } else {
      setSelectedRowIds((prev) => [...prev, row.id]);
      setRoleByGroupIdToAdd((prev) => {
        return {
          ...prev,
          [row.id]: [],
        };
      });
      setAccessDurationByGroupIdToAdd((prev) => {
        return {
          ...prev,
          [row.id]: ExpirationValue.Indefinite,
        };
      });
    }
  };

  const onCheckConnection = async (row: GroupGroupRow, checked: Boolean) => {
    if (checked) {
      if (selectedRowIds.includes(row.id)) {
        return;
      }
      try {
        setSelectedRowIds((prev) => [...prev, row.id]);
        let items: GroupDropdownPreviewFragment[] | undefined;
        if (groupsByConnectionId[row.id]) {
          items = groupsByConnectionId[row.id];
        } else {
          if (row.connectionType) {
            items = await handleFetchGroups(row.id);
          }
        }
        ReactDOM.unstable_batchedUpdates(() => {
          setRoleByGroupIdToAdd((prev) => {
            const newRoles = { ...prev };
            items
              ?.filter((g) => g.id !== group?.id)
              .forEach((item) => {
                if (!(item.id in newRoles) && !disabledGroupIds.has(item.id)) {
                  newRoles[item.id] = [];
                  setSelectedRowIds((prev) => [...prev, item.id]);
                  setAccessDurationByGroupIdToAdd((prev) => {
                    return {
                      ...prev,
                      [item.id]: ExpirationValue.Indefinite,
                    };
                  });
                }
              });
            return newRoles;
          });
        });
      } catch (err) {
        logError(err, "Failed to fetch groups for connection");
      }
    } else {
      const items = getNestedRows(row);
      setSelectedRowIds((prev) =>
        prev.filter((id) => !items.map((row) => row.id).includes(id))
      );
      setAccessDurationByGroupIdToAdd((prev) => {
        const newDurations = { ...prev };
        items.forEach((row) => {
          if (row.id in newDurations) {
            delete newDurations[row.id];
            return newDurations;
          }
        });
        return newDurations;
      });
      setRoleByGroupIdToAdd((prev) => {
        const newRoles = { ...prev };
        items.forEach((row) => {
          if (row.id in newRoles) {
            delete newRoles[row.id];
            return newRoles;
          }
        });
        return newRoles;
      });
    }
  };

  const onRowClick = async (row: GroupGroupRow) => {
    if (row.isEmpty || disabledGroupIds.has(row.id)) {
      return;
    }
    if (hasNestedRows(row)) {
      if (row.connectionType && !groupsByConnectionId[row.id]) {
        handleFetchGroups(row.id);
      }
    } else {
      onCheckGroup(row);
    }
  };

  const renderConnectionsList = () => {
    const rows: GroupGroupRow[] = connections.map((connection) => {
      const row: GroupGroupRow = {
        id: connection.id,
        icon: {
          type: "src",
          icon: getConnectionTypeInfo(connection.connectionType)?.icon,
        },
        name: connection.name,
        connectionType: connection.connectionType,
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={loading || searchGroupsLoading}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedRowIds)}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        onExpandRow={(row) => handleFetchGroups(row.id)}
        getChildRows={getNestedRows}
        expandOnChecked={true}
        expandOnRowClick={true}
      />
    );
  };

  const renderSearchList = () => {
    if (searchGroupsError) {
      return <ModalErrorMessage errorMessage={searchGroupsError.message} />;
    }

    const filteredGroups = (searchGroupsData?.groups.groups ?? []).filter(
      (group) => !disabledGroupIds.has(group.id)
    );

    const rows: GroupGroupRow[] = filteredGroups.map((group) => {
      const iconData: IconData = {
        type: "entity",
        entityType: group.groupType,
      };
      const row: GroupGroupRow = {
        id: group.id,
        icon: iconData,
        name: group.name,
        groupType: group.groupType,
        connectionId: group?.connection?.id,
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={loading || searchGroupsLoading}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedRowIds)}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        getChildRows={getNestedRows}
      />
    );
  };

  return (
    <FullscreenView
      title={
        <FullscreenViewTitle
          entityType={group.groupType}
          entityName={group.name}
          targetEntityName="groups"
          action="add"
        />
      }
      onCancel={handleClose}
      onPrimaryButtonClick={handleSubmit}
      primaryButtonDisabled={numGroupsToAdd === 0}
      primaryButtonLabel={`Add ${
        numGroupsToAdd ? numGroupsToAdd : ""
      } ${pluralize("group", numGroupsToAdd)}`}
      primaryButtonLoading={addLoading}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select member groups to add the group to:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Search by name"
              autoFocus
            />
          </div>
          <div className={sprinkles({ color: "gray600", fontSize: "textXs" })}>
            {debouncedSearchQuery === ""
              ? "Showing first 100 groups in each app. Use search to find more results."
              : "Showing first 100 search results. Refine your search to find more."}
          </div>
          <Divider />
          {debouncedSearchQuery === ""
            ? renderConnectionsList()
            : renderSearchList()}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addError && (
          <Banner message={addError} type="error" marginBottom="lg" />
        )}
        <div
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
            marginBottom: "lg",
          })}
        >
          Adding {numGroupsToAdd} {pluralize("Group", numGroupsToAdd)}
        </div>
        {Object.keys(roleByGroupIdToAdd).map((groupId) => {
          const group = groupById[groupId];
          if (!group || !group.connection) {
            return null;
          }
          const existingRoles = rolesByGroupId[groupId] || [];
          const rolesToAdd = roleByGroupIdToAdd[groupId] || [];
          const roleOptions = allRoles.filter((role) => {
            return ![...existingRoles, ...rolesToAdd].some(
              (existingRole) =>
                existingRole.accessLevelRemoteId === role.accessLevelRemoteId
            );
          });

          return (
            <div key={group.id} className={styles.resourceCard}>
              <div
                className={sprinkles({
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "sm",
                })}
              >
                <div
                  className={sprinkles({
                    flexShrink: 0,
                  })}
                >
                  <EntityIcon
                    type={group.connection.connectionType}
                    iconStyle="rounded"
                  />
                </div>
                <div className={styles.resourceInfoSection}>
                  <div className={styles.resourceCardHeader}>{group.name}</div>
                  <div className={styles.resourceCardSubtitle}>
                    {group.connection.name}
                  </div>
                  <div className={styles.resourceCardType}>
                    <EntityIcon type={group.groupType} includeBrand={false} />
                    {groupTypeInfoByType[group.groupType].name}
                  </div>
                </div>
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon
                    name="trash"
                    color="red600V3"
                    onClick={() => {
                      setSelectedRowIds((prev) =>
                        prev.filter(
                          (id) => id !== groupId && id !== group.connection?.id
                        )
                      );
                      setRoleByGroupIdToAdd((prev) => {
                        const newRoles = { ...prev };
                        delete newRoles[group.id];
                        return newRoles;
                      });
                      setAccessDurationByGroupIdToAdd((prev) => {
                        const newDurations = { ...prev };
                        delete newDurations[group.id];
                        return newDurations;
                      });
                    }}
                  />
                </div>
              </div>
              <div className={sprinkles({ marginTop: "md" })}>
                <Select
                  key={group.id}
                  options={Object.values(ExpirationValue)}
                  placeholder="Select access duration"
                  value={accessDurationByGroupIdToAdd[group.id]}
                  onChange={(val) => {
                    if (val) {
                      setAccessDurationByGroupIdToAdd((prev) => {
                        return {
                          ...prev,
                          [group.id]: val,
                        };
                      });
                    }
                  }}
                  disableBuiltInFiltering
                  getOptionLabel={(expirationVal) =>
                    expirationVal === ExpirationValue.Indefinite
                      ? "Indefinite access"
                      : `Access for ${expirationVal}`
                  }
                />
              </div>
              {groupHasRoles && (
                <div className={sprinkles({ marginTop: "md" })}>
                  <Select
                    options={roleOptions}
                    loading={loading}
                    placeholder="Select role"
                    getOptionLabel={(role) => role.accessLevelName}
                    onChange={(role) => {
                      if (role) {
                        setRoleByGroupIdToAdd((prev) => {
                          const newRoles = { ...prev };
                          newRoles[groupId] = [
                            ...(newRoles[groupId] || []),
                            role,
                          ];
                          return newRoles;
                        });
                      }
                    }}
                    selectOnly
                  />
                  {rolesToAdd.map((role) => {
                    return (
                      <div
                        key={role.accessLevelRemoteId}
                        className={sprinkles({
                          paddingX: "sm",
                          marginTop: "sm",
                          fontSize: "textSm",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        })}
                      >
                        {role.accessLevelName}
                        <Icon
                          name="x"
                          size="xs"
                          onClick={() => {
                            setRoleByGroupIdToAdd((prev) => {
                              const newRoles = { ...prev };
                              newRoles[groupId] = newRoles[groupId].filter(
                                (existingRole) =>
                                  existingRole.accessLevelRemoteId !==
                                  role.accessLevelRemoteId
                              );
                              return newRoles;
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default GroupAddMemberGroupsView;
