import {
  AccessOption,
  AppEndUserGroupRowFragment,
  AppEndUserResourceRowFragment,
  AppItemsSortByField,
  ConnectionType,
  EndUserConnectionAppFragment,
  EndUserOktaResourceAppFragment,
  EntityType,
  GroupType,
  ResourceType,
  SortDirection,
  useAppDetailsQuery,
  useAppEndUserResourcesQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeaderV3";
import AccessLabel from "components/enduser_exp/AccessLabel";
import TableControls from "components/enduser_exp/TableControls";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { GroupDetailsModal } from "components/modals/enduser_exp/GroupDetailsModal";
import { ResourceDetailsModal } from "components/modals/enduser_exp/ResourceDetailsModal";
import {
  ButtonV3,
  InteractiveCard,
  Label,
  Loader,
  Masonry,
  Skeleton,
  TabsV3,
} from "components/ui";
import * as interactiveCardStyles from "components/ui/card/InteractiveCard.css";
import Table, { Header } from "components/ui/table/Table";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { groupHasForfeitableRole } from "utils/groups";
import { useDebouncedValue, usePageTitle } from "utils/hooks";
import {
  connectionTypeCanHaveBreadcrumb,
  formatResourceBreadcrumb,
  resourceHasForfeitableRole,
} from "utils/resources";
import { useURLSearchParam } from "utils/router/hooks";
import { useAccessRequestTransition } from "views/access_request/AccessRequestContext";
import { useAccessOptionKey } from "views/apps/utils";
import { EndUserItemDetailsCard } from "views/common/EndUserItemDetailsCard";
import { ItemUsageDetails } from "views/common/ItemUsageDetails";
import {
  CurrentUserResourceAccessStatus,
  currentUserResourceAccessStatusFromResource,
  useConnectTransition,
} from "views/connect_sessions/utils";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { isGroupBindingRedirectRequired } from "views/group_bindings/common";
import { useGroupBindingRequestRedirectModal } from "views/group_bindings/modals/GroupBindingRequestRedirectModal";
import OrgContext from "views/settings/OrgContext";

import { AppsContext, ITEM_TYPE_URL_KEY } from "../AppsContext";
import { BREAKPOINT_COLUMNS, NO_PERMISSION_TO_REQUEST } from "./constants";
import * as tableStyles from "./Table.css";
import { AppItemRow } from "./types";
import {
  formatRequestDataForItems,
  getGroupUserAccessInfo,
  getOwnerUserIconList,
  getResourceUserAccessInfo,
  useHandleRedirectFromAdminCatalog,
} from "./utils";

const ALL_VIEW_KEY = "all";
function isSortableField(str: string): str is AppItemsSortByField {
  return Object.values<string>(AppItemsSortByField).includes(str);
}

export interface AppEndUserView {
  key: string;
  title: string;
  icon: IconData;
  count?: number;
}

type AppSortValue = {
  field: AppItemsSortByField;
  direction: SortDirection;
};

const APP_SORT_OPTIONS: { label: string; value: AppSortValue }[] = [
  {
    label: "Name (A-Z)",
    value: {
      field: AppItemsSortByField.Name,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Name (Z-A)",
    value: {
      field: AppItemsSortByField.Name,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Newest first",
    value: {
      field: AppItemsSortByField.CreatedAt,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Oldest first",
    value: {
      field: AppItemsSortByField.CreatedAt,
      direction: SortDirection.Asc,
    },
  },
];

const AppDetailView = () => {
  const { appId } = useParams<Record<string, string>>();
  const { layoutOption } = useContext(AppsContext);
  const [accessOptionKey] = useAccessOptionKey();

  const [searchQuery, setSearchQuery] = useURLSearchParam("search", "");
  const [selectedItemType, setSelectedItemType] = useURLSearchParam(
    ITEM_TYPE_URL_KEY,
    ALL_VIEW_KEY
  );
  const debouncedSearchQuery = useDebouncedValue(searchQuery ?? "", 500);
  const [sortBy, setSortBy] = useState<AppSortValue | undefined>(
    APP_SORT_OPTIONS[0].value
  );
  const transitionToAccessRequest = useAccessRequestTransition();
  const transitionToConnect = useConnectTransition();
  const handleRedirectFromAdminCatalog = useHandleRedirectFromAdminCatalog();
  const {
    open: openGroupBindingRedirectModal,
    maybeRenderModal: maybeRenderGroupBindingRedirectModal,
  } = useGroupBindingRequestRedirectModal();
  const history = useHistory();
  const { orgState } = useContext(OrgContext);

  const [
    showResourceDetailsModal,
    setShowResourceDetailsModal,
  ] = useURLSearchParam("resourceId");
  const [showGroupDetailsModal, setShowGroupDetailsModal] = useURLSearchParam(
    "groupId"
  );
  const [checkedItems, setCheckedItems] = useState<AppItemRow[]>([]);
  const [itemsLoadingSubRows, setItemsLoadingSubRows] = useState<string[]>([]);

  const handleShowDetailsModal = useCallback(
    (row: AppItemRow) => {
      if (row.entityType === EntityType.Group) {
        setShowGroupDetailsModal(row.id);
      } else if (row.entityType === EntityType.Resource) {
        setShowResourceDetailsModal(row.id);
      }
    },
    [setShowGroupDetailsModal, setShowResourceDetailsModal]
  );

  const columns: Header<AppItemRow>[] = [
    {
      id: AppItemsSortByField.Name,
      label: "Name",
      sortable: true,
      width: 200,
      customCellRenderer: (row) => {
        return (
          <div className={tableStyles.name}>
            <div
              className={sprinkles({
                display: "flex",
                flexDirection: "column",
              })}
            >
              <div className={tableStyles.text({ bold: true })}>
                <Label
                  label={row[AppItemsSortByField.Name]}
                  truncateLength={50}
                  oneLine
                />
              </div>
              {row.shortenedBreadcrumb && row.breadcrumb && (
                <Label
                  label={row.shortenedBreadcrumb}
                  color="gray600"
                  oneLine
                  truncateLength={60}
                  tooltipText={
                    row.breadcrumb !== row.shortenedBreadcrumb
                      ? row.breadcrumb
                      : undefined
                  }
                />
              )}
            </div>
            {itemsLoadingSubRows.includes(row.id) && <Loader size="xs" />}
          </div>
        );
      },
    },
    {
      id: "description",
      label: "Description",
      sortable: false,
      width: 180,
      customCellRenderer: (row) => {
        const description = row.description.length > 0 ? row.description : "—";
        return (
          <div className={tableStyles.text()}>
            <Label label={description} truncateLength={null} oneLine />
          </div>
        );
      },
    },
    {
      id: "type",
      label: "Type",
      sortable: false,
      width: 80,
      customCellRenderer: (row) => {
        return (
          <div className={tableStyles.text()}>
            <Label label={row.typeFriendlyName} truncateLength={null} oneLine />
          </div>
        );
      },
    },
    {
      id: "accessInfo",
      label: "Access",
      sortable: false,
      width: 100,
      customCellRenderer: (row) => {
        return <AccessLabel {...row.accessInfo} />;
      },
    },
    {
      id: "cta",
      label: "Actions",
      sortable: false,
      width: 100,
      minWidth: 100,
      customCellRenderer: (row) => {
        return (
          <div>
            {row.connectable ? (
              <ButtonV3
                label="Connect"
                type="success"
                size="xs"
                disabled={!row.connectable}
                onClick={(event) => {
                  event.stopPropagation();
                  handleConnect(row, event);
                }}
              />
            ) : (
              <ButtonV3
                label="Request"
                type="main"
                size="xs"
                disabled={!row.requestable}
                disabledTooltip={NO_PERMISSION_TO_REQUEST}
                onClick={(event) => {
                  event.stopPropagation();
                  handleOpenRequest(row, event);
                }}
              />
            )}
          </div>
        );
      },
    },
  ];

  const { data, error, loading } = useAppDetailsQuery({
    variables: {
      appId,
    },
  });

  let endUserApp:
    | EndUserConnectionAppFragment
    | EndUserOktaResourceAppFragment
    | undefined
    | null;
  let isOktaApp = false;
  switch (data?.app.__typename) {
    case "App":
      switch (data?.app.app.__typename) {
        case "ConnectionApp":
          endUserApp = data.app.app.connection;
          break;
        case "OktaResourceApp":
          endUserApp = data.app.app.resource;
          isOktaApp = true;
          break;
      }
      break;
  }

  // Only nest items if we are showing a list of all items
  const showListResults =
    layoutOption === "grid" ||
    accessOptionKey === AccessOption.Mine ||
    debouncedSearchQuery.length > 0 ||
    selectedItemType !== ALL_VIEW_KEY;

  const {
    data: resourceData,
    loading: resourceLoading,
    error: resourceError,
    previousData: previousResourceData,
    fetchMore,
  } = useAppEndUserResourcesQuery({
    variables: {
      id: appId,
      includeGroups: true,
      access: accessOptionKey,
      searchQuery: debouncedSearchQuery,
      sortBy: sortBy,
      itemType: selectedItemType === ALL_VIEW_KEY ? null : selectedItemType,
      hasV3: true,
      parentResourceId:
        selectedItemType === ALL_VIEW_KEY &&
        !debouncedSearchQuery &&
        accessOptionKey === AccessOption.All
          ? {
              resourceId: null,
            }
          : null,
    },
    fetchPolicy: "cache-and-network",
  });

  const app = resourceData?.app.__typename === "App" ? resourceData.app : null;
  const previousApp =
    previousResourceData?.app.__typename === "App"
      ? previousResourceData.app
      : null;
  const items = app?.items?.items ?? [];
  const totalItemsCount = app?.items?.totalNumItems ?? 0;
  const cursor = app?.items?.cursor;
  const itemTypes = useMemo(
    () => app?.itemTypes ?? previousApp?.itemTypes ?? [],
    [app?.itemTypes, previousApp?.itemTypes]
  );

  const itemsByParentId: Record<
    string,
    (AppEndUserResourceRowFragment | AppEndUserGroupRowFragment)[]
  > = {};
  const itemsWithChildren: Set<String> = new Set();
  const requestableItemIds: string[] = [];
  const itemsById: Record<
    string,
    AppEndUserResourceRowFragment | AppEndUserGroupRowFragment
  > = {};
  items?.forEach((item) => {
    const parentResourceId = item.resource?.parentResourceId;
    const resource = item.resource ?? item.group;
    if (resource) {
      if (resource.isRequestable) {
        requestableItemIds.push(resource.id);
      }
      itemsById[resource.id] = resource;
    }
    if (showListResults) {
      // If not showing a resource hierarchy then
      // skip updating itemsByParentId & itemsWithChildren
      return;
    }
    if (parentResourceId && resource) {
      itemsByParentId[parentResourceId] = [
        ...(itemsByParentId[parentResourceId] ?? []),
        resource,
      ];
    }
    if (item.resource && item.resource.hasVisibleChildren) {
      itemsWithChildren.add(item.resource.id);
    }
  });

  const handleOpenRequest = (
    row: AppItemRow,
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (isGroupBindingRedirectRequired(row)) {
      event.stopPropagation();
      openGroupBindingRedirectModal(row.id, row.groupBinding!.id);
      return;
    }

    const selectedItems = checkedItems;
    checkedItems.push(row);

    transitionToAccessRequest(
      {
        ...formatRequestDataForItems(
          selectedItems,
          isOktaApp ? appId : undefined
        ),
        appId: appId,
      },
      event
    );
  };

  const handleConnect = (
    row: AppItemRow,
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    transitionToConnect({
      connectionId: row.connectionId,
      resourceId: row.entityId,
      event,
    });
  };

  // This useEffect will handle any redirect to the request modal
  useEffect(() => {
    handleRedirectFromAdminCatalog(
      showResourceDetailsModal,
      showGroupDetailsModal,
      endUserApp?.id,
      isOktaApp
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showResourceDetailsModal, showGroupDetailsModal, endUserApp, isOktaApp]);
  usePageTitle(endUserApp?.name ?? "App");

  const buildRow = (
    item: AppEndUserResourceRowFragment | AppEndUserGroupRowFragment
  ): AppItemRow => {
    // Check if need to force breadcrumbs on certain connections
    const itemCanHaveBreadcrumb = connectionTypeCanHaveBreadcrumb(
      item.connection?.connectionType
    );
    const common = {
      id: item.id,
      entityId: item.id,
      [AppItemsSortByField.Name]: item.name,
      description: item.description || "",
      connectionId: item.connectionId,
      // In some cases we need to show an empty breadcrumb in the cards
      // otherwise alignment will be off, this will only be relevant in GCP,
      // AWS connections where parent resources may not be visible to the user
      // (due to some other issue) but we still want things to look good.
      breadcrumb: itemCanHaveBreadcrumb ? "" : undefined,
      shortenedBreadcrumb: itemCanHaveBreadcrumb ? "" : undefined,
    };
    switch (item.__typename) {
      case "Resource": {
        const mostRecentUserAccessStatus = currentUserResourceAccessStatusFromResource(
          item
        );
        return {
          ...common,
          type: isOktaApp ? ResourceType.OktaRole : item.resourceType,
          typeFriendlyName: isOktaApp
            ? "Role"
            : getResourceTypeInfo(item.resourceType)?.fullName ?? "",
          entityType: EntityType.Resource,
          hasVisibleChildren: item.hasVisibleChildren,
          accessInfo: getResourceUserAccessInfo(item.currentUserAccess),
          requestable: item.isRequestable,
          forfeitable: resourceHasForfeitableRole(item.currentUserAccess),
          shortenedBreadcrumb:
            item.ancestorPathToResource !== item.name
              ? formatResourceBreadcrumb(item.ancestorPathToResource, 50)
              : common.shortenedBreadcrumb,
          breadcrumb:
            item.ancestorPathToResource !== item.name
              ? formatResourceBreadcrumb(item.ancestorPathToResource)
              : common.breadcrumb,
          connectable:
            mostRecentUserAccessStatus ===
              CurrentUserResourceAccessStatus.AuthorizedSessionStarted ||
            mostRecentUserAccessStatus ===
              CurrentUserResourceAccessStatus.AuthorizedSessionNotStarted,
        };
      }
      case "Group": {
        return {
          ...common,
          type: isOktaApp ? ResourceType.OktaRole : item.groupType,
          typeFriendlyName: isOktaApp
            ? "Role"
            : getGroupTypeInfo(item.groupType)?.name ?? "",
          entityType: EntityType.Group,
          hasVisibleChildren: false,
          accessInfo: getGroupUserAccessInfo(item.currentUserAccess),
          requestable: item.isRequestable,
          forfeitable: groupHasForfeitableRole(item.currentUserAccess),
          connectable: false,
          groupBinding: item.groupBinding?.id
            ? {
                id: item.groupBinding?.id,
                sourceGroupId: item.groupBinding?.sourceGroupId,
              }
            : undefined,
        };
      }
    }
  };

  const rows: AppItemRow[] = [];
  items?.forEach((item) => {
    if (item.group) {
      rows.push(buildRow(item.group));
      return;
    }
    const resource = item.resource;
    if (!resource) {
      return;
    }
    if (showListResults) {
      rows.push(buildRow(resource));
      return;
    }
    // Only include top-level resources in top rows.
    // A top level resource is one that has no parent or whose parent is the current resource we're viewing.
    const parentResourceId =
      "parentResourceId" in resource && resource.parentResourceId;
    if (!parentResourceId) {
      rows.push(buildRow(resource));
    }
  });

  // Views we can have for apps
  // Sort the entity types and iterate over them to create the tabs
  const appViews = useMemo(() => {
    const appViews: AppEndUserView[] = [];
    var totalItemTypesCount = 0;
    Array.from(itemTypes).forEach((itemType) => {
      if (itemType) {
        if (
          Object.values(ResourceType).includes(
            itemType.itemType as ResourceType
          )
        ) {
          const resourceTypeInfo = getResourceTypeInfo(
            itemType.itemType as ResourceType
          );
          if (resourceTypeInfo) {
            appViews.push({
              key: itemType.itemType,
              title: resourceTypeInfo.name,
              icon: {
                type: "name",
                icon: resourceTypeInfo.iconName,
              },
              count: itemType.count,
            });
            totalItemTypesCount += itemType.count;
          }
        } else if (
          Object.values(GroupType).includes(itemType.itemType as GroupType)
        ) {
          const groupTypeInfo = getGroupTypeInfo(
            itemType.itemType as GroupType
          );
          if (groupTypeInfo) {
            appViews.push({
              key: itemType.itemType,
              title: groupTypeInfo.name,
              icon: {
                type: "name",
                icon: groupTypeInfo.iconName,
              },
              count: itemType.count,
            });
            totalItemTypesCount += itemType.count;
          }
        }
      }
    });

    appViews.sort((a, b) => a.title.localeCompare(b.title));
    if (appViews.length > 1) {
      appViews.unshift({
        key: ALL_VIEW_KEY,
        title: "Browse All",
        icon: {
          type: "name",
          icon: "dots-grid",
        },
        count: totalItemTypesCount,
      });
    }

    // Add a Role view if we are viewing an Okta App
    // Okta Apps only have roles right now so the ALL_VIEW_KEY works for this case
    if (isOktaApp) {
      appViews.unshift({
        key: ALL_VIEW_KEY,
        title: "Role",
        icon: {
          type: "name",
          icon: "role",
        },
        count: totalItemsCount,
      });
    }
    return appViews;
  }, [itemTypes, isOktaApp, totalItemsCount]);

  const loadMoreRows = useCallback(
    async (id?: string) => {
      if ((!id && !cursor) || resourceLoading) return;
      if (id) setItemsLoadingSubRows([...itemsLoadingSubRows, id]);
      await fetchMore({
        variables: {
          cursor,
          parentResourceId: { resourceId: id ?? null },
        },
      });
      if (id) {
        setItemsLoadingSubRows(
          itemsLoadingSubRows.filter((itemId) => itemId !== id)
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cursor, fetchMore, resourceLoading]
  );

  let content;
  let hasErrors = false;

  if (data?.app.__typename === "AppNotFoundError") {
    content = <NotFoundPage />;
    hasErrors = true;
  }

  if (loading) {
    return (
      <Column isContent maxWidth="none">
        <ColumnHeaderSkeleton includeCard />
      </Column>
    );
  }

  if (!endUserApp || error || resourceError) {
    content = <UnexpectedErrorPage error={error ?? resourceError} />;
    hasErrors = true;
  }

  if (endUserApp && hasErrors === false) {
    const ownerIconList = getOwnerUserIconList(
      endUserApp?.adminOwner?.ownerUsers
    );

    let appData: IconData;
    if (endUserApp?.iconUrl) {
      appData = {
        type: "src",
        icon: endUserApp.iconUrl,
      };
    } else {
      let entityType: ConnectionType | ResourceType;
      switch (endUserApp.__typename) {
        case "Connection":
          entityType = endUserApp?.connectionType;
          break;
        case "Resource":
          entityType = endUserApp?.resourceType;
          break;
        default:
          entityType = ConnectionType.Custom;
      }
      appData = {
        type: "entity",
        // Todo: Verify the default value
        entityType: entityType,
      };
    }

    const hasExpandableRows = rows.some(
      (row) => itemsWithChildren.has(row.id) || Boolean(itemsByParentId[row.id])
    );

    const tabInfos: PropsFor<typeof TabsV3>["tabInfos"] = appViews.map(
      (view) => {
        // shouldHighlight allows the only tab to be highlighted in the case there is only one Item Type (and hence no Brouse All tab)
        let shouldHighlight =
          appViews.length === 1 ? true : selectedItemType === view.key;
        return {
          title: view.title,
          onClick: () => {
            setSelectedItemType(view.key);
          },
          isSelected: shouldHighlight,
          icon: view.icon,
          badgeCount: view.count,
        };
      }
    );

    const isAppRequestable = () => {
      switch (endUserApp?.__typename) {
        case "Connection":
          // TODO: implement backend check to see if connection has at least one requestable resoruce
          return true;
        case "Resource":
          return (
            endUserApp.isRequestable ||
            items.some((item) => item.group?.isRequestable) ||
            // If there are more elements beyond what's currently shown, we're
            // going to assume that there's something requestable
            cursor
          );
        default:
          return false;
      }
    };

    const menuOptions: PropsFor<
      typeof EndUserItemDetailsCard
    >["extraMenuOptions"] = [];

    const hasAwsRoleCreation = Boolean(
      orgState.orgSettings?.roleCreationReviewerOwner
    );
    if (
      appData.type === "entity" &&
      appData.entityType === ConnectionType.Aws &&
      hasAwsRoleCreation
    ) {
      menuOptions.push({
        label: "Create new IAM role",
        onClick: () =>
          history.push({
            pathname: `/apps/${appId}/create-role`,
          }),
      });
    }

    content = (
      <>
        <ColumnHeader
          breadcrumbs={[
            { name: "Catalog", to: "/apps" },
            { name: "Apps", to: "/apps" },
            {
              name: endUserApp?.name ?? "",
              to: ``,
            },
          ]}
          includeDefaultActions
        />
        <ColumnContent>
          <EndUserItemDetailsCard
            icon={appData}
            title={endUserApp?.name}
            subtitle={endUserApp?.description ?? ""}
            actions={
              <ButtonV3
                label="Request"
                type="main"
                size="lg"
                disabled={!isAppRequestable()}
                disabledTooltip={NO_PERMISSION_TO_REQUEST}
                data-test-id={"Request"}
                onClick={(event) => {
                  transitionToAccessRequest({ appId: appId }, event);
                }}
              />
            }
            rightContent={
              endUserApp.__typename === "Resource" ? (
                <ItemUsageDetails
                  teamUsageCount={endUserApp?.accessStats?.teamAccessCount || 0}
                  titleUsageCount={
                    endUserApp?.accessStats?.titleAccessCount || 0
                  }
                  entityType={EntityType.Resource}
                  resourceType={endUserApp?.resourceType}
                  ownerIcons={ownerIconList}
                />
              ) : (
                <ItemUsageDetails
                  teamUsageCount={endUserApp?.accessStats?.teamAccessCount || 0}
                  titleUsageCount={
                    endUserApp?.accessStats?.titleAccessCount || 0
                  }
                  entityType={EntityType.Connection}
                  connectionType={endUserApp?.connectionType}
                  ownerIcons={ownerIconList}
                />
              )
            }
            extraMenuOptions={menuOptions}
          />
          <TableControls
            tabInfos={tabInfos}
            selectedItems={checkedItems}
            totalItemsCount={totalItemsCount}
            searchQuery={searchQuery?.toString()}
            setSearchQuery={(value: string) => {
              setSearchQuery(value);
            }}
            sortBy={sortBy}
            setSortBy={(value) => setSortBy(value as AppSortValue | undefined)}
            showSortBy={layoutOption === "grid"}
            sortByOptions={APP_SORT_OPTIONS}
            showAccessOptionToggle
            appId={appId}
          />
          {resourceLoading && !rows ? (
            <Skeleton variant="text" width="100px" />
          ) : (
            <>
              {layoutOption === "grid" ? (
                <Masonry
                  loadingItems={resourceLoading}
                  totalNumItems={totalItemsCount}
                  items={rows}
                  getItemKey={(row) => row.id}
                  breakpointCols={BREAKPOINT_COLUMNS}
                  renderItem={(row) => (
                    <InteractiveCard
                      title={row[AppItemsSortByField.Name]}
                      description={row.description}
                      subtitle={row.shortenedBreadcrumb ?? undefined}
                      subtitleTooltip={
                        row.breadcrumb !== row.shortenedBreadcrumb &&
                        row.requestable
                          ? row.breadcrumb
                          : undefined
                      }
                      disabledReason={
                        !row.requestable ? NO_PERMISSION_TO_REQUEST : undefined
                      }
                      disabledBulk={isOktaApp}
                      checked={checkedItems.some((item) => item.id === row.id)}
                      onCheckedChange={
                        !isOktaApp
                          ? (checked) => {
                              if (checked) {
                                setCheckedItems((prev) => [...prev, row]);
                              } else {
                                setCheckedItems((prev) =>
                                  prev.filter((i) => i.id !== row.id)
                                );
                              }
                            }
                          : undefined
                      }
                      topLabel={
                        <Label
                          label={row.typeFriendlyName}
                          inline
                          oneLine
                          truncateLength={null}
                          icon={{
                            type: "entity",
                            entityType: row.type,
                            includeBrand: false,
                          }}
                        />
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                        handleShowDetailsModal(row);
                      }}
                      renderCTA={() => {
                        return (
                          <div className={interactiveCardStyles.leftRightCTA}>
                            {row.connectable ? (
                              <ButtonV3
                                label="Connect"
                                type="success"
                                disabled={!row.connectable}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleConnect(row, event);
                                }}
                              />
                            ) : (
                              <ButtonV3
                                label="Request"
                                type="main"
                                disabled={!row.requestable}
                                disabledTooltip={NO_PERMISSION_TO_REQUEST}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleOpenRequest(row, event);
                                }}
                              />
                            )}
                            {row.accessInfo.hasAccess ? (
                              <AccessLabel {...row.accessInfo} />
                            ) : null}
                          </div>
                        );
                      }}
                    />
                  )}
                  onLoadMoreItems={loadMoreRows}
                />
              ) : (
                <Table
                  rows={rows}
                  loadingRows={resourceLoading}
                  totalNumRows={totalItemsCount}
                  getRowId={(ru) => ru.id}
                  columns={columns}
                  emptyState={{
                    title: "No Items",
                  }}
                  onRowClick={(row) => {
                    handleShowDetailsModal(row);
                  }}
                  getRowCanExpand={(row) =>
                    itemsWithChildren.has(row.id) ||
                    Boolean(itemsByParentId[row.id])
                  }
                  getChildRows={
                    hasExpandableRows
                      ? (row) => itemsByParentId[row.id]?.map(buildRow)
                      : undefined
                  }
                  getCheckboxDisabledReason={(row) => {
                    if (row.requestable) {
                      return;
                    }
                    return NO_PERMISSION_TO_REQUEST;
                  }}
                  checkedRowIds={new Set(checkedItems.map((item) => item.id))}
                  onSelectAll={(checked) => {
                    if (checked) {
                      const checkedItemRows = requestableItemIds.map((id) =>
                        buildRow(itemsById[id])
                      );
                      setCheckedItems(checkedItemRows);
                    } else {
                      setCheckedItems([]);
                    }
                  }}
                  selectAllChecked={
                    checkedItems.length > 0 &&
                    checkedItems.length === requestableItemIds.length
                  }
                  onCheckedRowsChange={
                    !isOktaApp
                      ? (ids, checked) => {
                          if (checked) {
                            const newIdsToAdd = ids.filter(
                              (id) => !checkedItems.some((i) => i.id === id)
                            );
                            const newItems = newIdsToAdd.map((id) =>
                              buildRow(itemsById[id])
                            );
                            setCheckedItems((prev) => [...prev, ...newItems]);
                          } else {
                            setCheckedItems((prev) =>
                              prev.filter((i) => !ids.some((id) => i.id === id))
                            );
                          }
                        }
                      : undefined
                  }
                  handleManualSort={(sortBy, sortDirection) => {
                    if (!sortDirection) {
                      setSortBy(undefined);
                      return;
                    }
                    if (!isSortableField(sortBy)) {
                      return;
                    }
                    const direction: SortDirection =
                      sortDirection === "DESC"
                        ? SortDirection.Desc
                        : SortDirection.Asc;

                    setSortBy({
                      field: sortBy,
                      direction,
                    });
                  }}
                  manualSortDirection={
                    sortBy && {
                      sortBy: sortBy.field,
                      sortDirection: sortBy.direction,
                    }
                  }
                  onLoadMoreRows={loadMoreRows}
                />
              )}
            </>
          )}
        </ColumnContent>
      </>
    );
  }

  return (
    <>
      <Column isContent maxWidth="none">
        {content}
        {showGroupDetailsModal ? (
          <GroupDetailsModal
            groupId={showGroupDetailsModal}
            showModal={!!showGroupDetailsModal}
            closeModal={() => setShowGroupDetailsModal(null)}
            forOktaAppId={isOktaApp ? appId : ""}
          />
        ) : showResourceDetailsModal ? (
          <ResourceDetailsModal
            resourceId={showResourceDetailsModal}
            showModal={!!showResourceDetailsModal}
            closeModal={() => setShowResourceDetailsModal(null)}
          />
        ) : null}
        {maybeRenderGroupBindingRedirectModal()}
      </Column>
    </>
  );
};

export { AppDetailView };
