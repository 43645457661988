import { Maybe } from "api/generated/graphql";
import clsx from "clsx";
import { TooltipPlacement } from "components/label/Label";
import styles from "components/label/Label.module.scss";
import { Avatar, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { CSSProperties, ReactElement } from "react";
import { Link } from "react-router-dom";

import * as userLabelStyles from "./UserLabel.css";

type UserLabelProps = {
  name?: string | null;
  avatar?: Maybe<string>;
  url?: string | null;
  email?: string;
  subText?: string | null;
  warningIcon?: ReactElement;
  warningTooltipText?: string;
  medium?: boolean;
  large?: boolean;
  bold?: boolean;
  pointerCursor?: boolean;
  inactive?: boolean;
  target?: string;
  hideAvatar?: boolean;
};

export const UserLabel = (props: UserLabelProps) => {
  let style: CSSProperties = {};
  if (props.bold) {
    style = { fontWeight: 500 };
  }

  const name = (
    <span className={userLabelStyles.title} style={style}>
      {props.name}
      {!!props.email && (
        <span className={sprinkles({ color: "gray500", fontSize: "bodySm" })}>
          {" "}
          ({props.email})
        </span>
      )}
    </span>
  );

  let customStyle = {};
  if (props.pointerCursor) {
    customStyle = {
      ...customStyle,
      cursor: "pointer",
    };
  }
  return (
    <div
      className={styles.user}
      style={customStyle}
      onClick={(e) => {
        if (props.url) {
          e.stopPropagation();
        }
      }}
    >
      {!props.hideAvatar && (
        <Avatar
          size="small"
          url={props.avatar || undefined}
          inactive={props.inactive}
        />
      )}
      <div
        className={clsx({
          [styles.hideAvatar]: props.hideAvatar,
          [styles.userText]: !props.large,
          [styles.userTextLarge]: props.large,
          [styles.inactive]: props.inactive,
        })}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          maxWidth: "inherit",
          ...customStyle,
        }}
      >
        {props.url ? (
          <Link to={props.url} target={props.target}>
            {name}
          </Link>
        ) : (
          name
        )}
        {props.subText && (
          <span className={userLabelStyles.subText}>{props.subText}</span>
        )}
      </div>

      {props.warningIcon && (
        <div
          className={clsx({
            [styles.userWarning]: !props.large,
            [styles.userWarningLarge]: props.large,
          })}
        >
          {props.warningTooltipText ? (
            <Tooltip
              tooltipText={props.warningTooltipText ?? null}
              placement={TooltipPlacement.Bottom}
            >
              <div>{props.warningIcon}</div>
            </Tooltip>
          ) : (
            <div>{props.warningIcon}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserLabel;
