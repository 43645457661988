import {
  AppPreviewFragment,
  ConnectionType,
  CurrentUserGroupAccessTinyFragment,
  CurrentUserResourceAccessTinyFragment,
  EntityType,
  GroupType,
  ResourceType,
  SearchResultEntryFragment,
  SuggestionFragment,
  useSearchQuery,
  useSuggestionsQuery,
} from "api/generated/graphql";
import { Column, ColumnContainer } from "components/column/Column";
import ColumnHeaderV3 from "components/column/ColumnHeaderV3";
import AccessLabel from "components/enduser_exp/AccessLabel";
import CatalogLabel from "components/enduser_exp/CatalogLabel";
import LayoutToggle from "components/enduser_exp/LayoutToggle";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import OpalPage from "components/layout/OpalPage";
import { GroupDetailsModal } from "components/modals/enduser_exp/GroupDetailsModal";
import { ResourceDetailsModal } from "components/modals/enduser_exp/ResourceDetailsModal";
import {
  ButtonV3,
  Input,
  InteractiveCard,
  Label,
  Masonry,
} from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import * as interactiveCardStyles from "components/ui/card/InteractiveCard.css";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import { IconData } from "components/ui/utils";
import _ from "lodash";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import useLogEvent from "utils/analytics";
import { UserExperience, useUserHasUserExperience } from "utils/auth/auth";
import { groupHasForfeitableRole } from "utils/groups";
import { useDebouncedValue } from "utils/hooks";
import {
  formatResourceBreadcrumb,
  resourceHasForfeitableRole,
} from "utils/resources";
import {
  Location,
  useTransitionTo,
  useURLSearchParam,
} from "utils/router/hooks";
import {
  RequestableEntityTypes,
  useAccessRequestTransition,
} from "views/access_request/AccessRequestContext";
import { AppsContext } from "views/apps/AppsContext";
import {
  BREAKPOINT_COLUMNS,
  NO_PERMISSION_TO_REQUEST,
} from "views/apps/enduser_exp/constants";
import { ItemAccessInfo } from "views/apps/enduser_exp/types";
import {
  formatRequestDataForItems,
  getGroupUserAccessInfo,
  getResourceUserAccessInfo,
} from "views/apps/enduser_exp/utils";
import {
  CurrentUserResourceAccessStatus,
  currentUserResourceAccessStatusFromResource,
  useConnectTransition,
} from "views/connect_sessions/utils";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import SearchContext, {
  SearchContextActionType,
} from "views/search/SearchContext";
import { dropNothings } from "views/utils";

import * as styles from "./SearchV3.css";

type SearchItem = {
  id: string;
  name: string;
  description?: string;
  entityType: EntityType;
  resourceType?: ResourceType;
  itemTypeName: string;
  itemTypeIcon?: IconData;
  redirectTo: Location;
  shortenedBreadcrumb?: string;
  breadcrumb?: string;
  accessInfo?: ItemAccessInfo;
  icon?: IconData;
  connectionId?: string;

  forfeitable?: boolean;
  connectable?: boolean;
  requestable?: boolean;
  oktaAppId?: string;

  // placeholder for the table column
  cta?: string;
};

const SearchView = () => {
  const transitionTo = useTransitionTo();
  const logEvent = useLogEvent();
  const transitionToAccessRequest = useAccessRequestTransition();
  const transitionToConnect = useConnectTransition();
  const userExperience = useUserHasUserExperience();

  const [openInfoModalRow, setOpenInfoModalRow] = useState<SearchItem>();
  const { searchState, searchDispatch } = useContext(SearchContext);
  const { layoutOption } = useContext(AppsContext);
  const [currentQuery, setCurrentQuery] = useURLSearchParam("q", "");
  const debouncedQuery = useDebouncedValue(currentQuery, 250) ?? "";

  const showSuggestions = debouncedQuery.length <= 1;

  const { error, loading: searchLoading } = useSearchQuery({
    skip: showSuggestions,
    variables: {
      input: {
        query: debouncedQuery,
      },
    },
    onCompleted: (data) => {
      searchDispatch({
        type: SearchContextActionType.SearchQueryChange,
        payload: {
          searchResultEntries: data.search.entries,
        },
      });
    },
  });

  let {
    data: suggestionsData,
    loading: suggestionsLoading,
  } = useSuggestionsQuery({
    variables: {
      input: {
        numMaxSuggestions: 12,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const handleClick = (
    actionType: "connect" | "request" | "detail",
    row: SearchItem,
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    logEvent({
      name: "search_result_click",
      properties: {
        entityID: row.id,
        entityType: row.entityType,
        entityName: row.name,
        action: actionType,
      },
    });

    switch (actionType) {
      case "connect": {
        if (!row.connectable || !row.connectionId) {
          return;
        }
        transitionToConnect({
          connectionId: row.connectionId,
          resourceId: row.id,
          event,
        });
        break;
      }
      case "request": {
        const entityType = RequestableEntityTypes.find(
          (e) => e === row.entityType
        );
        if (!entityType || !row.requestable) {
          return;
        }
        transitionToAccessRequest(
          formatRequestDataForItems(
            { entityType, entityId: row.id },
            row.oktaAppId
          ),
          event
        );
        break;
      }
      case "detail": {
        if (userExperience === UserExperience.AdminUX) {
          transitionTo(row.redirectTo, event);
        } else {
          switch (row.entityType) {
            case EntityType.Group:
            case EntityType.Resource:
              setOpenInfoModalRow(row);
              break;
            default:
              transitionTo(row.redirectTo, event);
              break;
          }
        }
      }
    }
  };

  if (error) {
    return (
      <ColumnContainer>
        <Column isContent maxWidth="none">
          <ColumnHeaderV3
            title="Search"
            icon={{ type: "name", icon: "search" }}
            includeDefaultActions
          />
          <UnexpectedErrorPage error={error} />
        </Column>
      </ColumnContainer>
    );
  }

  const rows: SearchItem[] = showSuggestions
    ? dropNothings(
        suggestionsData?.suggestions.suggestions.map((suggestion) =>
          suggestion ? suggestionToSearchItem(suggestion) : null
        ) ?? []
      )
    : dropNothings(
        searchState.searchResultEntries.map((entry) =>
          entry ? searchResultToSearchItem(entry) : null
        )
      );

  const columns: Header<SearchItem>[] = dropNothings([
    {
      id: "name",
      label: "Name",
      sortable: true,
      width: 200,
      customCellRenderer: (row) => {
        return (
          <CatalogLabel
            icon={row.icon}
            name={row.name}
            breadcrumb={row.breadcrumb}
            shortenedBreadcrumb={row.shortenedBreadcrumb}
          />
        );
      },
    },
    {
      id: "description",
      label: "Description",
      sortable: false,
      width: 180,
      customCellRenderer: (row) => {
        const description =
          row.description && row.description.length > 0 ? row.description : "";
        return <Label label={description} truncateLength={null} oneLine />;
      },
    },
    {
      id: "itemTypeName",
      label: "Type",
      sortable: true,
      width: 120,
      customCellRenderer: (row) => {
        return <Label label={row.itemTypeName} truncateLength={null} oneLine />;
      },
    },
    showSuggestions
      ? {
          id: "accessInfo",
          label: "Access",
          sortable: false,
          width: 140,
          customCellRenderer: (row) => {
            if (row.accessInfo) {
              return <AccessLabel {...row.accessInfo} />;
            }
            return <></>;
          },
        }
      : null,
    {
      id: "cta",
      label: "Actions",
      sortable: false,
      width: 80,
      customCellRenderer: (row) => {
        return (
          <>
            {getActionButtons(row, handleClick, "xs")
              .reverse()
              .map((button) => (
                <ButtonV3 {...button} key={button.key} />
              ))}
          </>
        );
      },
    },
  ]);

  let bottomContent;

  if (showSuggestions && rows.length === 0) {
    // no suggestions - show nothing
    bottomContent = null;
  } else {
    let tableHeader;
    if (showSuggestions) {
      // suggestions
      tableHeader = (
        <TableHeader
          entityName="Most visited item"
          totalNumRows={null}
          loading={suggestionsLoading}
          rightElement={<LayoutToggle />}
        />
      );
    } else {
      //search results
      tableHeader = (
        <TableHeader
          entityName="Search Result"
          totalNumRows={rows.length}
          loading={searchLoading}
          rightElement={<LayoutToggle />}
        />
      );
    }
    bottomContent = (
      <>
        {tableHeader}
        {layoutOption === "grid" ? (
          <Masonry
            containerStyle={{ height: "auto" }}
            items={rows}
            totalNumItems={rows.length}
            getItemKey={(data) => data.id}
            breakpointCols={BREAKPOINT_COLUMNS}
            loadingItems={searchLoading || suggestionsLoading}
            renderItem={(data: SearchItem) => {
              return (
                <InteractiveCard
                  title={data.name}
                  icon={
                    !_.isEqual(data.icon, data.itemTypeIcon)
                      ? data.icon
                      : undefined
                  }
                  subtitle={data.shortenedBreadcrumb ?? data.breadcrumb}
                  description={data.description}
                  subtitleTooltip={
                    data.shortenedBreadcrumb &&
                    data.breadcrumb !== data.shortenedBreadcrumb
                      ? data.breadcrumb
                      : undefined
                  }
                  topLabel={
                    <Label
                      label={data.itemTypeName}
                      inline
                      oneLine
                      truncateLength={null}
                      icon={data.itemTypeIcon}
                    />
                  }
                  onClick={(event) => {
                    handleClick("detail", data, event);
                  }}
                  renderCTA={() => {
                    return (
                      <div className={interactiveCardStyles.leftRightCTA}>
                        <div>
                          {getActionButtons(data, handleClick)
                            .reverse()
                            .map((button) => (
                              <ButtonV3 {...button} key={button.key} />
                            ))}
                        </div>
                        {data.accessInfo?.hasAccess ? (
                          <AccessLabel {...data.accessInfo} />
                        ) : null}
                      </div>
                    );
                  }}
                />
              );
            }}
            emptyState={{
              title: "No items found",
              subtitle: "Try searching for something else",
              icon: "search",
            }}
          />
        ) : (
          <Table
            autoHeight
            columns={columns}
            rows={rows}
            loadingRows={searchLoading}
            totalNumRows={rows.length}
            getRowId={(data) => data.id}
            emptyState={{
              title: "No items found",
              subtitle: "Try searching for something else",
              icon: "search",
            }}
            onRowClick={(row, event) => {
              handleClick("detail", row, event);
            }}
          />
        )}
      </>
    );
  }

  return (
    <OpalPage title="Search" icon="search">
      <div className={styles.topContainer}>
        <div className={styles.searchPrompt}>What are you looking for?</div>
        <div className={styles.searchInput}>
          <Input
            leftIconName="search"
            placeholder="Search Opal"
            style="search"
            // FIXME: autoFocus sometimes doesn't work, we'd probably need to use a hook to set the focus
            autoFocus
            value={currentQuery?.toString() ?? ""}
            onChange={setCurrentQuery}
          />
        </div>
        <div className={styles.searchHelp}>
          Not sure what you need?
          <Link to="/apps" className={styles.link}>
            Browse the catalog
          </Link>
        </div>
      </div>
      {bottomContent}
      {openInfoModalRow?.entityType === EntityType.Group ? (
        <GroupDetailsModal
          groupId={openInfoModalRow.id}
          showModal={!!openInfoModalRow}
          forOktaAppId={openInfoModalRow.oktaAppId}
          closeModal={() => setOpenInfoModalRow(undefined)}
        />
      ) : openInfoModalRow?.entityType === EntityType.Resource ? (
        <ResourceDetailsModal
          resourceId={openInfoModalRow.id}
          showModal={!!openInfoModalRow}
          closeModal={() => setOpenInfoModalRow(undefined)}
        />
      ) : null}
    </OpalPage>
  );
};

function getActionButtons(
  row: SearchItem,
  handleClick: (
    type: "connect" | "request" | "detail",
    row: SearchItem,
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void,
  buttonSize?: "xs" | "sm" | "md"
) {
  const buttons: (PropsFor<typeof ButtonV3> & { key: string })[] = [];
  if (row.connectable) {
    buttons.push({
      label: "Connect",
      type: "success",
      key: "connect",
      size: buttonSize ?? "md",
      onClick: (event) => {
        event.stopPropagation();
        handleClick("connect", row, event);
      },
    });
  } else if (
    row.resourceType !== ResourceType.AwsAccount &&
    (row.entityType === EntityType.Group ||
      row.entityType === EntityType.Resource ||
      row.requestable)
  ) {
    buttons.push({
      label: "Request",
      type: "main",
      key: "request",
      disabled: !row.requestable,
      disabledTooltip: NO_PERMISSION_TO_REQUEST,
      size: buttonSize ?? "md",
      onClick: (event) => {
        event.stopPropagation();
        handleClick("request", row, event);
      },
    });
  }
  return buttons;
}

function searchResultToSearchItem(
  result: SearchResultEntryFragment
): SearchItem | null {
  switch (result.objectId.entityType) {
    case EntityType.Resource:
      if (result.resource) {
        return resourceToSearchItem({
          ...result.resource,
          connection: result.connection,
        });
      }
      break;
    case EntityType.Group:
      if (result.group) {
        return groupToSearchItem({
          ...result.group,
          connection: result.connection,
        });
      }
      break;
    case EntityType.Connection:
      if (result.connection) {
        const icon: IconData = result.connection.iconUrl
          ? {
              type: "src",
              icon: result.connection.iconUrl,
            }
          : {
              type: "entity",
              entityType: result.connection.connectionType,
            };
        return {
          id: result.connection.id,
          name: result.connection.name,
          description: "",
          entityType: EntityType.Connection,
          itemTypeName: "App",
          icon: icon,
          itemTypeIcon: icon,
          redirectTo: {
            pathname: `/apps/${result.connection.id}`,
          },
        };
      }
      break;
    case EntityType.User: {
      const icon: IconData = result.avatarUrl
        ? {
            type: "src",
            icon: result.avatarUrl,
            style: "rounded",
          }
        : {
            type: "src",
            icon: defaultAvatarURL,
            style: "rounded",
          };
      return {
        id: result.objectId.entityId,
        name: result.name,
        entityType: EntityType.User,
        itemTypeName: "User",
        breadcrumb: result.email ?? undefined,
        description: result.annotationText ?? "",
        redirectTo: {
          pathname: `/users/${result.objectId.entityId}`,
        },
        icon: icon,
        itemTypeIcon: {
          type: "name",
          icon: "user",
        },
      };
    }
    case EntityType.Owner: {
      const icon: IconData = {
        type: "name",
        icon: "user-square",
      };
      return {
        id: result.objectId.entityId,
        name: result.name,
        entityType: EntityType.Owner,
        itemTypeName: "Owner",
        description: result.annotationText ?? "",
        redirectTo: {
          pathname: `/owners/${result.objectId.entityId}`,
        },
        breadcrumb: "", // Owners don't have breadcrumbs, but we need it for the InteractiveCard
        icon: icon,
        itemTypeIcon: icon,
      };
    }
    case EntityType.Bundle: {
      const icon: IconData = {
        type: "name",
        icon: "package",
      };
      return {
        id: result.objectId.entityId,
        name: result.name,
        entityType: EntityType.Bundle,
        itemTypeName: "Bundle",
        description: result.annotationText ?? "",
        redirectTo: {
          pathname: `/bundles/${result.objectId.entityId}`,
        },
        breadcrumb: "", // Bundles don't have breadcrumbs, but we need it for the InteractiveCard
        icon: icon,
        itemTypeIcon: icon,
      };
    }
  }
  return null;
}

function resourceToSearchItem(resource: {
  id: string;
  name: string;
  description: string;
  remoteId: string;
  resourceType: ResourceType;
  iconUrl?: string | null;
  ancestorPathToResource?: string | null;
  connection?: {
    id: string;
    name: string;
    connectionType: ConnectionType;
  } | null;
  isRequestable: boolean;
  currentUserAccess: CurrentUserResourceAccessTinyFragment;
}): SearchItem {
  const mostRecentUserAccessStatus = currentUserResourceAccessStatusFromResource(
    resource
  );
  return {
    id: resource.id,
    connectionId: resource.connection?.id,
    entityType: EntityType.Resource,
    resourceType: resource.resourceType,
    name: resource.name,
    description: resource.description,
    icon: resource.iconUrl
      ? {
          type: "src",
          icon: resource.iconUrl,
        }
      : {
          type: "entity",
          entityType: resource.resourceType,
          onlyBrandIcon: true,
        },
    itemTypeName: getResourceTypeInfo(resource.resourceType)?.name ?? "",
    itemTypeIcon: {
      type: "entity",
      entityType: resource.resourceType,
      includeBrand: false,
    },
    redirectTo: {
      pathname: `/resources/${resource.id}`,
    },
    shortenedBreadcrumb: formatResourceBreadcrumb(
      resource.ancestorPathToResource,
      50,
      resource.connection?.name
    ),
    breadcrumb: formatResourceBreadcrumb(
      resource.ancestorPathToResource,
      null,
      resource.connection?.name
    ),
    forfeitable: resourceHasForfeitableRole(resource.currentUserAccess),
    connectable:
      mostRecentUserAccessStatus ===
        CurrentUserResourceAccessStatus.AuthorizedSessionStarted ||
      mostRecentUserAccessStatus ===
        CurrentUserResourceAccessStatus.AuthorizedSessionNotStarted,
    requestable: resource.isRequestable,
    accessInfo: getResourceUserAccessInfo(resource.currentUserAccess),
  };
}

function groupToSearchItem(group: {
  id: string;
  name: string;
  connection?: { id: string; name: string } | null;
  parentApp?: AppPreviewFragment | null;
  currentUserAccess: CurrentUserGroupAccessTinyFragment;
  isRequestable: boolean;
  description: string;
  groupType: GroupType;
}): SearchItem {
  const isOktaAppRole = group.parentApp?.app.__typename === "OktaResourceApp";
  const appName = group.parentApp?.name ?? group.connection?.name ?? "";
  const iconUrl =
    group.parentApp?.app.__typename === "OktaResourceApp"
      ? group.parentApp.app.iconUrl ?? undefined
      : undefined;
  return {
    id: group.id,
    connectionId: group.connection?.id,
    name: group.name,
    icon: iconUrl
      ? { type: "src", icon: iconUrl }
      : {
          type: "entity",
          entityType: group.groupType,
          onlyBrandIcon: true,
        },
    description: group.description,
    itemTypeName: getGroupTypeInfo(group.groupType)?.name ?? "",
    itemTypeIcon: {
      type: "entity",
      entityType: group.groupType,
      includeBrand: false,
    },
    redirectTo: {
      pathname: `/groups/${group.id}`,
      search:
        isOktaAppRole && group.parentApp?.id
          ? `?oktaAppId=${group.parentApp.id}`
          : "",
    },
    shortenedBreadcrumb: `${appName}/`,
    breadcrumb: `${appName}/`,
    accessInfo: getGroupUserAccessInfo(group.currentUserAccess),
    connectable: false,
    forfeitable: groupHasForfeitableRole(group.currentUserAccess),
    requestable: group.isRequestable,
    entityType: EntityType.Group,
    oktaAppId: isOktaAppRole ? group.parentApp?.id : undefined,
  };
}

function suggestionToSearchItem(
  suggestion: SuggestionFragment
): SearchItem | null {
  switch (suggestion.__typename) {
    case "ResourceSuggestion": {
      if (!suggestion.resource) {
        return null;
      }
      const { resource } = suggestion;
      return resourceToSearchItem(resource);
    }
    case "GroupSuggestion": {
      if (!suggestion.group) {
        return null;
      }
      const { group } = suggestion;
      return groupToSearchItem(group);
    }
  }
}

export default SearchView;
