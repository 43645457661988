import {
  AccessOption,
  ResourceType,
  useUserMenuQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Avatar, ContextMenu } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { useHistory } from "react-router";
import AppContext from "views/app/AppContext";
import {
  ACCESS_OPTION_URL_KEY,
  ITEM_TYPE_URL_KEY,
} from "views/apps/AppsContext";

import * as styles from "./LeftSidebarUserMenu.css";

export default ({ collapsed }: { collapsed: boolean }) => {
  const { authState } = useContext(AuthContext);
  const { appState } = useContext(AppContext);
  const history = useHistory();

  const { data } = useUserMenuQuery();

  const user = authState.user;
  const opalConnectionId =
    data?.connections.connections.length === 1
      ? data?.connections.connections[0].id
      : null;

  const renderUserMenu = (onClick: (event: React.MouseEvent) => void) => {
    return (
      <button className={styles.userMenu({ collapsed })} onClick={onClick}>
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
            alignItems: "center",
          })}
        >
          <Avatar url={user?.user.avatarUrl} size="medium" />
          {!collapsed && (
            <div>
              <div className={styles.userName}>{user?.user.fullName}</div>
              <div className={styles.orgName}>
                {user?.user.organization.name}
              </div>
            </div>
          )}
        </div>
      </button>
    );
  };

  const menuItems: PropsFor<typeof ContextMenu>["options"] = [
    {
      label: "My Opal Roles",
      icon: { type: "name", icon: "role" },
      onClick: () =>
        history.push(
          `/apps/${opalConnectionId}?${ITEM_TYPE_URL_KEY}=${ResourceType.OpalRole}&${ACCESS_OPTION_URL_KEY}=${AccessOption.Mine}`
        ),
    },
    {
      label: "Account Settings",
      icon: { type: "name", icon: "user" },
      onClick: () => history.push("/user/settings"),
    },
    {
      label: "Log Out",
      icon: { type: "name", icon: "log-out" },
      onClick: () => history.push("/sign-out"),
    },
    {
      label: "",
      onClick: () => {},
      renderOption: () => {
        return (
          <div className={styles.version}>
            <a href="https://docs.opal.dev/changelog" target="_blank">
              {appState.version}
            </a>
          </div>
        );
      },
    },
  ];

  return (
    <ContextMenu
      block
      options={menuItems}
      renderButton={renderUserMenu}
      offsetLeft={4}
      padding="lg"
      collapsed={collapsed}
      header={
        collapsed && (
          <>
            <div className={styles.userName}>{user?.user.fullName}</div>
            <div className={styles.orgName}>{user?.user.organization.name}</div>
          </>
        )
      }
    />
  );
};
