import { gql, useFragment } from "@apollo/client";
import type { OpalAppNameCellFragment } from "api/generated/graphql";

export default (props: { appID: string }) => {
  const { complete, data } = useFragment<OpalAppNameCellFragment>({
    fragment: gql`
      fragment OpalAppNameCell on App {
        name
      }
    `,
    fragmentName: "OpalAppNameCell",
    from: {
      __typename: "App",
      id: props.appID,
    },
  });

  return <span>{complete ? data?.name : "loading"}</span>;
};
