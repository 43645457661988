import { formatTimestampForDisplay } from "api/common/common";
import {
  EntityType,
  EventFragment,
  EventType,
  GroupPreviewSmallFragment,
  Maybe,
  PropagationStatusCode,
  PropagationTaskType,
  RequestFragment,
  RequestStatus,
  RequestTemplateCustomFieldType,
  SupportTicketPreviewFragment,
  useEventsFullQuery,
  useRequestQuery,
  UserPreviewLargeFragment,
} from "api/generated/graphql";
import { Column, ColumnContainer } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeaderV3, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeaderV3";
import { getLogoByThirdPartyProvider } from "components/label/Label";
import { PillV3 } from "components/pills/PillsV3";
import PropagationStatusModal, {
  PropagationStatusInfo,
} from "components/propagation/PropagationStatusModal";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { capitalize, getResourceUrlNew } from "utils/common";
import { logError, logWarning } from "utils/logging";
import { useReadUINotification } from "utils/notifications";
import { PropagationType } from "utils/useRemediations";
import { ItemDetailsCard } from "views/common/ItemDetailsCard";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";

import { IconData } from "../../components/ui/utils";
import RequestApproveButton from "./actions/RequestApproveButton";
import RequestCancelButton from "./actions/RequestCancelButton";
import RequestDenyButton from "./actions/RequestDenyButton";
import RequestOverviewV3 from "./RequestOverviewV3";
import { RequestProgressStages } from "./RequestProgressStages";
import { requestStatusToReadableString, RequestsType } from "./utils";

interface Props {
  selectedType: RequestsType;
}

const RequestDetailView = (props: Props) => {
  const { requestId } = useParams<Record<string, string>>();

  const [showPropagationStatusModal, setShowPropagationStatusModal] = useState(
    false
  );

  const {
    data,
    error,
    loading,
    refetch: refetchRequest,
    stopPolling,
    startPolling,
  } = useRequestQuery({
    variables: {
      input: {
        id: requestId,
      },
    },
  });

  let request: Maybe<RequestFragment> = null;
  if (data) {
    switch (data.request.__typename) {
      case "RequestResult":
        request = data.request.request;
        break;
      case "RequestNotFoundError":
        logWarning(new Error("error: request not found"));
        break;
      default:
        logError(new Error("failed to get request"));
    }
  } else if (error) {
    logError(error, `failed to get request`);
  }

  let events: EventFragment[] = [];
  const {
    data: eventsData,
    error: eventsError,
    loading: eventsLoading,
    refetch: refetchEvents,
  } = useEventsFullQuery({
    variables: {
      input: {
        filter: {
          objects: {
            objectId: request?.id ?? "",
          },
        },
      },
    },
    skip: !request?.id,
  });
  if (eventsData?.events?.__typename === "EventsResult") {
    events = eventsData.events.events.map((event) => ({
      ...event,
      createdAt: formatTimestampForDisplay(event.createdAt),
    }));
  } else if (error) {
    logError(error, `failed to list events`);
  }

  useEffect(() => {
    // If the request was made less than 5 seconds ago, refresh the request in a
    // few seconds to try to load request-creation events (i.e. since requester/reviewer
    // notifications happen async from request creation).
    let timeoutEvents1: NodeJS.Timeout;
    let timeoutEvents2: NodeJS.Timeout;
    let timeoutRequests: NodeJS.Timeout;
    if (
      request &&
      moment().diff(moment(new Date(request.createdAt)), "seconds") < 5
    ) {
      // Do it twice in case 2 seconds is too quick for a check. This lets us hedge against
      // being fast. We could alternatively refetch until {5 seconds, new events returned}, but that
      // seems more complicated than necessary.
      timeoutEvents1 = setTimeout(() => refetchEvents(), 2 * 1000);
      timeoutEvents2 = setTimeout(() => refetchEvents(), 4 * 1000);
      // Refetch the request, so that we can show the request ticket copy if it's been created.
      timeoutRequests = setTimeout(() => refetchRequest(), 4 * 1000);
    }
    return () => {
      clearTimeout(timeoutEvents1);
      clearTimeout(timeoutEvents2);
      clearTimeout(timeoutRequests);
    };
  }, [request, refetchRequest, refetchEvents]);

  useReadUINotification(requestId);

  const shouldPoll = request?.status === RequestStatus.Pending;
  useEffect(() => {
    if (shouldPoll) {
      startPolling(5000);
    } else {
      stopPolling();
    }
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling, shouldPoll]);

  if (loading) {
    return (
      <ColumnContainer>
        <Column isContent maxWidth="none">
          <ColumnHeaderSkeleton includeCard />
          <ViewSkeleton />
        </Column>
      </ColumnContainer>
    );
  }

  if (error || eventsError || !request) {
    return (
      <ColumnContainer>
        <Column isContent maxWidth="none">
          <UnexpectedErrorPage error={error} />
        </Column>
      </ColumnContainer>
    );
  }

  if (!request) {
    return null;
  }

  // for now just aggregate prop statuses
  let propagationStatusCode: PropagationStatusCode | undefined;
  const unfilteredStatuses = [
    ...request.requestedResources,
    ...request.requestedGroups,
  ].map((entity) => entity.propagationStatus);
  const statuses = unfilteredStatuses
    .filter((status) => Boolean(status))
    .map((status) => status?.statusCode);
  if (statuses.length > 0) {
    const allSuccess = statuses.every(
      (status) => status === PropagationStatusCode.Success
    );
    const anyPending = statuses.some(
      (status) =>
        status === PropagationStatusCode.Pending ||
        status === PropagationStatusCode.PendingTicketCreation
    );
    const anyPendingManualPropagation = statuses.some(
      (status) => status === PropagationStatusCode.PendingManualPropagation
    );
    if (allSuccess) {
      propagationStatusCode = PropagationStatusCode.Success;
    } else if (anyPending) {
      propagationStatusCode = PropagationStatusCode.Pending;
    } else if (anyPendingManualPropagation) {
      propagationStatusCode = PropagationStatusCode.PendingManualPropagation;
    } else {
      propagationStatusCode = PropagationStatusCode.ErrUnknown;
    }
  }

  let propagationTaskType: PropagationTaskType | undefined;
  const taskTypes = unfilteredStatuses
    .filter((status) => Boolean(status))
    .map((status) => status?.taskType);
  if (taskTypes.length > 0) {
    const allCreateUsers = taskTypes.every(
      (taskType) =>
        taskType === PropagationTaskType.ResourcesCreateUsers ||
        taskType === PropagationTaskType.GroupsCreateUsers
    );
    if (allCreateUsers) {
      propagationTaskType = taskTypes[0];
    } else {
      propagationTaskType = taskTypes.find(
        (taskType) =>
          taskType !== PropagationTaskType.ResourcesCreateUsers &&
          taskType !== PropagationTaskType.GroupsCreateUsers
      );
    }
  }

  // Build propagation statuses from requested groups and requested resources
  const propagationStatusInfos: PropagationStatusInfo[] = [];
  request.requestedResources.forEach((requestedResource) => {
    const propStatusInfo: PropagationStatusInfo = {
      propagationType: PropagationType.ResourceUser,
      propagationStatus: requestedResource.propagationStatus,
      propagationTickets: requestedResource.propagationStatus?.metadata?.ticket
        ? [requestedResource.propagationStatus.metadata.ticket]
        : undefined,
      isAccessReview: false,
      entityInfo: {
        resource: requestedResource.resource,
        user: request?.requester,
        role: requestedResource.accessLevel,
      },
    };

    propagationStatusInfos.push(propStatusInfo);
  });

  request.requestedGroups.forEach((requestedGroup) => {
    const propStatusInfo = {
      propagationType: PropagationType.GroupUser,
      propagationStatus: requestedGroup.propagationStatus,
      propagationTickets:
        requestedGroup.propagationStatus?.ticketPropagation?.resourceTickets
          .filter((resourceTicket) => Boolean(resourceTicket))
          .map(
            (resourceTicket) =>
              resourceTicket.ticket as SupportTicketPreviewFragment
          ) ?? [],
      isAccessReview: false,
      entityInfo: {
        group: requestedGroup.group,
        user: request?.requester,
        role: requestedGroup.accessLevel ?? undefined,
      },
    };

    propagationStatusInfos.push(propStatusInfo);
  });

  const requestHeaderButtons = (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      <RequestDenyButton request={request} />
      <RequestApproveButton request={request} />
      <RequestCancelButton request={request} />
    </div>
  );

  const getIcon = (
    target: UserPreviewLargeFragment | GroupPreviewSmallFragment
  ): IconData | undefined => {
    switch (target.__typename) {
      case "User":
        return {
          type: "src",
          icon: target.avatarUrl || defaultAvatarURL,
        };
      case "Group":
        if (target.connection) {
          return {
            type: "entity",
            entityType: target.connection.connectionType,
          };
        }
    }
  };

  let cardBodyFields: Record<string, string | JSX.Element> = {
    For:
      request.targetUser || request.targetGroup ? (
        <PillV3
          pillColor="DeepOrange"
          icon={
            request.targetUser
              ? getIcon(request.targetUser)
              : request.targetGroup
              ? getIcon(request.targetGroup)
              : undefined
          }
          keyText={
            request.targetUser
              ? request.targetUser.fullName
              : request.targetGroup?.name
          }
          entityId={
            request.targetUser
              ? {
                  entityId: request.targetUser?.id,
                  entityType: EntityType.User,
                }
              : request.targetGroup
              ? {
                  entityId: request.targetGroup?.id,
                  entityType: EntityType.Group,
                }
              : undefined
          }
          url={
            request.targetUser
              ? getResourceUrlNew({
                  entityId: request.targetUserId ?? null,
                  entityType: EntityType.User,
                })
              : request.targetGroup
              ? getResourceUrlNew({
                  entityId: request.targetGroupId ?? null,
                  entityType: EntityType.Group,
                })
              : undefined
          }
        />
      ) : (
        "—"
      ),
  };

  if (request.targetGroup) {
    cardBodyFields["Requested By"] = (
      <PillV3
        pillColor="DeepOrange"
        icon={{
          type: "src",
          icon: request.requester?.avatarUrl,
          fallbackIcon: defaultAvatarURL,
        }}
        keyText={request.requester?.fullName}
        entityId={
          request.requester
            ? {
                entityId: request.requester.id,
                entityType: EntityType.User,
              }
            : undefined
        }
      />
    );
  } else {
    cardBodyFields["Managed By"] = request.targetUser?.manager ? (
      <PillV3
        pillColor="DeepOrange"
        icon={{
          type: "src",
          icon: request.targetUser?.manager?.avatarUrl,
          fallbackIcon: defaultAvatarURL,
        }}
        keyText={request.targetUser?.manager?.fullName}
        entityId={
          request.targetUser?.manager
            ? {
                entityId: request.targetUser.manager.id,
                entityType: EntityType.User,
              }
            : undefined
        }
        url={getResourceUrlNew({
          entityId: request.targetUser?.manager?.id ?? null,
          entityType: EntityType.User,
        })}
      />
    ) : (
      "—"
    );
  }

  cardBodyFields["Duration"] = (
    <PillV3
      pillColor="Blue"
      keyText={
        request.durationInMinutes
          ? moment.duration(request.durationInMinutes, "minutes").humanize()
          : "Indefinite"
      }
    />
  );

  if (request.supportTicket) {
    cardBodyFields["Access ticket"] = (
      <PillV3
        pillColor="Gray"
        keyText={request.supportTicket.identifier}
        tooltipText={`Access to the requested item(s) will expire when the linked ticket is closed.`}
        externalUrl={request.supportTicket.url}
        icon={{
          icon: getLogoByThirdPartyProvider(
            request.supportTicket.thirdPartyProvider
          ),
          type: "src",
        }}
      />
    );
  }

  if (request.requestTicket) {
    cardBodyFields["Audit ticket"] = (
      <PillV3
        tooltipText={`This audit ticket was created to track this request.
                      All updates to this request are posted to the ticket.
                      Audit tickets can be useful for consolidating analytics and reporting.`}
        pillColor="Gray"
        keyText={request.requestTicket.identifier}
        externalUrl={request.requestTicket.url}
        icon={{
          icon: getLogoByThirdPartyProvider(
            request.requestTicket.thirdPartyProvider
          ),
          type: "src",
        }}
      />
    );
  }

  request.customMetadata?.forEach((metadata) => {
    let clickToCopy = false;
    let fieldValue: string | undefined;
    switch (metadata.fieldType) {
      case RequestTemplateCustomFieldType.ShortText:
        if (metadata.metadataValue?.shortTextValue) {
          clickToCopy = true;
          fieldValue = metadata.metadataValue.shortTextValue.value;
        }
        break;
      case RequestTemplateCustomFieldType.LongText:
        if (metadata.metadataValue?.longTextValue) {
          clickToCopy = true;
          fieldValue = metadata.metadataValue.longTextValue.value;
        }
        break;
      case RequestTemplateCustomFieldType.Boolean:
        if (metadata.metadataValue?.booleanValue) {
          fieldValue = metadata.metadataValue.booleanValue.value
            ? "True"
            : "False";
        }
        break;
      case RequestTemplateCustomFieldType.MultiChoice:
        if (metadata.metadataValue?.multiChoiceValue) {
          fieldValue = metadata.metadataValue.multiChoiceValue.value;
        }
        break;
    }
    if (fieldValue) {
      cardBodyFields[metadata.fieldName] = (
        <PillV3
          pillColor="DeepOrange"
          keyText={fieldValue}
          clickToCopy={clickToCopy}
        />
      );
    }
  });

  const accessRevoked = Boolean(
    events.find((event) =>
      event.subEvents.find(
        (subEvent) =>
          subEvent.subEventType === EventType.UsersRemovedFromGroups ||
          subEvent.subEventType === EventType.UsersRemovedFromResources ||
          subEvent.subEventType === EventType.ResourcesRemovedFromGroups ||
          subEvent.subEventType === EventType.GroupsRemovedFromGroups
      )
    )
  );

  const requestProgressStages = (
    <RequestProgressStages
      request={request}
      accessRevoked={accessRevoked}
      propagationStatusCode={propagationStatusCode}
      propagationTaskType={propagationTaskType}
      setShowPropagationStatusModal={setShowPropagationStatusModal}
    />
  );

  return (
    <>
      <ColumnContainer>
        <Column isContent maxWidth="none">
          <ColumnHeaderV3
            breadcrumbs={[
              { name: "Requests", to: "/requests" },
              {
                name: capitalize(props.selectedType),
                to: "/requests/" + props.selectedType,
              },
              { name: requestStatusToReadableString(request.status), to: "" },
            ]}
            includeDefaultActions
          />
          <ColumnContent>
            <ItemDetailsCard
              title={requestStatusToReadableString(request.status) + " request"}
              subtitle={`"${request.reason}"`}
              bodyFields={cardBodyFields}
              rightActions={requestHeaderButtons}
              footerElement={requestProgressStages}
            />
            <RequestOverviewV3
              request={request}
              events={events}
              loading={eventsLoading}
              propagationStatusInfos={propagationStatusInfos}
              propagationTaskType={propagationTaskType}
            />
          </ColumnContent>
        </Column>
      </ColumnContainer>
      {showPropagationStatusModal && (
        <PropagationStatusModal
          propagationStatusInfos={propagationStatusInfos}
          showModal={showPropagationStatusModal}
          setShowModal={setShowPropagationStatusModal}
        />
      )}
    </>
  );
};

export default RequestDetailView;
