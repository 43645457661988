import { Column, ColumnContainer } from "components/column/Column";
import OrgVisualization from "components/viz/OrgVisualization";
import OrgVisualizationV3 from "components/viz/OrgVisualizationV3";
import { Route, Switch } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

const InsightsRouteWrapper = () => {
  return (
    <Switch>
      <Route exact path="/insights" component={InsightsComponent} />
    </Switch>
  );
};

const InsightsComponent = () => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  if (hasV3) {
    return <OrgVisualizationV3 />;
  }
  return (
    <ColumnContainer>
      <Column isContent maxWidth="none">
        <OrgVisualization />
      </Column>
    </ColumnContainer>
  );
};

export default InsightsRouteWrapper;
