import {
  BundlesSortByField,
  EntityType,
  SortDirection,
  useBundlesQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column, ColumnContainer } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { ColumnSearchAndSort } from "components/column/ColumnSearchAndSort";
import OpalPage from "components/layout/OpalPage";
import { Divider, Input } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useDebouncedValue, useMountEffect } from "utils/hooks";
import { useTransitionTo } from "utils/router/hooks";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import BundleCreateModal from "./BundleCreateModal";
import * as styles from "./BundlesColumn.css";

export const SORT_OPTIONS = [
  {
    label: "Name (A-Z)",
    value: {
      field: BundlesSortByField.Name,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Name (Z-A)",
    value: {
      field: BundlesSortByField.Name,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Newest first",
    value: {
      field: BundlesSortByField.CreatedAt,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Oldest first",
    value: {
      field: BundlesSortByField.CreatedAt,
      direction: SortDirection.Asc,
    },
  },
];

interface BundleRow {
  id: string;
  name: string;
  description: string;
  admin: string;
  itemCount: number;
  linkTo?: string;
}

const BUNDLE_COLUMNS: Header<BundleRow>[] = [
  {
    id: "name",
    label: "Name",
    sortable: true,
  },
  {
    id: "description",
    label: "Description",
    sortable: false,
  },
  {
    id: "admin",
    label: "Admin",
    sortable: true,
  },
  {
    id: "itemCount",
    label: "Resources",
    sortable: true,
  },
];

const BundlesColumn = () => {
  const logEvent = useLogEvent();
  const history = useHistory();
  const transitionTo = useTransitionTo();
  const { authState } = useContext(AuthContext);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 300);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);

  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  const { data, error, loading, fetchMore } = useBundlesQuery({
    variables: {
      input: {
        searchQuery:
          debouncedSearchQuery.length > 0 ? debouncedSearchQuery : undefined,
        sortBy: sortBy.value,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  useMountEffect(() => {
    logEvent({
      name: "catalog_bundles",
      properties: {
        view: "admin",
      },
    });
  });

  const organizationName = authState.user?.user.organization.name;

  const bundles = data?.bundles.bundles ?? [];
  const cursor = data?.bundles.cursor;

  const rows = bundles.map<BundleRow>((bundle) => {
    return {
      id: bundle.id,
      name: bundle.name,
      description: bundle.description || "—",
      admin: bundle.adminOwner.name,
      itemCount: bundle.totalNumItems,
      linkTo: `/bundles/${bundle.id}/overview`,
    };
  });

  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              cursor,
              searchQuery:
                debouncedSearchQuery.length > 0
                  ? debouncedSearchQuery
                  : undefined,
              sortBy: sortBy.value,
            },
          },
        });
      }
    : undefined;

  const addMenuOptions: PropsFor<typeof ColumnHeader>["addMenuOptions"] = [];
  if (authState.user?.isAdmin) {
    addMenuOptions.push({
      label: "Create a bundle",
      onClick: () => setShowCreateModal(true),
      type: "success",
      icon: {
        type: "name",
        icon: "plus",
      },
    });
  }

  if (hasV3) {
    return (
      <OpalPage
        title="Catalog"
        icon="apps"
        tabs={[
          {
            title: "Apps",
            isSelected: history.location.pathname.endsWith("/apps"), // TODO
            onClick: () => history.push("/apps"),
          },
          {
            title: "Bundles",
            isSelected: history.location.pathname.endsWith("/bundles"), // TODO
            onClick: () => history.push("/bundles"),
          },
        ]}
      >
        <TableFilters>
          <TableFilters.Left>
            <div className={styles.searchInput}>
              <Input
                leftIconName="search"
                type="search"
                style="search"
                value={searchQuery}
                onChange={setSearchQuery}
                placeholder="Filter Bundles by name"
              />
            </div>
          </TableFilters.Left>
        </TableFilters>
        <TableHeader
          entityType={EntityType.Bundle}
          totalNumRows={data?.bundles.totalNumBundles ?? rows.length}
          loading={loading}
          defaultRightActions={
            authState.user?.isAdmin
              ? [
                  {
                    label: "Bundle",
                    type: "main",
                    iconName: "plus",
                    onClick: () => {
                      history.push(`/bundles/create`);
                    },
                  },
                ]
              : undefined
          }
        />
        {loading ? (
          <ColumnListItemsSkeleton />
        ) : (
          <Table
            autoHeight
            rows={rows}
            totalNumRows={data?.bundles.totalNumBundles ?? rows.length}
            getRowId={(ru) => ru.id}
            columns={BUNDLE_COLUMNS}
            onRowClick={(row, event) => {
              if (row.linkTo) {
                transitionTo(
                  {
                    pathname: `/bundles/${row.id}/resources`,
                  },
                  event
                );
              }
            }}
            defaultSortBy="name"
          />
        )}
        {showCreateModal && (
          <BundleCreateModal onClose={() => setShowCreateModal(false)} hasV3 />
        )}
      </OpalPage>
    );
  }

  return (
    <ColumnContainer>
      <Column>
        <ColumnHeader
          title="Bundles"
          icon={{ type: "name", icon: "package" }}
          subtitle={organizationName}
          breadcrumbs={[{ name: "Apps", to: "/apps" }]}
          count={data?.bundles.bundles?.length ?? 0}
          addMenuOptions={addMenuOptions}
        />
        <Divider />
        {(Boolean(data?.bundles.totalNumBundles) || loading) && (
          <ColumnSearchAndSort
            key="search"
            setSearchQuery={setSearchQuery}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortOptions={SORT_OPTIONS}
            placeholder="Search bundles"
            trackName="bundles"
          />
        )}
        {error ? (
          <UnexpectedErrorPage error={error} />
        ) : (
          <ColumnListScroller
            numRows={bundles.length}
            loading={loading}
            emptyState={{
              title: "No bundles",
              subtitle: authState.user?.isAdmin
                ? `Select "Create a bundle" from the options in the top right corner to get started.`
                : undefined,
            }}
            onLoadMore={loadMoreRows}
            hasNextPage={Boolean(cursor)}
            renderRow={(index) => {
              const bundle = bundles[index];
              if (!bundle) return <></>;

              return (
                <ColumnListItem
                  key={bundle.id}
                  label={bundle.name}
                  onClick={() => history.push(`/bundles/${bundle.id}/overview`)}
                />
              );
            }}
          />
        )}
      </Column>
      {showCreateModal && (
        <BundleCreateModal onClose={() => setShowCreateModal(false)} />
      )}
    </ColumnContainer>
  );
};

export default BundlesColumn;
