import { getModifiedErrorMessage } from "api/ApiContext";
import {
  ConnectionType,
  EntityType,
  GroupType,
  Maybe,
  PropagationStatusCode,
  RemoveGroupResourceInput,
  ResourceDetailViewFragment,
  ResourceGroupFragment,
  ResourceType,
  UpdateGroupResourceInput,
  useRemoveGroupResourcesMutation,
  useUpdateGroupResourcesMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { ResourceLabel, TimeLabel } from "components/label/Label";
import { BulkUpdateExpirationModal } from "components/modals/BulkUpdateExpirationModal";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import { useToast } from "components/toast/Toast";
import { Checkbox, EntityIcon, Input, Modal } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import { compareAccessPaths } from "components/ui/table/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage, hasBasicPermissions } from "utils/auth/auth";
import { getResourceUrlNew } from "utils/common";
import { isSnowflakeResource } from "utils/directory/resources";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { PropagationType } from "utils/useRemediations";
import { useTransitionToImportItems } from "views/apps/AppImportItemsView";
import { expirationValueToDurationInMinutes } from "views/requests/utils";
import {
  getGroupResourceAccessPathsInfo,
  GroupResourceAccessPathsInfo,
  GroupResourceAccessPointsLabel,
} from "views/resources/GroupResourceAccessPointsLabel";
import { dropNothings } from "views/utils";

import * as styles from "./ResourceGroupsTableV3.css";

interface ResourceGroupRow {
  id: string;
  groupId: string;
  resourceGroup: ResourceGroupFragment;
  name?: string;
  connectionType?: ConnectionType;
  role: string;
  accessPaths: GroupResourceAccessPathsInfo;
  expiry?: string | null;
  syncStatus?: PropagationStatusCode | null;
  authorizedActions?: string[] | null;
}
type ResourceGroupsTableV3Props = {
  resource: ResourceDetailViewFragment;
  showUnmanagedGroups: boolean;
  setShowUnmanagedGroups: (checked: boolean) => void;
};

export const ResourceGroupsTableV3 = (props: ResourceGroupsTableV3Props) => {
  const transitionTo = useTransitionTo();
  const [transitionToImportItems] = useTransitionToImportItems();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [
    updateExpirationErrorMessage,
    setUpdateExpirationErrorMessage,
  ] = useState<string>("");
  const [showUpdateExpirationModal, setShowUpdateExpirationModal] = useState(
    false
  );
  const [removeGroupsErrorMessage, setRemoveGroupsErrorMessage] = useState<
    Maybe<string>
  >(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const startPushTaskPoll = usePushTaskLoader();
  const logEvent = useLogEvent();
  const { displaySuccessToast } = useToast();
  const { authState } = useContext(AuthContext);
  const hasGroupProjects = useFeatureFlag(FeatureFlag.GroupProjects);

  const [
    updateGroupResources,
    { loading: updateLoading },
  ] = useUpdateGroupResourcesMutation();
  const [
    removeGroupResources,
    { loading: removeLoading },
  ] = useRemoveGroupResourcesMutation();

  const canEdit = props.resource?.authorizedActions?.includes(
    AuthorizedActionManage
  );

  const canSeePropagationStatus = () => {
    return !hasBasicPermissions(authState.user) || canEdit;
  };

  const RESOURCE_GROUP_COLUMNS: Header<ResourceGroupRow>[] = dropNothings([
    {
      id: "name",
      label: "Name",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div
            className={styles.groupNameField}
            onClick={(event) => {
              event.stopPropagation();
              if (row.resourceGroup.group?.isManaged) {
                transitionTo(
                  {
                    pathname: getResourceUrlNew({
                      entityId: row.resourceGroup.groupId,
                      entityType: EntityType.Group,
                    }),
                  },
                  event
                );
              } else {
                transitionToImportItems(
                  {
                    connectionId: props.resource.connection?.id ?? "",
                    groupIds: [row.resourceGroup.groupId],
                  },
                  event
                );
              }
            }}
          >
            <ResourceLabel
              text={row.name}
              bold={true}
              pointerCursor={true}
              maxChars="auto"
              inactive={!row.resourceGroup.group?.isManaged}
              tooltipText={
                !row.resourceGroup.group?.isManaged
                  ? "This group is currently not managed in Opal"
                  : null
              }
            />
          </div>
        );
      },
      width: 130,
    },
    {
      id: "connectionType",
      label: "Type",
      customCellRenderer: (row) => {
        const groupInfo = getGroupTypeInfo(row.resourceGroup.group?.groupType);
        if (!groupInfo || !row.connectionType) return <></>;
        return (
          <div
            className={sprinkles({
              display: "flex",
              gap: "sm",
              alignItems: "center",
            })}
          >
            <EntityIcon type={row.connectionType} iconStyle="rounded" />
            <div>{groupInfo.name}</div>
          </div>
        );
      },
    },
    {
      id: "role",
      label: "Role",
    },
    {
      id: "accessPaths",
      label: "Access Paths",
      customCellRenderer: (row) => {
        return (
          <GroupResourceAccessPointsLabel
            accessPathsInfo={row.accessPaths}
            group={row.resourceGroup.group}
            resource={props.resource}
            role={row.resourceGroup.accessLevel}
            access={row.resourceGroup.access}
          />
        );
      },
      sortingFn: (rowA, rowB): number => {
        return compareAccessPaths(
          rowA.getValue("accessPaths"),
          rowB.getValue("accessPaths")
        );
      },
      width: 110,
    },
    hasGroupProjects
      ? {
          id: "expiry",
          label: "Expires",
          customCellRenderer: (row) => {
            const expirationTime = row.resourceGroup.access
              ?.latestExpiringAccessPoint?.expiration
              ? moment(
                  new Date(
                    row.resourceGroup.access.latestExpiringAccessPoint.expiration
                  )
                )
              : null;
            return (
              <TimeLabel
                time={expirationTime}
                supportTicket={
                  row.resourceGroup.access?.latestExpiringAccessPoint
                    .supportTicket
                }
                useExpiringLabel
                indefiniteLablel="Permanent Access"
              />
            );
          },
          width: 125,
        }
      : null,
    canSeePropagationStatus()
      ? {
          id: "syncStatus",
          label: "Status",
          customCellRenderer: (row) => {
            return (
              <PropagationStatusLabelWithModal
                propagationType={PropagationType.GroupResource}
                propagationStatus={row.resourceGroup.propagationStatus}
                isAccessReview={false}
                entityInfo={{
                  group: row.resourceGroup.group,
                  resource: props.resource,
                  role: row.resourceGroup.accessLevel,
                }}
              />
            );
          },
          width: 50,
        }
      : null,
  ]);

  let resourceGroups = props.resource.containingGroups;
  if (!props.showUnmanagedGroups) {
    resourceGroups = resourceGroups.filter((group) => group.group?.isManaged);
  }
  if (searchQuery) {
    resourceGroups = resourceGroups.filter((resourceGroup) =>
      resourceGroup.group?.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  }
  const rowsById: Record<string, ResourceGroupRow> = {};
  const rows: ResourceGroupRow[] = resourceGroups.map((resourceGroup) => {
    const row = {
      id: resourceGroup.groupId + resourceGroup.accessLevel.accessLevelName,
      groupId: resourceGroup.groupId,
      name: resourceGroup.group?.name,
      resource: props.resource,
      resourceGroup: resourceGroup,
      connectionType: resourceGroup.group?.connection?.connectionType,
      role: resourceGroup.accessLevel.accessLevelName || "\u2014",
      accessPaths: getGroupResourceAccessPathsInfo({
        group: resourceGroup.group,
        resource: props.resource,
        access: resourceGroup.access,
      }),
      authorizedActions: resourceGroup.group?.authorizedActions,
      syncStatus: resourceGroup.propagationStatus?.statusCode,
    };
    rowsById[row.id] = row;
    return row;
  });

  const bulkRightActions: PropsFor<
    typeof TableHeader
  >["bulkRightActions"] = canEdit
    ? [
        {
          label: "Update Expiration",
          type: "mainSecondary",
          onClick: () => setShowUpdateExpirationModal(true),
          iconName: "clock",
        },
        {
          label: "Remove",
          type: "danger",
          onClick: () => setShowRemoveModal(true),
          iconName: "trash",
        },
      ]
    : [];

  const submitRemoval = async (
    groupResourcesToRemove: RemoveGroupResourceInput[]
  ) => {
    logEvent({
      name: "apps_remove_resource_from_groups",
      properties: {
        numGroupsRemovedFrom: _.uniqBy(groupResourcesToRemove, "groupId")
          .length,
      },
    });

    try {
      const { data } = await removeGroupResources({
        variables: {
          input: {
            groupResources: groupResourcesToRemove,
          },
        },
      });
      switch (data?.removeGroupResources.__typename) {
        case "RemoveGroupResourcesResult":
          startPushTaskPoll(data.removeGroupResources.taskId, {
            refetchOnComplete: {
              resourceId: props.resource.id,
            },
          });
          setRemoveGroupsErrorMessage(null);
          setSelectedItemIds([]);
          setShowRemoveModal(false);
          setSearchQuery("");
          break;
        case "UserFacingError":
          setRemoveGroupsErrorMessage(data.removeGroupResources.message);
          break;
        default:
          logError(new Error(`failed to remove resource from groups`));
          setRemoveGroupsErrorMessage(
            `Error: failed to remove resource from groups`
          );
      }
    } catch (error) {
      logError(error, "failed to remove resource from groups");
      setRemoveGroupsErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to remove resource from groups",
          error
        )
      );
    }
  };

  const submitUpdateExpiration = async (
    groupResourcesToUpdate: UpdateGroupResourceInput[]
  ) => {
    logEvent({
      name: "apps_update_group_resources",
      properties: {
        numResourcesUpdated: _.uniqBy(groupResourcesToUpdate, "resourceId")
          .length,
      },
    });

    try {
      const { data } = await updateGroupResources({
        variables: {
          input: {
            groupResources: groupResourcesToUpdate,
          },
        },
        refetchQueries: ["ResourceDetailView"],
      });
      switch (data?.updateGroupResources.__typename) {
        case "UpdateGroupResourcesResult":
          if (data.updateGroupResources.taskIds.length > 0) {
            for (const taskId of data.updateGroupResources.taskIds) {
              startPushTaskPoll(taskId, {
                refetchOnComplete: { resourceId: props.resource.id },
              });
            }
          } else {
            displaySuccessToast(
              `Successfully updates ${pluralize(
                "groups",
                groupResourcesToUpdate.length,
                true
              )}`
            );
          }
          setShowUpdateExpirationModal(false);
          setUpdateExpirationErrorMessage("");
          setSelectedItemIds([]);
          setSearchQuery("");
          break;
        case "UserFacingError":
          setUpdateExpirationErrorMessage(data.updateGroupResources.message);
          break;
        default:
          logError(new Error(`failed to update expiration for groups`));
          setUpdateExpirationErrorMessage(
            "Error: failed to update expiration for groups"
          );
      }
    } catch (error) {
      logError(error, "failed to update expiration");
      setUpdateExpirationErrorMessage(
        getModifiedErrorMessage("Error: failed to update expiration", error)
      );
    }
  };

  const getCheckboxDisabledReason = (row: ResourceGroupRow) => {
    const canManageGroup = row.authorizedActions?.includes(
      AuthorizedActionManage
    );
    if (
      row.resourceGroup.group?.groupType === GroupType.OktaGroup &&
      props.resource.resourceType === ResourceType.OktaRole
    ) {
      return "Okta Role and Group relationships cannot be managed in Opal. Please make these changes in Okta.";
    } else if (!canManageGroup) {
      return "You can only remove resources which you administer. Please ask your Opal admin to remove it.";
    } else if (!row.resourceGroup.access?.directAccessPoint) {
      return "The group has no direct access to remove";
    }

    if (isSnowflakeResource(props.resource.resourceType)) {
      return "Snowflake resources cannot be managed in Opal. Please make these changes in Snowflake.";
    }
    return undefined;
  };

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        height: "100%",
      })}
    >
      <TableFilters>
        <TableFilters.Left>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              placeholder="Filter by name"
              value={searchQuery}
              onChange={setSearchQuery}
            />
          </div>
          <Checkbox
            size="md"
            label="Show unmanaged groups"
            checked={props.showUnmanagedGroups}
            onChange={(value) => {
              props.setShowUnmanagedGroups(value);
            }}
          />
        </TableFilters.Left>
      </TableFilters>

      <TableHeader
        entityType={EntityType.Group}
        totalNumRows={new Set(rows.map((row) => row.groupId)).size}
        selectedNumRows={selectedItemIds.length}
        defaultRightActions={
          canEdit && !isSnowflakeResource(props.resource.resourceType)
            ? [
                {
                  label: "Add Groups",
                  type: "mainSecondary",
                  onClick: () => {
                    transitionTo({
                      pathname: `/resources/${props.resource.id}/add-groups`,
                    });
                  },
                  iconName: "plus",
                },
              ]
            : []
        }
        bulkRightActions={bulkRightActions}
      />
      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        columns={RESOURCE_GROUP_COLUMNS}
        defaultSortBy="name"
        onRowClickTransitionTo={(row) =>
          getResourceUrlNew({
            entityId: row.resourceGroup.groupId,
            entityType: EntityType.Group,
          })
        }
        checkedRowIds={new Set(selectedItemIds)}
        onCheckedRowsChange={
          canEdit
            ? (checkedRowIds, checked) => {
                if (checked) {
                  setSelectedItemIds((prev) => [...prev, ...checkedRowIds]);
                  return;
                } else {
                  setSelectedItemIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        selectAllChecked={
          selectedItemIds.length > 0 &&
          selectedItemIds.length ===
            rows.filter((row) => !getCheckboxDisabledReason(row)).length
        }
        onSelectAll={(checked) =>
          checked
            ? setSelectedItemIds(
                rows
                  .filter((row) => !getCheckboxDisabledReason(row))
                  .map((row) => row.id)
              )
            : setSelectedItemIds([])
        }
        getCheckboxDisabledReason={getCheckboxDisabledReason}
      />
      {showUpdateExpirationModal && (
        <BulkUpdateExpirationModal
          isOpen={showUpdateExpirationModal}
          onSubmit={(expiration) => {
            const groupResourcesToUpdate: UpdateGroupResourceInput[] = [];
            const accessDurationInMinutes =
              expirationValueToDurationInMinutes(expiration)?.asMinutes() ??
              null;
            for (const rowId of selectedItemIds) {
              const row = rowsById[rowId];
              groupResourcesToUpdate.push({
                resourceId: props.resource.id,
                groupId: row.groupId,
                accessLevel: {
                  accessLevelName:
                    row.resourceGroup.access.accessLevel.accessLevelName,
                  accessLevelRemoteId:
                    row.resourceGroup.access.accessLevel.accessLevelRemoteId,
                },
                durationInMinutes: {
                  int: accessDurationInMinutes,
                },
              });
            }
            submitUpdateExpiration(groupResourcesToUpdate);
          }}
          selectedItemIds={selectedItemIds}
          entityName={props.resource.name}
          errorMessage={updateExpirationErrorMessage}
          onClose={() => setShowUpdateExpirationModal(false)}
          loading={updateLoading}
        />
      )}
      {showRemoveModal && (
        <Modal
          isOpen
          title="Remove Resource Groups"
          onClose={() => setShowRemoveModal(false)}
        >
          <Modal.Body>
            {removeGroupsErrorMessage && (
              <ModalErrorMessage errorMessage={removeGroupsErrorMessage} />
            )}
            Are you sure you want to remove{" "}
            {pluralize("direct role assignment", selectedItemIds.length, true)}{" "}
            from this resource?
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Remove"
            primaryButtonDisabled={selectedItemIds.length === 0}
            primaryButtonLoading={removeLoading}
            onPrimaryButtonClick={() => {
              const groupResourcesToRemove: RemoveGroupResourceInput[] = [];
              for (const rowId of selectedItemIds) {
                const row = rowsById[rowId];
                groupResourcesToRemove.push({
                  groupId: row.groupId,
                  resourceId: props.resource.id,
                  accessLevel: {
                    accessLevelName:
                      row.resourceGroup.access.accessLevel.accessLevelName,
                    accessLevelRemoteId:
                      row.resourceGroup.access.accessLevel.accessLevelRemoteId,
                  },
                });
              }
              submitRemoval(groupResourcesToRemove);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default ResourceGroupsTableV3;
