import { refetchQueries } from "api/ApiContext";
import {
  AppIdInput,
  AppType,
  EntityType,
  GroupBulkSuggestionPreviewFragment,
  GroupType,
  RecommendationsEntityKey,
  RecommendationsEntityType,
  RecommendationsSubscoreType,
  ResourceBulkSuggestionPreviewFragment,
  ResourceType,
  TagInput,
  TopBulkSuggestionsQuery,
  useDismissRecommendationsSubscoresMutation,
  useLogEntityRemediationMutation,
  useRescoreEntityMutation,
  useTopBulkSuggestionsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { BreadcrumbsV3, ButtonV3, Icon, PopoverV3 } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import _, { lowerCase } from "lodash";
import pluralize from "pluralize";
import { useContext } from "react";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { getResourceUrlNew } from "utils/common";
import { useGetResourceBreadcrumbs } from "utils/hooks";
import { RecommendationsFilter } from "views/recommendations/RecommendationFilters";
import ThreatPill, {
  outsideAccessRiskFactorText,
  perpetualAccessRiskFactorText,
  unusedAccessRiskFactorText,
} from "views/recommendations/ThreatPill";

import EntityRemediationModal from "./resolutions/EntityRemediationModal";
import { ResourceEntityIcon } from "./resolutions/RemediationColumns";
import * as popover_styles from "./ThreatPill.css";

type BulkSuggestionRow = {
  id: string;
  entity:
    | ResourceBulkSuggestionPreviewFragment
    | GroupBulkSuggestionPreviewFragment;
  type: RecommendationsSubscoreType;
  totalCount: number;
  targetCount: number;
};

interface ThreatResolutionNewProps {
  filter: RecommendationsFilter;
}

export interface RemediationMetadata {
  entityKey: RecommendationsEntityKey;
  jitCount: number;
  jitCountFromSuggestions: number;
  revokeCount: number;
  revokeCountFromSuggestions: number;
  remediationType: RecommendationsSubscoreType;
  totalSuggestionsCount: number;
}

const AllSuggestionTypes = [
  RecommendationsSubscoreType.SuggestionPerpetualAndUnusedAccess,
  RecommendationsSubscoreType.SuggestionNotUsingAccess,
  RecommendationsSubscoreType.SuggestionUnusedAccess,
  RecommendationsSubscoreType.SuggestionUnmanagedAccess,
];

export const getSuggestionRowId = (row: BulkSuggestionRow) =>
  row.type + ":" + row.id;

const ThreatResolutionNew = ({ filter }: ThreatResolutionNewProps) => {
  const [displayedSuggestionsData, setDisplayedSuggestionData] = useState<
    TopBulkSuggestionsQuery | undefined
  >(undefined);
  const [rowDisplayCount, setRowDisplayCount] = useState(5);
  const loadMore = () => {
    setRowDisplayCount(rowDisplayCount + 4);
  };
  const [remediationMetadata, setRemediationMetadata] = useState<
    Map<string, RemediationMetadata>
  >(new Map());
  const [remediatedEntityId, setRemediatedEntityId] = useState<string>();
  const [rowsBeingRemediated, setRowsBeingRemediated] = useState<Set<string>>(
    new Set()
  );
  const calculateGraphSize = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 1160) {
      return true;
    }
    return false;
  };

  const [compactMode, setCompactMode] = useState(calculateGraphSize());

  useEffect(() => {
    const handleResize = () => {
      setCompactMode(calculateGraphSize());
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const addRemediatedEntityId = (entityId: string | undefined) => {
    setRemediatedEntityId(entityId);
  };
  const [runLogEntityRemediationMutation] = useLogEntityRemediationMutation();
  const addRemediationMetadata = (row: BulkSuggestionRow) => {
    return (metadata: RemediationMetadata) =>
      setRemediationMetadata(
        new Map(remediationMetadata.set(getSuggestionRowId(row), metadata))
      );
  };

  const removeRemediationMetadata = (rowId: string) => {
    setRemediationMetadata(
      new Map(Array.from(remediationMetadata).filter(([key]) => key !== rowId))
    );
  };
  const [runRescoreMutation] = useRescoreEntityMutation();

  const debouncedRemoveRow = _.debounce(
    async (rowId, rowItem: RemediationMetadata) => {
      removeRemediationMetadata(rowId);

      await runLogEntityRemediationMutation({
        variables: {
          input: {
            entityKey: rowItem.entityKey,
            jitCount: rowItem.jitCount,
            jitCountFromSuggestions: rowItem.jitCountFromSuggestions,
            revokeCount: rowItem.revokeCount,
            revokeCountFromSuggestions: rowItem.revokeCountFromSuggestions,
            remediationType: rowItem.remediationType,
            totalSuggestionsCount: rowItem.totalSuggestionsCount,
          },
        },
      });

      await runRescoreMutation({
        variables: {
          input: rowItem.entityKey,
        },
      });

      refetchQueries({ include: ["TopBulkSuggestions", "RiskScoreGraphData"] });

      const updatedSuggestions = displayedSuggestionsData
        ? _.cloneDeep(displayedSuggestionsData)
        : undefined;
      if (updatedSuggestions?.topBulkSuggestions?.suggestions) {
        updatedSuggestions.topBulkSuggestions.suggestions = updatedSuggestions.topBulkSuggestions.suggestions.filter(
          // @ts-ignore, we don't have a fragment for groups yet so id is nullable
          (suggestion) => getSuggestionRowId(suggestion) !== rowId
        );
      }
      setDisplayedSuggestionData(updatedSuggestions);
      addRemediatedEntityId(undefined); // resetting the remediatedEntityId
      setRowsBeingRemediated((prevRows) => {
        // resetting the rows being processed
        const newProcessingRows = new Set(prevRows);
        newProcessingRows.delete(rowId);
        return newProcessingRows;
      });
    },
    5000
  );

  useEffect(() => {
    if (remediationMetadata.size > 0) {
      remediationMetadata.forEach((rowItem, rowId) => {
        if (!rowsBeingRemediated.has(rowId)) {
          // Add row to remediating state
          setRowsBeingRemediated((prev) => new Set(prev).add(rowId));
          debouncedRemoveRow(rowId, rowItem);
        }
      });
    }
  });

  const appIdInput = useMemo((): AppIdInput[] | undefined => {
    if (!filter.appFilter) {
      return undefined;
    }

    return filter.appFilter
      .map((appFilterItem) => {
        switch (appFilterItem.app.__typename) {
          case "ConnectionApp":
            return {
              id: appFilterItem.app.connectionId,
              type: AppType.Native,
            } as AppIdInput;
          case "OktaResourceApp":
            return {
              id: appFilterItem.app.resourceId,
              type: AppType.Okta,
            } as AppIdInput;
          default:
            return undefined;
        }
      })
      .filter((input): input is AppIdInput => input !== undefined);
  }, [filter.appFilter]);

  const resourceTypesInput = useMemo((): ResourceType[] | undefined => {
    if (!filter.entityTypeFilter) {
      return undefined;
    }
    const resourceTypes = filter.entityTypeFilter.filter(
      (value): value is ResourceType => {
        return Object.values(ResourceType).includes(value as ResourceType);
      }
    );
    if (resourceTypes.length === 0) {
      return undefined;
    } else {
      return resourceTypes;
    }
  }, [filter.entityTypeFilter]);

  const groupTypesInput = useMemo((): GroupType[] | undefined => {
    if (!filter.entityTypeFilter) {
      return undefined;
    }
    const groupTypes = filter.entityTypeFilter.filter(
      (value): value is GroupType => {
        return Object.values(GroupType).includes(value as GroupType);
      }
    );
    if (groupTypes.length === 0) {
      return undefined;
    } else {
      return groupTypes;
    }
  }, [filter.entityTypeFilter]);

  const tagIdInput = useMemo(() => {
    if (!filter.tagFilter) {
      return undefined;
    }
    return filter.tagFilter.map((tag) => {
      return {
        id: tag.id,
      } as TagInput;
    });
  }, [filter.tagFilter]);

  const getSuggestionTypesFromRiskFactors = (
    riskFactors: string[] | undefined
  ): RecommendationsSubscoreType[] => {
    if (!riskFactors || riskFactors.length === 0) {
      return AllSuggestionTypes;
    }

    const riskTypesSet = new Set<RecommendationsSubscoreType>();

    riskFactors.forEach((riskFactor) => {
      switch (riskFactor) {
        case perpetualAccessRiskFactorText:
          riskTypesSet.add(
            RecommendationsSubscoreType.SuggestionPerpetualAndUnusedAccess
          );
          break;
        case unusedAccessRiskFactorText:
          riskTypesSet.add(
            RecommendationsSubscoreType.SuggestionNotUsingAccess
          );
          riskTypesSet.add(RecommendationsSubscoreType.SuggestionUnusedAccess);
          riskTypesSet.add(
            RecommendationsSubscoreType.SuggestionPerpetualAndUnusedAccess
          );
          break;
        case outsideAccessRiskFactorText:
          riskTypesSet.add(
            RecommendationsSubscoreType.SuggestionUnmanagedAccess
          );
          break;
      }
    });

    return Array.from(riskTypesSet);
  };

  const riskScoreRange = useMemo(() => {
    return filter.riskScoreFilter && filter.riskScoreFilter.length > 0
      ? filter.riskScoreFilter.reduce(
          (acc, range) => ({
            minRiskScore: Math.min(acc.minRiskScore, range.minScore),
            maxRiskScore: Math.max(acc.maxRiskScore, range.maxScore),
          }),
          {
            minRiskScore: filter.riskScoreFilter[0].minScore,
            maxRiskScore: filter.riskScoreFilter[0].maxScore,
          }
        )
      : { minRiskScore: undefined, maxRiskScore: undefined };
  }, [filter.riskScoreFilter]);

  const {
    data: suggestionsData,
    loading: suggestionsLoading,
    refetch,
  } = useTopBulkSuggestionsQuery({
    variables: {
      input: {
        apps: appIdInput,
        minRiskScore: riskScoreRange.minRiskScore,
        maxRiskScore: riskScoreRange.maxRiskScore,
        tags: tagIdInput,
        riskTypes: getSuggestionTypesFromRiskFactors(filter.riskFactorFilter),
        searchQuery: filter.searchQuery,
        groupType: groupTypesInput,
        resourceType: resourceTypesInput,
      },
    },
  });

  useEffect(() => {
    refetch({
      input: {
        apps: appIdInput,
        minRiskScore: riskScoreRange.minRiskScore,
        maxRiskScore: riskScoreRange.maxRiskScore,
        searchQuery: filter.searchQuery,
        tags: tagIdInput,
        riskTypes: getSuggestionTypesFromRiskFactors(filter.riskFactorFilter),
        groupType: groupTypesInput,
        resourceType: resourceTypesInput,
      },
    });
  }, [
    refetch,
    appIdInput,
    tagIdInput,
    riskScoreRange,
    filter.riskFactorFilter,
    groupTypesInput,
    resourceTypesInput,
    filter.searchQuery,
  ]);

  useEffect(() => {
    if (!suggestionsLoading) {
      setDisplayedSuggestionData(suggestionsData);
    }
  }, [suggestionsLoading, suggestionsData]);

  const allRows = (
    displayedSuggestionsData?.topBulkSuggestions.suggestions || []
  )
    .filter((suggestion) => Boolean(suggestion))
    .map((suggestion) => {
      switch (suggestion.entity.__typename) {
        case "Resource":
          return {
            id: suggestion.entity.resourceId,
            entity: suggestion.entity,
            type: suggestion.type,
            totalCount: suggestion.totalUserCount,
            targetCount: suggestion.targetUserCount,
          };
        case "Group":
          return {
            id: suggestion.entity.groupId,
            entity: suggestion.entity,
            type: suggestion.type,
            totalCount: suggestion.totalUserCount,
            targetCount: suggestion.targetUserCount,
          };
      }
    }) as BulkSuggestionRow[];

  const columns: Header<BulkSuggestionRow>[] = [
    {
      id: "id",
      label: "",
      customCellRenderer: (row: BulkSuggestionRow) => {
        return (
          <RecommendationBulkSuggestionRow
            entity={row.entity}
            type={row.type}
            totalCount={row.totalCount}
            targetCount={row.targetCount}
            addRemediationMetadata={addRemediationMetadata(row)}
            remediationMetadata={remediationMetadata.get(
              getSuggestionRowId(row)
            )}
            addRemediatedEntityId={addRemediatedEntityId}
            remediatedEntityId={remediatedEntityId}
            compact={compactMode}
          />
        );
      },
    },
  ];

  const numSuggestions =
    displayedSuggestionsData?.topBulkSuggestions.suggestions.length;

  const DataSection = () => {
    return (
      <>
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
            alignItems: "center",
            paddingBottom: "md",
          })}
        >
          <Table
            rows={allRows.slice(0, rowDisplayCount)}
            columns={columns}
            loadingRows={suggestionsLoading}
            totalNumRows={numSuggestions ?? 0}
            getRowId={(row) => getSuggestionRowId(row)}
            emptyState={{
              title: filter
                ? "No suggestions match the selected filters"
                : "No suggestions to remediate",
            }}
            getRowHighlightColor={(row) => {
              const hasMetadata = remediationMetadata.has(
                getSuggestionRowId(row)
              );
              if (hasMetadata) {
                return "green";
              }
            }}
            getRowHighlighted={(row) => {
              const hasMetadata = remediationMetadata.has(
                getSuggestionRowId(row)
              );
              return hasMetadata;
            }}
            hideHeader
          />
        </div>
        <div>
          {allRows.length > rowDisplayCount && (
            <ButtonV3
              type="default"
              size="sm"
              outline
              label="Load More"
              onClick={() => {
                loadMore();
              }}
            />
          )}
        </div>
      </>
    );
  };
  return <DataSection />;
};

const RecommendationBulkSuggestionRow = ({
  entity,
  type,
  totalCount,
  targetCount,
  addRemediationMetadata,
  remediationMetadata,
  addRemediatedEntityId,
  remediatedEntityId,
  compact = false,
}: {
  entity:
    | ResourceBulkSuggestionPreviewFragment
    | GroupBulkSuggestionPreviewFragment;
  type: RecommendationsSubscoreType;
  totalCount: number;
  targetCount: number;
  addRemediationMetadata: (metadata: RemediationMetadata) => void;
  remediationMetadata?: RemediationMetadata;
  addRemediatedEntityId: (entityId: string) => void;
  remediatedEntityId?: string;
  compact?: boolean;
}) => {
  const isOktaApp =
    entity.__typename === "Resource" &&
    entity.resourceType == ResourceType.OktaApp;

  const [modalOpen, setModalOpen] = useState(false);

  const [dismiss] = useDismissRecommendationsSubscoresMutation({
    refetchQueries: ["TopBulkSuggestions", "RiskScoreGraphData"],
  });
  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin;

  const suggestionTypeContent = () => {
    const entityTypeText = isOktaApp ? "app" : lowerCase(entity.__typename);

    const renderContent = (
      message: string,
      pillTypes: RecommendationsSubscoreType[]
    ) => (
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          gap: "sm",
        })}
      >
        {pillTypes.map((subscoreType) => (
          <ThreatPill
            key={subscoreType}
            type={subscoreType}
            compact={compact}
          />
        ))}
        <span
          className={sprinkles({
            fontSize: "textSm",
            color: "black",
          })}
        >
          {message}
        </span>
      </div>
    );

    switch (type) {
      case RecommendationsSubscoreType.SuggestionPerpetualAndUnusedAccess:
        return renderContent(
          `${targetCount} out of ${totalCount} directly assigned ${pluralize(
            "user",
            totalCount
          )} ${pluralize(
            "have",
            targetCount
          )} not used this ${entityTypeText} in 30 days.`,
          [
            RecommendationsSubscoreType.PerpetualAccess,
            RecommendationsSubscoreType.UnusedAccess,
          ]
        );

      case RecommendationsSubscoreType.SuggestionUnmanagedAccess:
        return renderContent(
          `${targetCount} out of ${totalCount} directly assigned ${pluralize(
            "user",
            totalCount
          )} ${pluralize(
            "have",
            targetCount
          )} externally provisioned access to this ${entityTypeText}.`,
          [RecommendationsSubscoreType.UnmanagedAccess]
        );

      case RecommendationsSubscoreType.SuggestionNotUsingAccess:
        return renderContent(
          `${targetCount} out of ${totalCount} ${pluralize(
            "resource",
            totalCount
          )} ${pluralize(
            "is",
            targetCount
          )} unused by all members of this ${entityTypeText}.`,
          [RecommendationsSubscoreType.UnusedAccess]
        );

      case RecommendationsSubscoreType.SuggestionUnusedAccess:
        return renderContent(
          `${targetCount} out of ${totalCount} directly assigned ${pluralize(
            "user",
            totalCount
          )} ${pluralize(
            "have",
            targetCount
          )} not used this ${entityTypeText} in 30 days.`,
          [RecommendationsSubscoreType.UnusedAccess]
        );

      default:
        return <></>;
    }
  };

  const entityType = (() => {
    switch (entity.__typename) {
      case "Resource":
        return EntityType.Resource;
      case "Group":
        return EntityType.Group;
      default:
        throw new Error(`Unexpected entity type: ${entity.__typename}`);
    }
  })();

  const entityId = (() => {
    switch (entity.__typename) {
      case "Resource":
        return entity.resourceId;
      case "Group":
        return entity.groupId;
      default:
        throw new Error(`Unexpected entity type: ${entity.__typename}`);
    }
  })();

  const entityTypeName = (() => {
    switch (entity.__typename) {
      case "Resource":
        return resourceTypeInfoByType[entity.resourceType].fullName;
      case "Group":
        return groupTypeInfoByType[entity.groupType].name;
      default:
        throw new Error(`Unexpected entity type: ${entity.__typename}`);
    }
  })();

  const recommendationsEntityType = (() => {
    switch (entity.__typename) {
      case "Resource":
        return RecommendationsEntityType.Resource;
      case "Group":
        return RecommendationsEntityType.Group;
      default:
        throw new Error(`Unexpected entity type: ${entity.__typename}`);
    }
  })();

  const {
    data: breadcrumbs,
    error: breadcrumbsError,
    loading: breadcrumbsLoading,
  } = useGetResourceBreadcrumbs(
    {
      ...entity,
      id: entityId,
    },
    false
  );
  if (breadcrumbsError) {
    throw new Error(`Unexpected error generating breadcrumbs`);
  }
  return (
    <>
      <div
        key={entityId}
        className={sprinkles({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "sm",
          fontFamily: "body",
        })}
      >
        <div
          className={sprinkles({
            display: "block",
          })}
        >
          <div
            className={sprinkles({
              display: "flex",
              gap: "xs",
              alignItems: "center",
              paddingBottom: "sm",
            })}
          >
            <ResourceEntityIcon
              isOktaApp={isOktaApp}
              iconUrl={entity.iconUrl}
              connectionType={entity.connection?.connectionType}
              iconSize={"sm"}
            />

            <span className={sprinkles({ color: "gray600" })}>
              {!breadcrumbsLoading && (
                <BreadcrumbsV3
                  breadcrumbInfos={breadcrumbs}
                  linkColor={"grey"}
                />
              )}
            </span>
          </div>

          <div
            className={sprinkles({
              display: "flex",
              gap: "xs",
              paddingBottom: "sm",
            })}
          >
            <Link
              className={sprinkles({
                fontWeight: "bold",
                fontSize: "textLg",
              })}
              to={getResourceUrlNew(
                { entityId: entityId, entityType: entityType },
                entityType
              )}
            >
              {entity.name}
            </Link>
            <span
              className={sprinkles({
                fontSize: "textLg",
              })}
            >
              {`(${entityTypeName})`}
            </span>
          </div>
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              gap: "sm",
            })}
          >
            {remediationMetadata ? (
              <span
                className={sprinkles({
                  fontSize: "textSm",
                  color: "green500V3",
                })}
              >
                <Icon name="check-circle" size="sm" color="green500V3" />
                {` Threat Resolved: ${remediationMetadata.jitCount} ${pluralize(
                  "grant",
                  remediationMetadata.jitCount
                )} converted to JIT${
                  remediationMetadata.revokeCount !== 0
                    ? `, ${remediationMetadata.revokeCount} revoked ${pluralize(
                        "grant",
                        remediationMetadata.revokeCount
                      )}`
                    : ""
                }`}
              </span>
            ) : (
              suggestionTypeContent()
            )}
          </div>
        </div>
        {!remediationMetadata && (
          <PopoverV3
            content={
              <div className={popover_styles.tooltip}>
                {"Please wait, entity is currently being remediated"}
              </div>
            }
            position={"left"}
            enabled={entityId == remediatedEntityId}
          >
            <div
              className={sprinkles({
                display: "flex",
                alignItems: "center",
                gap: "md",
              })}
            >
              <PopoverV3
                content={
                  <div className={popover_styles.tooltip}>
                    {"You must be an super-admin to dismiss remediations"}
                  </div>
                }
                position={"left"}
                enabled={!isAdmin}
              >
                <ButtonV3
                  size="sm"
                  outline
                  type="mainBorderless"
                  label="Dismiss"
                  disabled={entityId == remediatedEntityId || !isAdmin}
                  onClick={() =>
                    dismiss({
                      variables: {
                        input: {
                          entityKeys: [
                            {
                              entityId: entityId,
                              entityType: recommendationsEntityType,
                            },
                          ],
                          type: type,
                        },
                      },
                    })
                  }
                />
              </PopoverV3>

              <ButtonV3
                size="sm"
                type="main"
                label="Review"
                disabled={entityId == remediatedEntityId}
                onClick={() => {
                  setModalOpen(true);
                }}
              />
            </div>
          </PopoverV3>
        )}
      </div>
      {modalOpen && (
        <EntityRemediationModal
          entityKeys={[
            {
              entityId: entityId,
              entityType: recommendationsEntityType,
            },
          ]}
          onClose={() => setModalOpen(false)}
          onRemediationComplete={addRemediationMetadata}
          suggestionTypes={[type]}
          entityRemediated={addRemediatedEntityId}
          isOktaApp={isOktaApp}
          onDismissal={(dismissedEntityId, dismissedEntityType) => {
            dismiss({
              variables: {
                input: {
                  entityKeys: [
                    {
                      entityId: dismissedEntityId,
                      entityType: dismissedEntityType,
                    },
                  ],
                  type: type,
                },
              },
            });
          }}
        />
      )}
    </>
  );
};

export default ThreatResolutionNew;
