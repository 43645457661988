import {
  ConnectionType,
  ResourceType,
  SessionPreviewFragment,
} from "api/generated/graphql";
import moment from "moment";
import { isSessionableType } from "utils/directory/connections";
import { useTransitionTo } from "utils/router/hooks";

export interface SessionWithExpiration {
  session: SessionPreviewFragment;
  expirationHours: number;
  expirationMinutes: number;
  isExpired: boolean;
}

export interface TransitionParams {
  connectionId: string;
  resourceId: string;
  event?: React.MouseEvent<HTMLElement, MouseEvent>;
}

export function getSessionWithExpiration(
  session: SessionPreviewFragment
): SessionWithExpiration {
  const diffMillis = Math.max(moment(session.endTime).diff(moment()), 0);
  const diffDuration = moment.duration(diffMillis);
  const expirationHours = Math.floor(diffDuration.asHours());
  const expirationMinutes = diffDuration.minutes();
  return {
    session,
    expirationHours,
    expirationMinutes,
    isExpired: diffMillis <= 0,
  };
}

type ResourceUserAccess = {
  connection?: {
    connectionType: ConnectionType;
  } | null;
  currentUserAccess?: {
    resourceUsers: {
      resourceId: string;
      userId: string;
    }[];
    activeSessions: SessionPreviewFragment[];
    pendingRequests: {
      id: string;
    }[];
  } | null;
  remoteId: string;
  resourceType: ResourceType;
};

export enum CurrentUserResourceAccessStatus {
  Unauthorized,
  Authorized,
  AuthorizedSessionStarted,
  AuthorizedSessionNotStarted,
  Requested,
}

export const currentUserResourceAccessStatusFromResource = (
  resource: ResourceUserAccess | null,
  // Allow a more narrow set of sessions to be passed in that overrides
  // resource.currentUserAccess.activeSessions. This can be useful if we
  // want a more updated view of the user's access than what's returned on
  // `resource`.
  activeSessions?: SessionPreviewFragment[]
) => {
  const currentUserAccess = resource?.currentUserAccess;
  const connectionType = resource?.connection?.connectionType;

  if (!resource || !currentUserAccess) {
    return CurrentUserResourceAccessStatus.Unauthorized;
  }

  if (currentUserAccess.resourceUsers.length > 0) {
    // If activeSessions is provided, use it rather than currentUserAccess.activeSessions.
    if ((activeSessions ?? currentUserAccess.activeSessions).length > 0) {
      return CurrentUserResourceAccessStatus.AuthorizedSessionStarted;
    } else if (
      connectionType &&
      isSessionableType(
        connectionType,
        resource.remoteId,
        resource.resourceType
      )
    ) {
      return CurrentUserResourceAccessStatus.AuthorizedSessionNotStarted;
    } else {
      return CurrentUserResourceAccessStatus.Authorized;
    }
  } else if (currentUserAccess.pendingRequests.length > 0) {
    return CurrentUserResourceAccessStatus.Requested;
  }
  return CurrentUserResourceAccessStatus.Unauthorized;
};

export const useConnectTransition = () => {
  const transitionTo = useTransitionTo();

  const transitionToConnect = ({
    connectionId,
    resourceId,
    event,
  }: TransitionParams) => {
    transitionTo(
      {
        pathname: `/apps/${connectionId}/connect/${resourceId}`,
      },
      event
    );
  };

  return transitionToConnect;
};
