import {
  EntityType,
  SortDirection,
  TagsSortByField,
  useTagsTableQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ColumnContainer } from "components/column/Column";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import OpalPage from "components/layout/OpalPage";
import { Input } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getResourceUrlNew } from "utils/common";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionTo, useURLSearchParam } from "utils/router/hooks";
import { SEARCH_QUERY_URL_KEY } from "views/apps/AppsContext";

import TagCreateModal from "./TagCreateModal";
import TagDetailV3 from "./TagDetailV3";
import * as styles from "./TagsColumnV3.css";

const CREATED_AT_COL_ID = TagsSortByField.CreatedAt;
const KEY_COL_ID = TagsSortByField.Key;
const VALUE_COL_ID = TagsSortByField.Value;
const RESOURCE_COUNT_COL_ID = TagsSortByField.ResourceCount;
const GROUP_COUNT_COL_ID = TagsSortByField.GroupCount;
const USER_COUNT_COL_ID = TagsSortByField.UserCount;

function isSortableField(str: string): str is TagsSortByField {
  return Object.values<string>(TagsSortByField).includes(str);
}

type SortValue = {
  field: TagsSortByField;
  direction: SortDirection;
};

interface TagRow {
  id: string;
  [KEY_COL_ID]: string;
  [VALUE_COL_ID]: string;
  [RESOURCE_COUNT_COL_ID]: number;
  [GROUP_COUNT_COL_ID]: number;
  [USER_COUNT_COL_ID]: number;
  [CREATED_AT_COL_ID]: string;
}

const TAG_COLUMNS: Header<TagRow>[] = [
  {
    id: KEY_COL_ID,
    label: "Key",
    sortable: true,
    customCellRenderer: (row) => {
      return (
        <Link
          to={getResourceUrlNew({
            entityId: row.id,
            entityType: EntityType.Tag,
          })}
          className={styles.tagCell}
        >
          {row[KEY_COL_ID]}
        </Link>
      );
    },
  },
  {
    id: VALUE_COL_ID,
    label: "Value",
    sortable: true,
    customCellRenderer: (row) => {
      return <div className={styles.tagCell}>{row[VALUE_COL_ID]}</div>;
    },
  },
  {
    id: RESOURCE_COUNT_COL_ID,
    label: "Resources",
    width: 80,
    sortable: true,
    customCellRenderer: (row) => {
      return (
        <div>{`${pluralize(
          "Resource",
          row[RESOURCE_COUNT_COL_ID],
          true
        )}`}</div>
      );
    },
  },
  {
    id: GROUP_COUNT_COL_ID,
    label: "Groups",
    width: 80,
    sortable: true,
    customCellRenderer: (row) => {
      return (
        <div>{`${pluralize("Group", row[GROUP_COUNT_COL_ID], true)}`}</div>
      );
    },
  },
  {
    id: USER_COUNT_COL_ID,
    label: "Users",
    width: 80,
    sortable: true,
    customCellRenderer: (row) => {
      return <div>{`${pluralize("User", row[USER_COUNT_COL_ID], true)}`}</div>;
    },
  },
  {
    id: CREATED_AT_COL_ID,
    label: "Created",
    width: 80,
    sortable: true,
    customCellRenderer: (row) => {
      return <div>{moment(row[CREATED_AT_COL_ID]).fromNow()}</div>;
    },
  },
];

const TagsColumn = () => {
  const transitionTo = useTransitionTo();
  const { tagId } = useParams<Record<string, string>>();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { authState } = useContext(AuthContext);
  const [searchQueryParam, setSearchQuery] = useURLSearchParam(
    SEARCH_QUERY_URL_KEY
  );
  const searchQuery = searchQueryParam || "";
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 300);
  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: TagsSortByField.Key,
    direction: SortDirection.Asc,
  });
  const isAdmin = authState.user?.isAdmin;

  const { data, error, loading, fetchMore } = useTagsTableQuery({
    variables: {
      input: {
        limit: 50,
        searchQuery:
          debouncedSearchQuery.length > 0 ? debouncedSearchQuery : undefined,
        sortBy: sortBy,
      },
    },
  });

  if (error) {
    logError(error, `failed to list tags`);
  }

  const cursor = data?.tags.cursor;
  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              limit: 50,
              cursor,
              searchQuery:
                debouncedSearchQuery.length > 0
                  ? debouncedSearchQuery
                  : undefined,
              sortBy: sortBy,
            },
          },
        });
      }
    : undefined;

  const tags = data?.tags.tags ?? [];

  const rows: TagRow[] = tags.map((tag) => {
    return {
      id: tag.id,
      [KEY_COL_ID]: tag.key,
      [VALUE_COL_ID]: tag.value ?? "",
      [RESOURCE_COUNT_COL_ID]: tag.tagResources.length,
      [GROUP_COUNT_COL_ID]: tag.tagGroups.length,
      [USER_COUNT_COL_ID]: tag.tagUsers.length,
      [CREATED_AT_COL_ID]: tag.createdAt,
    };
  });

  if (tagId) {
    // this should be it's own route
    return (
      <ColumnContainer>
        <TagDetailV3 />
      </ColumnContainer>
    );
  }

  return (
    <>
      <OpalPage title="Tags" icon="tag">
        <TableFilters>
          <TableFilters.Left>
            <div className={styles.searchInput}>
              <Input
                leftIconName="search"
                type="search"
                style="search"
                value={searchQuery}
                onChange={setSearchQuery}
                placeholder="Filter Tags"
              />
            </div>
          </TableFilters.Left>
        </TableFilters>

        <TableHeader
          entityType={EntityType.Tag}
          totalNumRows={data?.tags.totalNumTags ?? 0}
          loading={loading}
          defaultRightActions={
            isAdmin
              ? [
                  {
                    label: "Tag",
                    type: "main",
                    iconName: "plus",
                    onClick: () => {
                      setShowCreateModal(true);
                    },
                  },
                ]
              : undefined
          }
        />

        <>
          {loading ? (
            <ColumnListItemsSkeleton />
          ) : (
            <Table
              rows={rows}
              totalNumRows={data?.tags.totalNumTags ?? 0}
              emptyState={{ title: "No tags to display." }}
              getRowId={(ru) => ru.id}
              columns={TAG_COLUMNS}
              defaultSortBy={KEY_COL_ID}
              onRowClick={(row, event) => {
                transitionTo(
                  {
                    pathname: getResourceUrlNew({
                      entityId: row.id,
                      entityType: EntityType.Tag,
                    }),
                  },
                  event
                );
              }}
              onLoadMoreRows={loadMoreRows}
              manualSortDirection={
                sortBy && {
                  sortBy: sortBy.field,
                  sortDirection: sortBy.direction,
                }
              }
              handleManualSort={(sortBy, sortDirection) => {
                if (!sortDirection) {
                  setSortBy(undefined);
                  return;
                }
                if (!isSortableField(sortBy)) {
                  return;
                }
                const direction: SortDirection =
                  sortDirection === "DESC"
                    ? SortDirection.Desc
                    : SortDirection.Asc;

                setSortBy({
                  field: sortBy,
                  direction,
                });
              }}
            />
          )}
        </>
      </OpalPage>
      {showCreateModal ? (
        <TagCreateModal onClose={() => setShowCreateModal(false)} />
      ) : null}
    </>
  );
};

export default TagsColumn;
